import {
  AlignmentType,
  BorderStyle,
  convertInchesToTwip,
  Document,
  HeadingLevel,
  HorizontalPositionAlign,
  ImageRun,
  PageBreak,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  UnderlineType,
  VerticalAlign,
  VerticalPositionAlign,
  WidthType,
} from 'docx';

function initials(str) {
  return str
    .split(/\s+/)
    .map((w, i) => (i ? w.substring(0, 1).toUpperCase() + '.' : w))
    .join(' ');
}

export class DocumentCreator {
  create(
    [
      contractBetween,
      contract,
      rightsObligations,
      coastAndPayment,
      serviceProvisionProcedure,
      responsibilitiesParties,
      forceMajeure,
      settlementDisputes,
      end,
    ],
    orderValues,
    stamp,
    signature
  ) {
    const tableCustomer = new Table({
      borders: {
        insideVertical: { style: BorderStyle.NONE },
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: { size: 2500, type: WidthType.DXA },
              children: [this.createText(`Прізвище, ім'я, по батькові:`)],
            }),
            new TableCell({
              width: { size: 6570, type: WidthType.DXA },
              children: [this.createTextBold(orderValues?.client_payer_name)],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              width: { size: 2500, type: WidthType.DXA },
              children: [this.createText('Контактний телефон:')],
            }),
            new TableCell({
              width: { size: 6570, type: WidthType.DXA },
              children: [this.createTextBold(orderValues?.client_contact_phone)],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              width: { size: 2500, type: WidthType.DXA },
              children: [this.createText('E-mail:')],
            }),
            new TableCell({
              width: { size: 6570, type: WidthType.DXA },
              children: [this.createTextBold(orderValues?.client_email)],
            }),
          ],
        }),
      ],
      width: {
        size: 9070,
        type: WidthType.DXA,
      },
    });
    const tableStudent = new Table({
      borders: {
        insideVertical: { style: BorderStyle.NONE },
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: { size: 2500, type: WidthType.DXA },
              children: [this.createText(`Прізвище, ім'я, по батькові:`)],
            }),
            new TableCell({
              width: { size: 6570, type: WidthType.DXA },
              children: [
                this.createTextBold(
                  `${orderValues?.client_student_name}, ${orderValues?.client_birth_date}р.н`
                ),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              width: { size: 2500, type: WidthType.DXA },
              children: [this.createText('Контактний телефон:')],
            }),
            new TableCell({
              width: { size: 6570, type: WidthType.DXA },
              children: [this.createTextBold(orderValues?.client_contact_phone)],
            }),
          ],
        }),
      ],
      width: {
        size: 9070,
        type: WidthType.DXA,
      },
    });

    const tableInfoAboutStudent = new Table({
      borders: {
        insideVertical: { style: BorderStyle.NONE },
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: { size: 2500, type: WidthType.DXA },
              children: [this.createText('Іноземна мова:')],
            }),
            new TableCell({
              width: { size: 6570, type: WidthType.DXA },
              children: [this.createTextItalic('англійська')],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              width: { size: 2500, type: WidthType.DXA },
              children: [this.createText('Рівень володіння:')],
            }),
            new TableCell({
              width: { size: 6570, type: WidthType.DXA },
              children: [
                this.createTextItalic(orderValues?.ukraine_education_english_level),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              width: { size: 2500, type: WidthType.DXA },
              children: [this.createText('Група:')],
            }),
            new TableCell({
              width: { size: 6570, type: WidthType.DXA },
              children: [
                this.createTextItalic(orderValues?.ukraine_education_education_grup),
              ],
            }),
          ],
        }),
      ],
      width: {
        size: 9070,
        type: WidthType.DXA,
      },
    });

    const tableInfoPrice = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: { size: 1800, type: WidthType.DXA },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                this.createTextBoldCenter('Напрям комплексу '),
                this.createTextBoldCenter('організаційно-'),
                this.createTextBoldCenter('освітніх послуг'),
              ],
            }),
            new TableCell({
              width: { size: 1800, type: WidthType.DXA },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                this.createTextBoldCenter('Період наданих'),
                this.createTextBoldCenter('Послуг'),
              ],
            }),
            new TableCell({
              width: { size: 1800, type: WidthType.DXA },
              verticalAlign: VerticalAlign.CENTER,
              children: [this.createTextBoldCenter('Вартість')],
            }),
            new TableCell({
              width: { size: 1800, type: WidthType.DXA },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                this.createTextBoldCenter('Сума'),
                this.createTextBoldCenter('знижки'),
              ],
            }),
            new TableCell({
              width: { size: 1800, type: WidthType.DXA },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                this.createTextBoldCenter('Сума до'),
                this.createTextBoldCenter('спати'),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              width: { size: 3260, type: WidthType.DXA },
              verticalAlign: VerticalAlign.CENTER,
              children: [this.createTextCenter(orderValues?.program_name_for_docs)],
            }),
            new TableCell({
              width: { size: 3260, type: WidthType.DXA },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                this.createTextCenter(
                  `${orderValues?.program_start_date}-${orderValues?.program_end_date}`
                ),
              ],
            }),
            new TableCell({
              width: { size: 850, type: WidthType.DXA },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                this.createTextCenter(
                  `${
                    orderValues.currency === 'UAH'
                      ? orderValues?.full_price_number
                      : Math.round(
                          parseInt(orderValues?.full_price_number) *
                            parseFloat(orderValues?.conversion_rate)
                        )
                  } грн.`
                ),
              ],
            }),
            new TableCell({
              width: { size: 850, type: WidthType.DXA },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                this.createTextCenter(
                  `${
                    orderValues.currency === 'UAH'
                      ? orderValues?.discount > 0
                        ? orderValues?.discount
                        : '0,00'
                      : Math.round(orderValues?.discount * orderValues?.conversion_rate)
                  } грн.`
                ),
              ],
            }),
            new TableCell({
              width: { size: 850, type: WidthType.DXA },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                this.createTextCenter(
                  `${
                    orderValues.currency === 'UAH'
                      ? orderValues?.price_wiht_discount
                      : Math.round(
                          orderValues?.price_wiht_discount * orderValues?.conversion_rate
                        )
                  } грн.`
                ),
              ],
            }),
          ],
        }),
      ],
    });
    const tableRecipient = new Table({
      borders: {
        bottom: { style: BorderStyle.NONE },
        left: {
          style: BorderStyle.NONE,
        },
        top: { style: BorderStyle.NONE },
        right: { style: BorderStyle.NONE },
        insideVertical: { style: BorderStyle.NONE },
        insideHorizontal: { style: BorderStyle.NONE },
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: { size: 4535, type: WidthType.DXA },
              children: [
                this.createTableBold('ВИКОНАВЕЦЬ:'),
                this.createTableBold(orderValues?.recipient_name),
                this.createTableBold(''),
                this.createTextBoth(`Код ЄДРПОУ: ${orderValues?.recipient_edrpou}`),
                this.createTextBoth(
                  `Місцезнаходження: ${orderValues?.recipient_place_name}`
                ),
                this.createTextBoth(
                  `IBAN ${orderValues?.recipient_iban}, в ${orderValues?.recipient_bank}`
                ),
                this.createTextBoth(orderValues?.recipient_details),
              ],
            }),
            new TableCell({
              width: { size: 4535, type: WidthType.DXA },
              margins: { left: 540 },
              children: [
                this.createTableBold('ЗАМОВНИК:'),
                this.createTableBold('Фізична особа'),
                this.createTableBold(orderValues?.client_payer_name),
                this.createTableBold(''),
                this.createTextBoth(`Паспорт ${orderValues?.client_passport}`),
                this.createTextBoth(`код РНОКПП ${orderValues?.client_payer_tax_ID}`),
                this.createTextBoth(
                  `Адреса проживання: ${orderValues?.client_payer_tax_ID}`
                ),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                this.createTextBold(''),
                this.createTextBold(''),
                this.createTextBold('Директор'),
              ],
            }),
            new TableCell({
              children: [],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Table({
                  borders: {
                    bottom: { style: BorderStyle.NONE },
                    left: {
                      style: BorderStyle.NONE,
                    },
                    top: { style: BorderStyle.NONE },
                    right: { style: BorderStyle.NONE },
                    insideVertical: { style: BorderStyle.NONE },
                    insideHorizontal: { style: BorderStyle.NONE },
                  },
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                stamp !== null
                                  ? new ImageRun({
                                      data: stamp,
                                      floating: {
                                        zIndex: 10,
                                        horizontalPosition: {
                                          align: HorizontalPositionAlign.CENTER,
                                        },
                                        verticalPosition: {
                                          align: VerticalPositionAlign.TOP,
                                        },
                                      },
                                      transformation: {
                                        width: 100,
                                        height: 100,
                                      },
                                    })
                                  : new TextRun({ text: '' }),
                                signature !== null
                                  ? new ImageRun({
                                      data: signature,
                                      floating: {
                                        zIndex: 20,
                                        horizontalPosition: {
                                          align: HorizontalPositionAlign.CENTER,
                                        },
                                        verticalPosition: {
                                          align: VerticalPositionAlign.TOP,
                                        },
                                      },
                                      transformation: {
                                        width: 100,
                                        height: 100,
                                      },
                                    })
                                  : new TextRun({ text: '' }),

                                this.createTextBoldCenter(''),
                                this.createTextBoldCenter(''),
                                this.createTextBoldCenter(''),
                                this.createTextBold('____________________________'),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          children: [
                            this.createTextBold(''),
                            this.createTextBold(''),
                            this.createTextBold(''),
                            this.createTextBold(
                              `${initials(orderValues?.recipient_director)}`
                            ),
                            this.createTextBold(''),
                            this.createTextBold(''),
                            this.createTextBold(''),
                          ],
                        }),
                      ],
                    }),
                  ],
                  width: {
                    size: 4535,
                    type: WidthType.DXA,
                  },
                }),
              ],
            }),
            new TableCell({
              margins: { left: 540 },
              children: [
                this.createTextBold(''),
                this.createTextBold(''),
                this.createTextBold(''),
                this.createTextBold(
                  `____________________  ${initials(orderValues?.client_payer_name)}`
                ),
              ],
            }),
          ],
        }),
      ],
      width: {
        size: 9070,
        type: WidthType.DXA,
      },
    });
    return new Document({
      styles: {
        default: {
          heading1: {
            run: {
              font: 'Time New Roman',
              size: 24,
              bold: true,
              color: '000000',
            },
            paragraph: {
              spacing: { line: 340 },
            },
          },
          heading2: {
            run: {
              font: 'Time New Roman',
              size: 20,
              bold: true,
            },
            paragraph: {
              spacing: { line: 340 },
            },
          },
          heading3: {
            run: {
              font: 'Time New Roman',
              size: 20,
              bold: true,
            },
          },
        },
      },
      numbering: {
        config: [
          {
            reference: 'my-crazy-numbering',
            levels: [
              {
                level: 0,
                format: 'decimal',
                text: '%1.',
                alignment: AlignmentType.CENTER,
                style: {
                  paragraph: {
                    indent: {
                      left: convertInchesToTwip(0.1),
                      hanging: convertInchesToTwip(0.08),
                    },
                  },
                },
              },
              {
                level: 2,
                format: 'decimal',
                text: '2.%3.',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: {
                      left: convertInchesToTwip(0),
                      hanging: convertInchesToTwip(0),
                    },
                  },
                },
              },
            ],
          },
        ],
      },
      properties: {
        column: {
          space: 708,
          count: 2,
        },
      },
      sections: [
        {
          children: [
            this.createHeading(`ДОГОВІР ПРО НАДАННЯ ПОСЛУГ № ${orderValues?.order_num}`),
            this.createHeading(''),
            new Table({
              borders: {
                bottom: { style: BorderStyle.NONE },
                left: {
                  style: BorderStyle.NONE,
                },
                top: { style: BorderStyle.NONE },
                right: { style: BorderStyle.NONE },
                insideVertical: { style: BorderStyle.NONE },
                insideHorizontal: { style: BorderStyle.NONE },
              },
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      width: { size: 4535, type: WidthType.DXA },
                      children: [this.createTextBold('м.Київ')],
                    }),
                    new TableCell({
                      width: { size: 4535, type: WidthType.DXA },
                      children: [this.createDate(orderValues?.fullTextDate)],
                    }),
                  ],
                }),
              ],
              width: {
                size: 9070,
                type: WidthType.DXA,
              },
            }),
            this.createHeading(''),
            ...contractBetween
              .map((item) => {
                const arr = [];
                arr.push(this.createTextBoth(item.contract));
                arr.push(
                  this.createTextCustom3(
                    `\t${orderValues?.recipient_name} (Код ЄДРПОУ ${orderValues?.recipient_edrpou}), `,
                    `в особі директора ${orderValues?.recipient_generic}, яка діє на підставі Статуту, з однієї сторони, далі по тексту – `,
                    '«Виконавець», ',
                    'та'
                  )
                );
                arr.push(
                  this.createTextCustom3(
                    `\tФізична особа ${orderValues?.client_payer_name} `,
                    'далі  по тексту –  ',
                    '«Замовник», ',
                    'з іншої сторони, надалі спільно іменовані як «Сторони», а кожен окремо «Сторона», уклали даний Договір про наступне:'
                  )
                );
                return arr;
              })
              .reduce((prev, curr) => prev.concat(curr), []),
            this.createNumberingTitle('ПРЕДМЕТ ДОГОВОРУ'),
            ...contract
              .map((item) => {
                const arr = [];
                item.notes.forEach((bulletPoint) => {
                  arr.push(this.createTextBoth(bulletPoint));
                });
                return arr;
              })
              .reduce((prev, curr) => prev.concat(curr), []),
            this.createNumberingTitle("ПРАВА ТА ОБОВ'ЯЗКИ СТОРІН"),
            ...rightsObligations
              .map((position) => {
                const arr = [];
                arr.push(this.createNumberingSubTitle(position.executorUndertakes));
                position.obligationsExecutor.forEach((bulletPoint) => {
                  arr.push(this.createTextBoth(bulletPoint));
                });
                arr.push(this.createNumberingSubTitle(position.executorHasRight));
                position.executorsRights.forEach((bulletPoint) => {
                  arr.push(this.createTextBoth(bulletPoint));
                });
                arr.push(this.createNumberingSubTitle(position.customerUndertakes));
                position.obligationsCustomer.forEach((bulletPoint) => {
                  arr.push(this.createTextBoth(bulletPoint));
                });
                arr.push(this.createNumberingSubTitle(position.customerHasRight));
                position.customerRights.forEach((bulletPoint) => {
                  arr.push(this.createTextBoth(bulletPoint));
                });
                return arr;
              })
              .reduce((prev, curr) => prev.concat(curr), []),
            this.createNumberingTitle('ВАРТІСТЬ ТА ПОРЯДОК ОПЛАТИ ПОСЛУГ'),
            ...coastAndPayment
              .map((item) => {
                const arr = [];
                item.coast.forEach((bulletPoint) => {
                  arr.push(this.createTextBoth(bulletPoint));
                });
                return arr;
              })
              .reduce((prev, curr) => prev.concat(curr), []),
            this.createNumberingTitle(
              'ПОРЯДОК НАДАННЯ ПОСЛУГ. ПРИЙМАННЯ-ПЕРЕДАЧА НАДАНИХ ПОСЛУГ'
            ),
            ...serviceProvisionProcedure
              .map((item) => {
                const arr = [];
                item.service.forEach((bulletPoint) => {
                  arr.push(this.createTextBoth(bulletPoint));
                });
                return arr;
              })
              .reduce((prev, curr) => prev.concat(curr), []),
            this.createNumberingTitle('ВІДПОВІДАЛЬНІСТЬ СТОРІН'),
            ...responsibilitiesParties
              .map((item) => {
                const arr = [];
                item.responsibilities.forEach((bulletPoint) => {
                  arr.push(this.createTextBoth(bulletPoint));
                });
                return arr;
              })
              .reduce((prev, curr) => prev.concat(curr), []),
            this.createNumberingTitle('ФОРС-МАЖОРНІ ОБСТАВИНИ'),
            ...forceMajeure
              .map((item) => {
                const arr = [];
                item.force.forEach((bulletPoint) => {
                  arr.push(this.createTextBoth(bulletPoint));
                });
                return arr;
              })
              .reduce((prev, curr) => prev.concat(curr), []),
            this.createNumberingTitle('ПОРЯДОК ВИРІШЕННЯ СПОРІВ'),
            ...settlementDisputes
              .map((item) => {
                const arr = [];
                item.settlement.forEach((bulletPoint) => {
                  arr.push(this.createTextBoth(bulletPoint));
                });
                return arr;
              })
              .reduce((prev, curr) => prev.concat(curr), []),
            this.createNumberingTitle('ПРИКІНЦЕВІ ПОЛОЖЕННЯ'),
            ...end
              .map((item) => {
                const arr = [];
                item.first.forEach((bulletPoint) => {
                  arr.push(this.createTextBoth(bulletPoint));
                });
                arr.push(
                  this.createTextCustom2(
                    item.privacyNumber,
                    item.privacyBold,
                    item.privacyText
                  )
                );
                item.other.forEach((bulletPoint) => {
                  arr.push(this.createTextBoth(bulletPoint));
                });
                arr.push(
                  this.createNumberingTitle('ДОДАТКИ, РЕКВІЗИТИ ТА ПІДПИСИ СТОРІН')
                );
                arr.push(
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: item.subtitle,
                        bold: true,
                      }),
                    ],
                  })
                );
                item.documents.forEach((bulletPoint) => {
                  arr.push(this.createTextBoth(bulletPoint));
                });
                arr.push(this.createSubHeading(''));
                arr.push(this.createSubHeading(''));
                arr.push(tableRecipient);
                arr.push(
                  new Paragraph({
                    children: [new PageBreak()],
                  })
                );
                arr.push(this.createSubHeading('ДОДАТОК № 1'));
                arr.push(
                  this.createSubHeading(
                    `до Договору про надання послуг № ${orderValues?.order_num}`
                  )
                );
                arr.push(
                  this.createSubHeading(`від ${orderValues?.fullTextDate}`),
                  this.createSubHeading('')
                );
                arr.push(this.createSubHeading('ЗАМОВНИК'));
                arr.push(tableCustomer);
                arr.push(
                  this.createTextBoldCenter(''),
                  this.createTextBoldCenter(''),
                  this.createTextBoldCenter('ОСОБА, ВИЗНАЧЕНА ЗАМОВНИКОМ'),
                  this.createSubHeading('ДЛЯ ОТРИМАННЯ ПОСЛУГ')
                );
                arr.push(tableStudent);
                arr.push(
                  this.createTextBoldCenter(''),
                  this.createTextBoldCenter(''),
                  this.createTextBoldCenter('ВІДОМОСТІ ПРО ОСОБУ,'),
                  this.createSubHeading('ЩО ОТРИМУЄ ПОСЛУГИ')
                );
                arr.push(tableInfoAboutStudent);
                arr.push(
                  this.createSubHeading(''),
                  this.createSubHeading(''),
                  this.createSubHeading(
                    'ВІДОМОСТІ ПРО КОМПЛЕКС ОРГАНІЗАЦІЙНО-ОСВІТНІХ ПОСЛУГ'
                  )
                );
                arr.push(tableInfoPrice);
                arr.push(this.createSubHeading(''));
                arr.push(this.createSubHeading(''));
                arr.push(this.createSubHeading(''));
                arr.push(tableRecipient);
                return arr;
              })
              .reduce((prev, curr) => prev.concat(curr), []),
          ],
        },
      ],
    });
  }

  createDate(date) {
    return new Paragraph({
      alignment: AlignmentType.END,
      children: [
        new TextRun({
          text: date,
          bold: true,
          size: 22,
        }),
      ],
    });
  }

  createHeading(text) {
    return new Paragraph({
      text: text,
      alignment: AlignmentType.CENTER,
      heading: HeadingLevel.HEADING_1,
    });
  }
  createSubHeading(text) {
    return new Paragraph({
      alignment: AlignmentType.CENTER,
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 24,
        }),
      ],
    });
  }

  createTextBoldCenter(text) {
    return new Paragraph({
      alignment: AlignmentType.CENTER,
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 24,
        }),
      ],
    });
  }

  createTableBold(text) {
    return new Paragraph({
      alignment: AlignmentType.CENTER,
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 20,
        }),
      ],
    });
  }

  createTextBold(text) {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 20,
        }),
      ],
    });
  }
  createTextItalic(text) {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          italics: true,
          size: 24,
        }),
      ],
    });
  }

  createTextBoth(text) {
    return new Paragraph({
      text: text,
      alignment: AlignmentType.BOTH,
    });
  }
  createText(text) {
    return new Paragraph({
      text: text,
    });
  }
  createTextCenter(text) {
    return new Paragraph({
      text: text,
      alignment: AlignmentType.CENTER,
    });
  }
  createTextCustom2(text, textBold, textEnd) {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
        }),
        new TextRun({
          text: textBold,
          bold: true,
        }),
        new TextRun({
          text: textEnd,
        }),
      ],
    });
  }
  createTextCustom3(textBoldFirst, text, textBold, textEnd) {
    return new Paragraph({
      children: [
        new TextRun({
          text: textBoldFirst,
          bold: true,
        }),
        new TextRun({
          text: text,
        }),
        new TextRun({
          text: textBold,
          bold: true,
        }),
        new TextRun({
          text: textEnd,
        }),
      ],
    });
  }

  createNumberingTitle(text) {
    return new Paragraph({
      alignment: AlignmentType.CENTER,
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 320,
      },
      numbering: {
        level: 0,
        reference: 'my-crazy-numbering',
      },
      children: [
        new TextRun({
          text: text,
          size: 20,
          bold: true,
        }),
      ],
    });
  }
  createNumberingSubTitle(text) {
    return new Paragraph({
      heading: HeadingLevel.HEADING_3,
      numbering: {
        level: 2,
        reference: 'my-crazy-numbering',
      },
      children: [
        new TextRun({
          text: text,
          bold: true,
          underline: { type: UnderlineType.SINGLE },
        }),
      ],
    });
  }
}
