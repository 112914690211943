import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Containers
import { CountrysTable } from '../containers/CountrysTable';
import { SchoolsTable } from '../containers/SchoolsTable';
import { AddCountryModalBody } from '../containers/AddCountryModal';
import { EditCountryModal } from '../containers/EditCountryModal';
import { DeleteCountryModal } from '../containers/DeleteCountryModal';
import { AddSchoolModalBody } from '../containers/AddSchoolModal';
import { EditSchoolModal } from '../containers/EditSchoolModal';
import { DeleteSchoolModal } from '../containers/DeleteSchoolModal';

// Components
import { UniversalModal } from 'ui';

// Styled
import { PageSection, TableWrap } from './styles';

export const AdminProgramDetails = connect(
  ({ adminProgramDetails: { modal, currentRecord, modalData } }) => ({
    modal,
    currentRecord,
    modalData,
  }),
  ({
    adminProgramDetails: {
      setModal,
      setModalData,
      addCountryToServer,
      editCountryToServer,
      removeCountryFromServer,
      addSchoolToServer,
      editSchoolToServer,
      removeSchoolFromServer,
    },
    users: { getRoles },
  }) => ({
    setModal,
    setModalData,
    addCountryToServer,
    editCountryToServer,
    removeCountryFromServer,
    addSchoolToServer,
    editSchoolToServer,
    removeSchoolFromServer,
    getRoles,
  })
)(
  ({
    modal = '',
    currentRecord = {},
    modalData = {},
    setModal = () => {},
    setModalData = () => {},
    addCountryToServer = () => {},
    editCountryToServer = () => {},
    removeCountryFromServer = () => {},
    addSchoolToServer = () => {},
    editSchoolToServer = () => {},
    removeSchoolFromServer = () => {},
    getRoles = () => {},
  }) => {
    const setModalClose = () => {
      setModal('');
    };

    const { country = '', school = '' } = currentRecord;

    useEffect(() => {
      getRoles({});
    }, [getRoles]);

    return (
      <PageSection>
        {/* Add new user modal */}
        {modal === 'add' && (
          <UniversalModal
            title="Додати нову країну"
            onCancel={setModalClose}
            setModalClose={setModalClose}
            visible={true}
            ModalBody={() => <AddCountryModalBody modelData={currentRecord} />}
            serverRequest={addCountryToServer}
            setModalData={setModalData}
            modalData={modalData}
          />
        )}
        {/* Edit user modal */}
        {modal === 'edit' && (
          <UniversalModal
            title={`Редагувати країну ${country}`}
            onCancel={setModalClose}
            setModalClose={setModalClose}
            visible={true}
            currentRecord={currentRecord}
            ModalBody={() => <EditCountryModal modelData={currentRecord} />}
            serverRequest={editCountryToServer}
            setModalData={setModalData}
            modalData={modalData}
          />
        )}
        {/* Delete user modal */}
        {modal === 'delete' && (
          <UniversalModal
            title={`Видалити країну ${country}?`}
            onCancel={setModalClose}
            setModalClose={setModalClose}
            visible={true}
            currentRecord={currentRecord}
            ModalBody={() => <DeleteCountryModal modelData={currentRecord} />}
            serverRequest={removeCountryFromServer}
            setModalData={setModalData}
            modalData={currentRecord._id}
          />
        )}
        {modal === 'add-school' && (
          <UniversalModal
            title="Додати нову Школу"
            onCancel={setModalClose}
            setModalClose={setModalClose}
            visible={true}
            ModalBody={() => <AddSchoolModalBody modelData={currentRecord} />}
            serverRequest={addSchoolToServer}
            setModalData={setModalData}
            modalData={modalData}
          />
        )}
        {/* editSchoolToServer */}
        {modal === 'edit-school' && (
          <UniversalModal
            title={`Редагувати школу ${school}`}
            onCancel={setModalClose}
            setModalClose={setModalClose}
            visible={true}
            currentRecord={currentRecord}
            ModalBody={() => <EditSchoolModal modelData={currentRecord} />}
            serverRequest={editSchoolToServer}
            setModalData={setModalData}
            modalData={modalData}
          />
        )}
        {/* removeSchoolFromServer */}
        {modal === 'delete-school' && (
          <UniversalModal
            title={`Видалити школу ${school}?`}
            onCancel={setModalClose}
            setModalClose={setModalClose}
            visible={true}
            currentRecord={currentRecord}
            ModalBody={() => <DeleteSchoolModal modelData={currentRecord} />}
            serverRequest={removeSchoolFromServer}
            setModalData={setModalData}
            modalData={currentRecord._id}
          />
        )}
        <TableWrap>
          <CountrysTable />
        </TableWrap>
        <TableWrap>
          <SchoolsTable />
        </TableWrap>
      </PageSection>
    );
  }
);

export default AdminProgramDetails;
