import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Containers
import Actions from './Actions';
import AddUserButton from './AddUserButton';

// Components
import { Table } from 'ui';
import _columns from './columns';

const columns = _columns({ Actions, AddUserButton });

const getDataFromStore = ({ adminProgramDetails: { countrys } }) => ({
  countrys,
});
const getMethodsFromStore = ({ adminProgramDetails: { getAllCountrys } }) => ({
  getAllCountrys,
});

export const CountrysTable = connect(
  getDataFromStore,
  getMethodsFromStore
)(
  (
    // props
    { countrys = [], getAllCountrys = () => {} }
  ) => {
    useEffect(() => {
      getAllCountrys({});
    }, [getAllCountrys]);

    return (
      <Table
        bordered
        showHeader={true}
        dataSource={countrys.map((u, k) => ({ ...u, key: k }))}
        columns={columns}
      />
    );
  }
);

export default CountrysTable;
