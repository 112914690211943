export const components = ({ handleFields = () => {} }) => [
  {
    type: 'input',
    value: 'code',
    behaviours: { onChange: handleFields('code') },
    defaultValue: 'code',
    placeholder: 'Код номенклатури',
  },
  {
    type: 'date',
    // type: 'input',
    value: 'startDate',
    behaviours: { onChange: handleFields('startDate') },
    defaultValue: 'startDate',
    placeholder: 'Дата старту програми',
  },
  {
    type: 'date',
    // type: 'input',
    value: 'finishDate',
    behaviours: { onChange: handleFields('finishDate') },
    defaultValue: 'finishDate',
    placeholder: 'Дата кінця програми',
  },
];
