// Libraries
import styled from 'styled-components';

export const Input = styled.input`
  visibility: hidden;
  width: 1px;
  height: 1px;
`;

export const Wrap = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 90px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const Label = styled.label`
  padding: 6px 12px;
  border: 1px solid #007bff;
  border-radius: 10px;
  cursor: pointer;
  margin: 0;
`;

export const Button = styled.button`
  padding: 6px 12px;
  border: 1px solid #007bff;
  border-radius: 10px;
  cursor: pointer;
  margin: 0;
  :focus {
    outline: goldenrod;
  }
`;
