export const services = [
  'Бординг - послуги за рамковою угодою (проживання )',
  '1-4 клас (освітні послуги) до 14:00 - послуги за рамковою угодою',
  '1-11 клас (освітні послуги) послуги за рамковою угодою',
  'Індивідуальні уроки local',
  'Індивідуальні уроки native',
  'Літня школа',
  'Star Kids Варшава (60 хв./3 рази на тиждень)',
  'Star Kids Варшава (80 хв./3 рази на тиждень)',
  'Smart teens Варшава (80 хв./3 рази на тиждень)',
  'Academic English Варшава',
  'StartUp IT Academy',
  'Mix Hub Warszawa',
  'Mini Hub Warszawa',
  'Послуга організації харчування Warszawa',
  'Послуга організації Трансферу Warszawa',
  'Шкільна форма Warszawa',
  'Послуга покращеного проживання Warszawa',
];
