import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { SyncOutlined } from '@ant-design/icons';
// Components
import { Select, Label } from 'components/Fields';

// Utils
import { selectDataProcessor, getValueFromEvent } from 'utils';
import { CheckProgramNameToFields } from 'utils/checkProgramNames';
const customeEducationLocationList = [
  { label: 'Бординг Ірпінь', value: 'Бординг Ірпінь' },
  { label: 'Табір Адмірал', value: 'Табір Адмірал' },
  { label: 'Табір Олімпік', value: 'Табір Олімпік' },
  { label: 'Табір Карпати', value: 'Табір Карпати' },
  { label: 'Міжнародна Школа', value: 'Міжнародна Школа' },
  { label: 'Групові поїздки', value: 'Групові поїздки' },
];
// Redux
const getDataFromStore = ({
  main: {
    programInfo,
    selectedValues,
    program_types,
    program_names,
    paymant,
    locations,
    main_id,
    educationLocations,
    loading,
    country_list,
    school_list,
  },
}) => ({
  programInfo,
  selectedValues,
  program_types,
  program_names,
  paymant,
  locations,
  main_id,
  educationLocations,
  loading,
  country_list,
  school_list,
});
const getMethodsFromStore = ({
  main: {
    setState,
    getProgramTypes,
    getProgramNames,
    getPaymentMethods,
    getPaymentLocations,
    getEducationLocations,
    getRecipient,
    getManagers,
    getCountryList,
    getSchoolList,
  },
}) => ({
  setState,
  getProgramTypes,
  getProgramNames,
  getPaymentMethods,
  getPaymentLocations,
  getEducationLocations,
  getRecipient,
  getManagers,
  getCountryList,
  getSchoolList,
});

export default connect(
  getDataFromStore,
  getMethodsFromStore
)(
  ({
    loading = false,
    main_id = '',
    programInfo = {},
    selectedValues = {},
    program_types = [],
    program_names = [],
    paymant = [],
    locations = [],
    educationLocations = [],
    country_list = [],
    school_list = [],
    setState = () => {},
    getProgramTypes = () => {},
    getProgramNames = () => {},
    getPaymentMethods = () => {},
    getPaymentLocations = () => {},
    getEducationLocations = () => {},
    getRecipient = () => {},
    getManagers = () => {},
    getCountryList = () => {},
    getSchoolList = () => {},
  }) => {
    const placeholder = 'Не вибрано';

    const {
      programType = null,
      programName = null,
      paymant: _paymant = null,
      location = null,
      educationLocation = null,
      dop_school = null,
      dop_country = null,
    } = selectedValues;

    useEffect(() => {
      getProgramTypes();
    }, [getProgramTypes]);

    useEffect(() => {
      getCountryList();
    }, [getCountryList]);
    useEffect(() => {
      getSchoolList();
    }, [getSchoolList]);
    useEffect(() => {
      programInfo?.code && getEducationLocations({ code: programInfo?.code || '' });
    }, [programInfo]);

    const programTypes = useMemo(() => selectDataProcessor(program_types), [
      program_types,
    ]);

    const programNames = useMemo(() => selectDataProcessor(program_names), [
      program_names,
    ]);

    const paymentMethods = useMemo(() => selectDataProcessor(paymant), [paymant]);

    const paymentLocations = useMemo(() => selectDataProcessor(locations), [locations]);
    const paymentLocationsOptions = useMemo(
      () => selectDataProcessor(educationLocations),
      [educationLocations]
    );
    // const educationLocations = paymentLocations.filter(
    //   (item) => item?.label !== 'STUDY.UA Драгомирова 16'
    // );

    const schoolLists = useMemo(() => selectDataProcessor(school_list), [school_list]);
    const countryLists = useMemo(() => selectDataProcessor(country_list), [country_list]);

    const programTypeDisabled = main_id || !programTypes.length;
    const programNameDisabled = main_id || !programNames.length;
    const paymentMethodDisabled = !main_id && !paymentMethods.length;
    const paymentLocationDisabled = !main_id && !paymentLocations.length;
    const schoolListsDisabled = !main_id && !schoolLists.length;
    const countryListsDisabled = main_id || !countryLists.length;

    const programTypeChange = (e) => {
      const value = getValueFromEvent(e);

      setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          programType: value,
          programName: null,
          paymant: null,
          location: null,
          educationLocation: null,
          recipient: null,
        },
        paymant: [],
        locations: [],
      }));

      getProgramNames(value);
    };

    const programNameChange = async (e) => {
      const value = getValueFromEvent(e);

      setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          programName: value,
          paymant: null,
          location: null,
          educationLocation: null,
          recipient: null,
        },
        paymant: [],
      }));

      getPaymentMethods({
        program_type: programType,
        nomenclature_1s: value,
      });
    };

    const paymentMethodChange = async (e) => {
      const value = getValueFromEvent(e);

      setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          paymant: value,
          location: null,
          educationLocation: null,
          recipient: null,
        },
      }));

      getPaymentLocations({
        program_type: programType,
        nomenclature_1s: programName,
        payment_type: value,
      });
    };

    const paymentLocationChange = async (e) => {
      const value = getValueFromEvent(e);

      setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          location: value,
        },
      }));
      getRecipient({
        program_type: programType,
        nomenclature_1s: programName,
        payment_type: _paymant,
        location: value,
      });
      getManagers();
    };

    const educationLocationChange = async (e) => {
      const value = getValueFromEvent(e);

      setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          educationLocation: value,
        },
      }));
    };

    const dopSchoolChange = async (e) => {
      const value = getValueFromEvent(e);

      setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          dop_school: value,
        },
      }));
    };
    const dopContryChange = async (e) => {
      const value = getValueFromEvent(e);

      setState((state) => ({
        selectedValues: {
          ...state.selectedValues,
          dop_country: value,
        },
      }));
    };

    return (
      <>
        <div style={{ textAlign: 'center' }}>{loading ? <SyncOutlined spin /> : ''}</div>
        <Label text="Тип програми:" required>
          <Select
            allowClear={true}
            placeholder={placeholder}
            options={programTypes}
            value={programType}
            disabled={programTypeDisabled}
            onChange={programTypeChange}
          />
        </Label>

        <Label text="Назва програми:" required>
          <Select
            showSearch
            allowClear={true}
            placeholder={placeholder}
            options={programNames}
            value={programName}
            disabled={programNameDisabled}
            onChange={programNameChange}
          />
        </Label>
        {CheckProgramNameToFields(programName) ? (
          <>
            <hr style={{ marginBottom: -4, marginTop: 24 }} />
            <Label text="Доп. Школа:" required>
              <Select
                showSearch
                allowClear={true}
                placeholder={placeholder}
                options={schoolLists}
                value={dop_school}
                disabled={schoolListsDisabled}
                onChange={dopSchoolChange}
              />
            </Label>
            <Label text="Доп. Країна:" required>
              <Select
                showSearch
                allowClear={true}
                placeholder={placeholder}
                options={countryLists}
                value={dop_country}
                disabled={countryListsDisabled}
                onChange={dopContryChange}
              />
            </Label>
            <br />
            <hr />
          </>
        ) : (
          ''
        )}
        <Label text="Спосіб оплати:" required>
          <Select
            allowClear={true}
            placeholder={placeholder}
            options={paymentMethods}
            value={_paymant}
            disabled={paymentMethodDisabled}
            onChange={paymentMethodChange}
          />
        </Label>

        <Label text="Локація продажу:" required>
          <Select
            showSearch
            allowClear={true}
            placeholder={placeholder}
            options={paymentLocations}
            value={location}
            disabled={paymentLocationDisabled}
            onChange={paymentLocationChange}
          />
        </Label>

        <Label text="Локація навчання:" required>
          <Select
            showSearch
            allowClear={true}
            placeholder={placeholder}
            // options={paymentLocations}
            // options={educationLocations.concat(customeEducationLocationList)}
            options={
              paymentLocationsOptions.length > 0
                ? paymentLocationsOptions
                : paymentLocations
            }
            value={educationLocation}
            disabled={paymentLocationDisabled}
            onChange={educationLocationChange}
          />
        </Label>
      </>
    );
  }
);
