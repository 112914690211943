import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Containers
import Actions from './Actions';
import AddButton from './AddButton';

// Components
import { Table } from 'ui';
import _columns from './columns';

const columns = _columns({ Actions, AddButton });

const getDataFromStore = ({ adminProgramDates: { program_dates } }) => ({
  program_dates,
});
const getMethodsFromStore = ({ adminProgramDates: { getAllProgramDates } }) => ({
  getAllProgramDates,
});

export const ProgramDatesTable = connect(
  getDataFromStore,
  getMethodsFromStore
)(
  (
    // props
    { program_dates = [], getAllProgramDates = () => {} }
  ) => {
    // const params = { code: 'НФ-00000356' }; // для пошуку
    useEffect(() => {
      getAllProgramDates({});
      // getAllProgramDates(params);
    }, [getAllProgramDates]);

    return (
      <Table
        bordered
        showHeader={true}
        dataSource={program_dates?.map((u, k) => ({ ...u, key: k }))}
        columns={columns}
      />
    );
  }
);

export default ProgramDatesTable;
