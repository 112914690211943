import React, { useMemo } from 'react';
import { connect } from 'react-redux';

// Utils
import { useHandleFields, selectDataProcessor } from 'utils';

// Components
import { Select, Input, Label } from 'components/Fields';

// Styled
import { Title } from '../styles';

const placeholder = 'Не вибрано';

// Redux
const getDataFromStore = ({
  main: { ukraine_education, sellChannels, englishLevels, main_id },
}) => ({
  ukraine_education,
  sellChannels,
  englishLevels,
  main_id,
});
const getMethodsFromStore = ({ main: { setState } }) => ({
  setState,
});

export default connect(
  getDataFromStore,
  getMethodsFromStore
)(
  ({
    ukraine_education = {},
    sellChannels = [],
    englishLevels = [],
    setState = () => {},
    main_id = '',
  }) => {
    const {
      education_group = '',
      english_level = null,
      attraction_channel = null,
    } = ukraine_education;

    const _sellChannels = useMemo(() => selectDataProcessor(sellChannels), [
      sellChannels,
    ]);
    const _englishLevels = useMemo(() => selectDataProcessor(englishLevels), [
      englishLevels,
    ]);

    const sellChannelDisabled = !_sellChannels.length;
    const englishLevelDisabled = !_englishLevels.length;

    const handleFields = useHandleFields(setState);

    return (
      <>
        <Title>ДОДАТКОВА ІНФОРМАЦІЯ ПРО НАВЧАННЯ В УКРАЇНІ</Title>
        <hr />

        <Label margin="0">
          <Input
            disabled={main_id}
            addonBefore="Номер групи навчання"
            value={education_group}
            onChange={handleFields('ukraine_education.education_group')}
          />
        </Label>

        <Label>
          <Select
            addonBefore="Рівень англійської на сьогодні"
            placeholder={placeholder}
            options={_englishLevels.map((level) => ({
              ...level,
              label: level.title,
              value: level.title,
            }))}
            value={english_level}
            disabled={main_id || englishLevelDisabled}
            onChange={handleFields('ukraine_education.english_level')}
          />
        </Label>

        <Label required>
          <Select
            addonBefore="Канал продажу"
            placeholder={placeholder}
            options={_sellChannels.map((channel) => ({
              ...channel,
              label: channel.title,
              value: channel.title,
            }))}
            value={attraction_channel}
            disabled={main_id || sellChannelDisabled}
            onChange={handleFields('ukraine_education.attraction_channel')}
          />
        </Label>
      </>
    );
  }
);
