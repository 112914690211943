// Libraries
import React, { useEffect, useState } from 'react';
// Styles
import { Button, CurrencyName, Input, Table, Td, Th, Tr, WrapBorder } from './style';

// Components
import { notification } from 'antd';
import Loader from '../../ui/Loader';

// Providers
import { FormProvider, useForm } from 'react-hook-form';

// API
import { requests } from '../main/controllers';

export const ManualCurrency = ({ firstCurrency, secondCurrency }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [currency, setCurrency] = useState(null);
  const concatedCurrency = `${firstCurrency}_${secondCurrency}`;
  // Gets the current exchange rate
  useEffect(() => {
    (async () => {
      const res = await requests.getCurrenciesManualByKey(concatedCurrency);

      setCurrency({
        buy: parseFloat(res?.exchange?.buy || 0).toFixed(2),
        sell: parseFloat(res?.exchange?.sell || 0).toFixed(2),
      });
    })();
  }, []);

  const form = useForm();
  const { register, handleSubmit, reset } = form;

  const onSubmit = async (body) => {
    setIsLoading(true);
    const requestBody = {
      key: concatedCurrency,
      exchange: {
        sell: +body?.sell || 0,
        buy: +body?.buy || 0,
      },
    };

    const response = await requests.setCurrenciesManual(requestBody);
    setIsLoading(false);
    if (response?.error) {
      notification.error({
        message: 'Помилка зміни курсу',
        description: response?.message,
        duration: 5,
      });
    } else {
      notification.success({
        message: 'Курс успішно змінено',
        duration: 5,
      });
    }
  };

  return (
    <>
      <WrapBorder>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Table>
              <thead>
                <Tr>
                  <Th width="50%">У Відділеннях</Th>
                  <Th width="25%">Купівля</Th>
                  <Th width="25%">Продаж</Th>
                </Tr>
              </thead>
              <tbody>
                <Tr>
                  <Td width="50%">
                    <CurrencyName>{secondCurrency}</CurrencyName>
                    <CurrencyName grayed>{firstCurrency}</CurrencyName>
                  </Td>
                  <Td width="25%" textAlign="left">
                    <Input
                      step="any"
                      type="number"
                      defaultValue={currency?.buy}
                      name={`buy`}
                      ref={register}
                    />
                  </Td>
                  <Td width="25%" textAlign="left">
                    <Input
                      step="any"
                      type="number"
                      defaultValue={currency?.sell}
                      name={`sell`}
                      ref={register}
                    />
                  </Td>
                </Tr>
              </tbody>
            </Table>
            {isLoading ? (
              <Loader />
            ) : (
              <Button disabled={isLoading} type="submit">
                Зберегти
              </Button>
            )}
          </form>
        </FormProvider>
      </WrapBorder>
    </>
  );
};
