import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { Flex } from '~/ui';
import { requests } from '../main/controllers';
import { columns } from './columns';
import { connect } from 'react-redux';
import { Checkbox } from '../../ui';

const getDataFromStore = ({ users: { userData } }) => ({
  userData,
});
const getMethodsFromStore = ({ users: { getProfileInfo } }) => ({
  getProfileInfo,
});
export const AdminPaymentLimits = connect(
  getDataFromStore,
  getMethodsFromStore
)(({ getProfileInfo = () => {}, userData = {} }) => {
  const arrayUsersIdForAccess = ['5f97d2d4173eb200270fad96'];

  useEffect(() => {
    getProfileInfo();
  }, []);

  const { _id = '' } = userData;

  const [dataSource, setDataSource] = useState([]);
  const [state, setState] = useState({});

  useEffect(() => {
    (async () => {
      const response = await requests.getAllRecipient();
      setDataSource(response);
    })();
  }, []);
  const handlePagination = (value) => {
    setState(value);
  };

  const modifiedColumns = arrayUsersIdForAccess.includes(_id)
    ? [
        ...columns,
        {
          title: 'Ліміт оплати',
          dataIndex: 'ignoreLimits',
          key: 'ignoreLimits',
          render: (value, item) => <CheckBoxLimits item={item} />,
        },
      ]
    : columns;

  return (
    <Flex margin="0 auto" width="100%" style={{ alignContent: 'center', height: '100%' }}>
      <Table
        style={{ width: '100%' }}
        onChange={(e) => {
          handlePagination(e);
        }}
        pagination={{
          current: state.current,
          pageSize: state.pageSize,
          total: dataSource?.length,
        }}
        dataSource={dataSource}
        rowClassName={(item) => {
          let className;
          if (item.totalAmount >= 4500000 && item.totalAmount < 6500000) {
            className = 'custom_yellow';
          } else if (item.totalAmount >= 6500000) {
            className = 'custome_red';
          }
          return className;
        }}
        columns={modifiedColumns}
      />
    </Flex>
  );
});

const CheckBoxLimits = ({ item = {} }) => {
  const [isChecked, setIsChecked] = useState(false);
  const { ignoreLimits: _ignoreLimits = false } = item;
  useEffect(() => {
    setIsChecked(_ignoreLimits);
  }, []);
  const handleChangeIgnoreLimit = async () => {
    const newIsChecked = !isChecked;
    await requests.changeLimitRecipients(item?._id, { ignoreLimits: newIsChecked });
    setIsChecked(newIsChecked);
  };

  return <Checkbox checked={isChecked} onChange={handleChangeIgnoreLimit} />;
};
