import usersController from './requests';
import { handleRequestError } from 'utils';
import initialState from './initialState';

export const model = {
  state: initialState,
  reducers: {
    resetState: () => initialState,
    setUsers(state, payload) {
      return {
        ...state,
        users: payload,
      };
    },
    setСountrys(state, payload) {
      return {
        ...state,
        countrys: payload,
      };
    },
    setSchools(state, payload) {
      return {
        ...state,
        schools: payload,
      };
    },
    setModalData(state, payload) {
      return {
        ...state,
        modalData: payload,
      };
    },
    addUser(state, newUser) {
      return {
        ...state,
        users: [...state.users, newUser],
      };
    },
    addCountry(state, newCountry) {
      return {
        ...state,
        countrys: [...state.countrys, newCountry],
      };
    },
    addSchool(state, newSchool) {
      return {
        ...state,
        schools: [...state.schools, newSchool],
      };
    },
    editUser(state, edUser) {
      return {
        ...state,
        users: state.users.map((r) =>
          String(edUser._id) === String(r._id) ? edUser : r
        ),
      };
    },
    editCountry(state, edCountry) {
      return {
        ...state,
        countrys: state.countrys.map((r) =>
          String(edCountry._id) === String(r._id) ? edCountry : r
        ),
      };
    },
    editSchool(state, edSchool) {
      return {
        ...state,
        schools: state.schools.map((r) =>
          String(edSchool._id) === String(r._id) ? edSchool : r
        ),
      };
    },
    removeUser(state, reUser) {
      return {
        ...state,
        users: state.users.filter((r) => reUser._id !== r._id),
      };
    },
    setModal(state, payload) {
      return {
        ...state,
        modal: payload,
      };
    },
    setCurrentRecord(state, payload) {
      return {
        ...state,
        currentRecord: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async getAllCountrys(params, rootState) {
      try {
        const result = await usersController.getAllCountrys(params);

        if (!Array.isArray(result)) throw result;

        dispatch.adminProgramDetails.setСountrys(result);
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminProgramDetails.getAllCountrys,
          params,
        });
      }
    },
    async getAllSchools(params, rootState) {
      try {
        const result = await usersController.getAllSchools(params);

        if (!Array.isArray(result)) throw result;

        dispatch.adminProgramDetails.setSchools(result);
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminProgramDetails.getAllCountrys,
          params,
        });
      }
    },
    async getAllUsers(params, rootState) {
      try {
        const result = await usersController.getAllUsers(params);

        if (!Array.isArray(result)) throw result;

        dispatch.adminProgramDetails.setUsers(result);
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminProgramDetails.getAllUsers,
          params,
        });
      }
    },
    async addCountryToServer(params, rootState) {
      try {
        const result = await usersController.addCountryToServer(params);

        await dispatch.adminProgramDetails.addCountry(params);
        await dispatch.adminProgramDetails.getAllCountrys({});
        return result;
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminProgramDetails.addCountryToServer,
          params,
        });
      }
    },
    async editCountryToServer(params = {}, rootState) {
      const currentRecord = rootState?.adminProgramDetails?.currentRecord || {};
      const requestBody = { ...currentRecord, ...params };

      try {
        await usersController.editCountryToServer(requestBody);

        dispatch.adminProgramDetails.editCountry(params);
        dispatch.adminProgramDetails.getAllCountrys({});
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminProgramDetails.editCountryToServer,
          params: requestBody,
        });
      }
    },
    async removeCountryFromServer(_id = '', rootState) {
      try {
        await usersController.removeCountryFromServer(_id);

        dispatch.adminProgramDetails.getAllCountrys({});
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminProgramDetails.removeCountryFromServer,
          params: _id,
        });
      }
    },
    //addSchool
    async addSchoolToServer(params, rootState) {
      try {
        const result = await usersController.addSchoolToServer(params);

        await dispatch.adminProgramDetails.addSchool(params);
        await dispatch.adminProgramDetails.getAllSchools({});
        return result;
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminProgramDetails.addSchoolToServer,
          params,
        });
      }
    },
    // editSchoolToServer
    async editSchoolToServer(params = {}, rootState) {
      const currentRecord = rootState?.adminProgramDetails?.currentRecord || {};
      const requestBody = { ...currentRecord, ...params };

      try {
        await usersController.editSchoolToServer(requestBody);

        dispatch.adminProgramDetails.editSchool(params);
        dispatch.adminProgramDetails.getAllSchools({});
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminProgramDetails.editSchoolToServer,
          params: requestBody,
        });
      }
    },
    async removeSchoolFromServer(_id = '', rootState) {
      try {
        await usersController.removeSchoolFromServer(_id);

        dispatch.adminProgramDetails.getAllSchools({});
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminProgramDetails.removeSchoolFromServer,
          params: _id,
        });
      }
    },
  }),
};

export default model;
