import styled from 'styled-components';

export const Contaiter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
`;
export const ListContainer = styled.div`
  position: absolute;
  top: 100%;
  padding: 5px;
  padding-bottom: 10px;
  border: 1px solid #d9d9d9;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 100;
  transform-origin: top;
  transform: scaleY(0);
  transition: 0.3s;
  &.open {
    transform: scaleY(1);
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    width: 1px;
    height: 40px;
    background-color: transparent;
  }
`;
export const CustomeButton = styled.button`
  position: relative;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  transition: 0.3s;
  z-index: 50;
  &,
  &:focus {
    outline: none;
  }
  & > span {
    text-align: left;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    right: 15px;
    width: 10px;
    height: 10px;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    transform-origin: center;
    transform: rotate(315deg);
    transition: 0.3s;
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &.open {
    z-index: 70;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    &:hover {
      cursor: auto;
      text-decoration: none;
    }
    &::after {
      top: 14px;
      transform: rotate(-225deg);
    }
    &::before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
    }
  }
`;
