import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { pathChecks } from 'utils';

// Styled
import { Container, LeftSide, RightSide, LogOutButton } from './styles';

// Icons
import { MenuUnfoldOutlined } from '@ant-design/icons';

const getDataFromStore = ({ _app: { sideMenuWidth } }) => ({ sideMenuWidth });
const getMethodsFromStore = ({ _app: { setState }, users: { logOut } }) => ({
  setState,
  logOut,
});

export const Header = connect(
  getDataFromStore,
  getMethodsFromStore
)(({ sideMenuWidth = 0, setState = () => {}, logOut = () => {} }) => {
  const location = useLocation();
  const inAdminPage = pathChecks.isAdminPath(location);

  const handleSideBarClick = () => {
    setState({ sideMenuWidth: sideMenuWidth ? 0 : '180px' });
  };

  return (
    <Container>
      <LeftSide>
        {inAdminPage ? (
          <MenuUnfoldOutlined
            style={{ fontSize: 22, margin: '0 20px' }}
            onClick={handleSideBarClick}
          />
        ) : (
          ''
        )}
      </LeftSide>
      <RightSide>
        {inAdminPage ? <LogOutButton onClick={() => logOut()}>Вийти</LogOutButton> : ''}
      </RightSide>
    </Container>
  );
});

export default Header;
