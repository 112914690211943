// Libraries
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

// UI
import { Divider } from 'antd';
import { Button, Flex, Title, Tooltip } from 'ui';

// Components
import {
  Date,
  Input,
  TextArea,
  InputNumber,
  SubmitButton,
  Label,
} from 'components/Fields';
import { SearchPayer } from './searchPayer';
import { SearchStudent } from './searchStudent';

// Utils
import { useHandleFields } from 'utils';

// Styled
import { container, inputHeight, ClientForm, Wrap, Text } from './styles';

const NumberField = inputHeight(InputNumber, '40px');
const TextField = inputHeight(Input, '40px');

const Container = container(Flex);

const getDataFromStore = ({
  main: { selectedValues, client, orderCopy, main_id },
  adminOrders: { orders },
}) => ({
  selectedValues,
  client,
  orderCopy,
  main_id,
  orders,
});
const getMethodsFromStore = ({ main: { setState } }) => ({
  setState,
});

export default connect(
  getDataFromStore,
  getMethodsFromStore
)(
  ({
    selectedValues = {},
    client = {},
    main_id = '',
    setState = () => {},
    orders = [],
    ...props
  }) => {
    const { link_AMO_CRM } = selectedValues;
    const {
      // firstName = '',
      // lastName = '',
      // middleName = '',
      student_name = '',
      payer_name = '',
      birth_date = '',
      payer_tax_ID = '',
      address = '',
      now_class_course = '',
      passport = '',
      contact_phone = '',
      email = '',
    } = client;
    const handleFields = useHandleFields(setState);

    const handleCopyPayerName = () => {
      setState((state) => {
        const { client = {} } = state;
        const { payer_name = '' } = client;
        return {
          client: {
            ...client,
            student_name: payer_name,
          },
        };
      });
    };
    const disabledDate = (current) => {
      return current && current > moment().endOf('day');
    };

    // const getStudentName = () => {
    //   return `${lastName} ${firstName} ${middleName}`;
    // };

    // useEffect(() => {
    //   const student_name = getStudentName();
    //   setState((prev) => ({ ...prev, client: { ...prev.client, student_name } }));
    // }, [firstName, lastName, middleName]);

    const [isOrder, setIsOrder] = useState(false);
    const [ordersSearch, setOrdersSearch] = useState([]);
    useEffect(() => {
      if (!isOrder) return;
      setOrdersSearch(orders);
    }, [orders]);

    return (
      <Container direction="column">
        <Title>Ідентифікація клієнта</Title>
        <div style={{ display: 'flex' }}>
          <ClientForm style={{ position: 'relative' }} {...props}>
            <Divider orientation="center">Платник</Divider>
            {main_id && (
              <Wrap>
                <Text>{`Доплата для замовлення ${main_id}`}</Text>
              </Wrap>
            )}
            <Divider orientation="left">Пошук</Divider>
            <SearchPayer />
            <Divider />
            <Label required>
              <TextField
                disabled={main_id}
                value={payer_name}
                addonBefore="Платник ПІБ"
                onChange={handleFields('client.payer_name')}
              />
            </Label>
            <Label>
              <TextField
                disabled={main_id}
                addonBefore="Контактний телефон:"
                value={contact_phone}
                onChange={handleFields('client.contact_phone')}
              />
            </Label>
            <Label>
              <TextField
                disabled={main_id}
                addonBefore="Email:"
                value={email}
                onChange={handleFields('client.email')}
              />
            </Label>

            <Label required>
              <NumberField
                disabled={main_id}
                addonBefore="ІНН платника"
                value={payer_tax_ID}
                onChange={handleFields('client.payer_tax_ID')}
              />
            </Label>
            <Label required>
              <TextField
                disabled={main_id}
                addonBefore="Фактична адреса проживання"
                value={address}
                onChange={handleFields('client.address')}
              />
            </Label>
            <Label required>
              <TextArea
                disabled={main_id}
                addonBefore="Дані паспорта платника"
                value={passport}
                onChange={handleFields('client.passport')}
              />
            </Label>
            <Divider orientation="center">Студент</Divider>
            <Divider orientation="left">Пошук (ПІБ)</Divider>
            <SearchStudent setIsOrder={setIsOrder} />
            <Divider />
            <Label required>
              <TextField
                disabled={main_id}
                addonBefore="Студент ПІБ"
                addonAfter={
                  <Tooltip title="Скопіювати ПІБ платника">
                    <Button type="link" onClick={handleCopyPayerName}>
                      Той самий
                    </Button>
                  </Tooltip>
                }
                value={student_name}
                onChange={handleFields('client.student_name')}
              />
            </Label>
            <Label required>
              <Date
                disabled={main_id}
                addonBefore="Дата народження"
                onChange={handleFields('client.birth_date')}
                format={'DD-MM-YYYY'}
                defaultValue={birth_date ? moment(birth_date, 'DD-MM-YYYY') : ''}
                disabledDate={disabledDate}
                value={birth_date ? moment(birth_date, 'DD-MM-YYYY') : ''}
              />
            </Label>
            <Divider />
            <Label required>
              <TextField
                disabled={main_id}
                addonBefore="Посилання на AMO CRM"
                value={link_AMO_CRM}
                onChange={handleFields('selectedValues.link_AMO_CRM')}
              />
            </Label>
            <Label>
              <TextField
                addonBefore="Клас/курс (на момент старту програми)"
                value={now_class_course}
                onChange={handleFields('client.now_class_course')}
              />
            </Label>
            <div style={{ margin: '30px 0 20px 0' }}>
              <SubmitButton
                text="Далі >"
                onClick={() => setOrdersSearch([])}
                disabled={
                  !student_name ||
                  !payer_name ||
                  !birth_date ||
                  !payer_tax_ID ||
                  !address ||
                  !passport ||
                  !link_AMO_CRM
                }
              />
            </div>
          </ClientForm>
          {ordersSearch?.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                marginLeft: '20px',
              }}>
              {ordersSearch?.length > 0 &&
                ordersSearch.slice(0, 10).map((order) => {
                  const {
                    manager,
                    programName,
                    createDate,
                    amount_payable,
                    currency,
                    price_wiht_discount,
                    conversion_rate,
                    order_id,
                  } = order;
                  return (
                    <div
                      key={order_id}
                      style={{
                        display: 'flex',
                        width: '400px',
                        flexDirection: 'column',
                        border: '1px solid #c1c1c1',
                        padding: '5px',
                      }}>
                      <span style={{ width: 'fit-content' }}>
                        <b>ID замовлення:</b> {order_id}
                      </span>
                      <span style={{ width: 'fit-content' }}>
                        <b>Назва програми:</b> {programName}
                      </span>
                      <span style={{ width: 'fit-content' }}>
                        <b>Менеджер:</b> {manager.firstName} {manager.lastName}
                      </span>
                      <span style={{ width: 'fit-content' }}>
                        <b>Сума договору:</b>
                        {currency === 'UAH'
                          ? ` ${price_wiht_discount} UAH`
                          : ` ${amount_payable} ${currency} |
                        ${price_wiht_discount * conversion_rate} UAH`}
                      </span>
                      <span style={{ width: 'fit-content' }}>
                        <b>Дата створення:</b>
                        {` ${createDate.d}.${createDate.m}.${createDate.y}`}
                      </span>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </Container>
    );
  }
);
