import styled from 'styled-components';

// UI
import { Flex } from 'ui';

export const Container = styled(Flex)`
  max-width: 480px;
  margin: auto;
  border-radius: 5px;
  background-color: #f3f3f3;

  & > div {
    margin: 10px !important;
  }

  .UserContainer {
    margin: 0;

    .InfoContainer {
      .TextContainer {
        min-width: 150px;
        margin-left: 20px;
        margin-right: 20px;
        & > * {
          margin: 2px !important;
        }
      }

      .EditButtonsContainer {
        & > button {
          border-radius: 5px;
          padding: 0 !important;
          min-width: 30px;
          max-width: 30px;
          min-height: 30px;
          line-height: 30px;
        }
      }
    }
  }

  .ButtonsContainer {
    margin: 0;

    & > button {
      max-width: 150px !important;
      height: auto !important;
      white-space: normal !important;
    }
  }
`;
