import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Link,
  // PDFDownloadLink,
  // PDFViewer,
  Font,
} from '@react-pdf/renderer';
import TimeNewRoman from './fonts/Times_New_Roman.ttf';
import TimeNewRoman_bold from './fonts/Times_New_Roman_Bold.ttf';
import { TransformNumberInWords } from '../../services/NumbersInWords';

Font.register({
  family: 'TimeNewRoman',
  src: TimeNewRoman,
});
Font.register({ family: 'TimeNewRoman_bold', src: TimeNewRoman_bold });

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingLeft: 30,
    backgroundColor: '#fff',
    fontSize: 10,
    fontFamily: 'TimeNewRoman',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  border: {
    border: 1,
    padding: '10px 5px',
    position: 'relative',
  },
  p_absolute: {
    position: 'absolute',
    top: 50,
    right: 20,
    flexDirection: 'colum',
    alignItems: 'center',
  },
  m_b: {
    marginBottom: 5,
  },
  title: {
    fontSize: 13,
    marginTop: 15,
    marginBottom: 15,
    padding: '2px 5px',
    borderBottom: 1,
    // fontFamily: 'TimeNewRoman_bold',
  },
  text_left: {
    minWidth: 100,
    textAlign: 'left',
    marginRight: 10,
  },
  center_block: {
    minWidth: 500,
  },
  row_mb: {
    flexDirection: 'row',
    marginBottom: 10,
  },
});

export const Order = (props) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.border}>
            <Text>
              Одержувач:{'   '}
              {props.orderValues?.selectedValues?.recipient?.title || '111'}
            </Text>
            <View style={styles.row}>
              <Text> Код ЄДРПОУ</Text>
              <Text style={styles.border}>
                {(props.orderValues.recipient || {}).edrpou ||
                  ((props.orderValues.selectedValues || {}).recipient || {}).edrpou ||
                  ''}
              </Text>
            </View>
            <Text style={styles.m_b}>Банк одержувача</Text>
            <Text style={styles.m_b}>
              {(props.orderValues.recipient || {}).bank ||
                ((props.orderValues.selectedValues || {}).recipient || {}).bank ||
                ''}
            </Text>
            <Text>
              Призначення платежу: Оплата за освітні послуги зг. рахунку на оплату
              замовлення №
              {props.orderValues.order_id || props.orderValues.unique_order_number || ''}{' '}
              від {props.orderValues.date_create || ''} року, без ПДВ
            </Text>
            <Text style={{ position: 'absolute', top: 30, right: 70 }}>
              КРЕДИТ рах. N
            </Text>
            <Text
              style={{
                position: 'absolute',
                top: 50,
                right: 10,
                border: 1,
                padding: '5px 10px',
              }}>
              {(props.orderValues.recipient || {}).iban ||
                ((props.orderValues.selectedValues || {}).recipient || {}).iban ||
                ''}
            </Text>
          </View>
          <View>
            <Text style={styles.title}>
              Рахунок на оплату замовлення такого вида №{' '}
              {props.orderValues.order_id || props.orderValues.unique_order_number || ''}{' '}
              від {props.orderValues.date_create || ''} року
            </Text>
          </View>
          <View style={styles.row_mb}>
            <Text style={styles.text_left}>Отримувач:</Text>
            <View>
              <Text>
                {(props.orderValues.recipient || {}).title ||
                  ((props.orderValues.selectedValues || {}).recipient || {}).title ||
                  ''}
              </Text>
              <Text>
                {'IBAN '}
                {(props.orderValues.recipient || {}).iban ||
                  ((props.orderValues.selectedValues || {}).recipient || {}).iban ||
                  ''}
                , в{' '}
                {(props.orderValues.recipient || {}).bank ||
                  ((props.orderValues.selectedValues || {}).recipient || {}).bank ||
                  ''}
              </Text>
              <Text>
                код за ЄДРПОУ{' '}
                {(props.orderValues.recipient || {}).edrpou ||
                  ((props.orderValues.selectedValues || {}).recipient || {}).edrpou ||
                  ''}
              </Text>
              <Text>
                {(props.orderValues.recipient || {}).details ||
                  ((props.orderValues.selectedValues || {}).recipient || {}).details ||
                  ''}
              </Text>
              <Text></Text>
            </View>
            <Text></Text>
          </View>
          <View style={styles.row_mb}>
            <Text style={styles.text_left}>Замовник:</Text>
            <View style={{ fontFamily: 'TimeNewRoman_bold' }}>
              <Text>Фізична особа:</Text>
              <Text>{(props.orderValues.client || {}).payer_name || ''}</Text>
            </View>
            <Text></Text>
          </View>
          <View style={styles.row_mb}>
            <Text style={styles.text_left}>Договір:</Text>
            <View>
              <Link
                src={
                  (props.orderValues.recipient || {}).offer_agreement_link ||
                  ((props.orderValues.selectedValues || {}).recipient || {})
                    .offer_agreement_link ||
                  ''
                }>
                {(props.orderValues.recipient || {}).offer_agreement_link ||
                  ((props.orderValues.selectedValues || {}).recipient || {})
                    .offer_agreement_link ||
                  ''}
              </Link>
            </View>
            <Text></Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 20,
              textAlign: 'center',
              fontFamily: 'TimeNewRoman_bold',
            }}>
            <Text style={{ border: 1, width: 30, padding: 10 }}>№</Text>
            <Text style={{ border: 1, width: 300, padding: 10, borderLeft: 0 }}>
              Номенклатура
            </Text>
            <Text
              style={{
                border: 1,
                width: 40,
                padding: '3px 7px',
                borderLeft: 0,
              }}>
              Кіл- сть
            </Text>
            <Text
              style={{
                border: 1,
                width: 55,
                padding: '3px 10px',
                borderLeft: 0,
              }}>
              Од. вим.
            </Text>
            <Text
              style={{
                border: 1,
                width: 60,
                padding: '3px 7px',
                borderLeft: 0,
              }}>
              Ціна без ПДВ
            </Text>
            <Text
              style={{
                border: 1,
                width: 60,
                padding: '3px 7px',
                borderLeft: 0,
              }}>
              Сума без ПДВ
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 0,
              textAlign: 'center',
              marginBottom: 20,
            }}>
            <Text style={{ border: 1, width: 30, padding: 10, borderTop: 0 }}>1</Text>
            <Text
              style={{
                border: 1,
                width: 300,
                padding: 10,
                borderLeft: 0,
                borderTop: 0,
                textAlign: 'left',
              }}>
              {(props.orderValues.programName || {}).program_name_for_docs ||
                ((props.orderValues.selectedValues || {}).programName || {})
                  .program_name_for_docs ||
                ''}
            </Text>
            <Text
              style={{
                border: 1,
                width: 40,
                padding: '10px 2px',
                borderLeft: 0,
                borderTop: 0,
              }}>
              1
            </Text>
            <Text
              style={{
                border: 1,
                width: 55,
                padding: '10px 2px',
                borderLeft: 0,
                borderTop: 0,
              }}>
              послуга
            </Text>
            <Text
              style={{
                border: 1,
                width: 60,
                padding: '10px 2px',
                borderLeft: 0,
                borderTop: 0,
              }}>
              {parseInt(
                (
                  props.orderValues.amount_payable_in_UAH ||
                  (props.orderValues.selectedValues || {}).amount_payable_in_UAH ||
                  '0'
                ).split('.')[0]
              )
                .toLocaleString()
                .replace(/,|\.|-/g, ' ')}
              {'.00'}
            </Text>
            <Text
              style={{
                border: 1,
                width: 60,
                padding: '10px 2px',
                borderLeft: 0,
                borderTop: 0,
              }}>
              {parseInt(
                (
                  props.orderValues.amount_payable_in_UAH ||
                  (props.orderValues.selectedValues || {}).amount_payable_in_UAH ||
                  '0'
                ).split('.')[0]
              )
                .toLocaleString()
                .replace(/,|\.|-/g, ' ')}
              {'.00'}
            </Text>
          </View>
          <Text
            style={{
              marginBottom: 10,
            }}>
            Призначення платежу: Оплата за освітні послуги зг. рахунку на оплату
            замовлення №
            {props.orderValues.order_id || props.orderValues.unique_order_number || ''}{' '}
            від {props.orderValues.date_create || ''} року, без ПДВ
          </Text>
          <Text
            style={{
              width: '100%',
              textAlign: 'right',
              fontFamily: 'TimeNewRoman_bold',
            }}>
            Всього без ПДВ:{'       '}
            {parseInt(
              (
                props.orderValues.amount_payable_in_UAH ||
                (props.orderValues.selectedValues || {}).amount_payable_in_UAH ||
                '0'
              ).split('.')[0]
            )
              .toLocaleString()
              .replace(/,|\.|-/g, ' ')}
            {'.00'}
          </Text>
          <Text
            style={{
              width: 200,
            }}>
            Всього найменувань 1, на суму{' '}
            {parseInt(
              (
                props.orderValues.amount_payable_in_UAH ||
                (props.orderValues.selectedValues || {}).amount_payable_in_UAH ||
                '0'
              ).split('.')[0]
            )
              .toLocaleString()
              .replace(/,|\.|-/g, ' ')}
            .00 грн.
          </Text>
          <Text>
            {new TransformNumberInWords(
              props.orderValues.amount_payable_in_UAH ||
                (props.orderValues.selectedValues || {}).amount_payable_in_UAH,
              'UAH'
            ).transform()}
            {'.'}
          </Text>
          <Text
            style={{
              fontFamily: 'TimeNewRoman_bold',
              marginBottom: 30,
            }}>
            без ПДВ
          </Text>
          <Text>Рахунок дійсний протягом 3-х банківських днів</Text>
        </View>
        {/* <View
        style={{
          flexDirection: 'row',
          position: 'absolute',
          bottom: 30,
          left: 30,
        }}
      >
        <Text style={{ width: '50%', textAlign: 'center' }}>
          Виконавець ______________________________
        </Text>
        <Text style={{ width: '50%', textAlign: 'center' }}>
          Замовник ______________________________
        </Text>
      </View> */}
      </Page>
    </Document>
  );
};
export default Order;
