export const CAD = {
    nom: {
      1: 'канадський долар',
      2: 'канадських долари',
      3: 'канадських долари',
      4: 'канадських долари',
      others: 'канадських доларів',
    },
    lit: {
      1: 'канадський цент',
      2: 'канадських центи',
      3: 'канадських центи',
      4: 'канадських центи',
      others: 'канадських центів',
    },
    lessThen10: {
      man: {
        1: 'один',
        2: 'два',
        3: 'три',
        4: 'чотири',
        5: "п'ять",
        6: 'шість',
        7: 'сім',
        8: 'вісім',
        9: 'девять',
      },
      woman: {
        1: 'одна',
        2: 'дві',
        3: 'три',
        4: 'чотири',
        5: "п'ять",
        6: 'шість',
        7: 'сім',
        8: 'вісім',
        9: 'девять',
      },
    },
    from10to19: {
      10: 'десять',
      11: 'одинадцять',
      12: 'дванадцять',
      13: 'тринадцять',
      14: 'чотирнадцять',
      15: "п'ятнадцять",
      16: 'шістнадцять',
      17: 'сімнадцять',
      18: 'вісімнадцять',
      19: "дев'ятнадцять",
    },
    from20to90: {
      20: 'двадцять',
      30: 'тридцять',
      40: 'сорок',
      50: "п'ятдесят",
      60: 'шістдесят',
      70: 'сімдесят',
      80: 'вісімдесят',
      90: "дев'яносто",
    },
    from100to1000: {
      100: 'сто',
      200: 'двісті',
      300: 'триста',
      400: 'чотириста',
      500: "п'ятсот",
      600: 'шістсот',
      700: 'сімсот',
      800: 'вісімсот',
      900: "дев'ятсот",
    },
    from1000to1000000: {
      1000: 'тисяча',
      2000: 'тисячі',
      3000: 'тисячі',
      4000: 'тисячі',
      others: 'тисяч',
    },
    1000000: {
      1: 'мільйон',
      2: 'мільйона',
      3: 'мільйона',
      4: 'мільйона',
      others: 'мільйонів',
    },
  };