import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SyncOutlined, CopyOutlined, FileAddOutlined } from '@ant-design/icons';

// plugins
import { connect } from '~/plugins/redux';
//ui
import { Button } from 'ui';
const style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
// Model data
const getStateFromModel = ({ main: { loading }, users: { userData } }) => ({
  userData,
  loading,
});
const getMethodsFromModel = ({
  main: {
    setState,
    getProgramNames,
    getPaymentMethods,
    getPaymentLocations,
    getRecipient,
  },
}) => ({
  setState,
  getProgramNames,
  getPaymentMethods,
  getPaymentLocations,
  getRecipient,
});

export const Surcharge = connect(
  getStateFromModel,
  getMethodsFromModel
)(
  ({
    order = {},
    loading = false,
    setState = () => {},
    getProgramNames = () => {},
    getPaymentMethods = () => {},
    getPaymentLocations = () => {},
    getRecipient = () => {},
    ...props
  }) => {
    const history = useHistory();
    const {
      order_id,
      programType,
      programName,
      payment,
      location,
      educationLocation,
      link_AMO_CRM = '',
      abroad_education = {},
      ukraine_education = {},
      client = {},
      program_start_date = '',
      program_end_date = '',
      special_wishes = '',
    } = order;
    const [dataLoading, setDataLoading] = useState(false);

    const handleSurcharge = async () => {
      setDataLoading(true);
      try {
        await getProgramNames(programType || '');
        await getPaymentMethods({
          program_type: programType || '',
          nomenclature_1s: programName || '',
        });
        await getPaymentLocations({
          program_type: programType || '',
          nomenclature_1s: programName || '',
          payment_type: payment || '',
        });
        await getRecipient({
          program_type: programType || '',
          nomenclature_1s: programName || '',
          payment_type: payment || '',
          location: location?.title || location?.name || location || '',
        });
        setState((state) => ({
          selectedValues: {
            ...state.selectedValues,
            programType,
            programName,
            paymant: payment,
            location: location?.title || location?.name || location || '',
            educationLocation,
            abroad_education,
            link_AMO_CRM,
            program_start_date,
            program_end_date,
            special_wishes,
          },
          abroad_education,
          ukraine_education,
          client,
          main_id: order_id,
        }));
        setState({ orderCopy: true });
        setState({ step: 1 });
      } catch (error) {}
      setDataLoading(false);
      history.push('/admin/createOrder');
    };
    return (
      <>
        <Button
          style={style}
          onClick={() => {
            handleSurcharge();
          }}>
          Доплата
          {dataLoading ? <SyncOutlined spin /> : ''}
        </Button>
      </>
    );
  }
);
