import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// Utils
import { pathChecks } from 'utils';

// UI
import { Flex, Spin } from 'ui';

// Container
import { SideBar } from '../SideBar';
import { Header } from '../Header';

// Plugins
import { initFingerPrint } from 'plugins/fingerprint';

// Styled
import { App, Main, MainWrapper } from './styles';

const getDataFromStore = ({ _app: { sideMenuWidth } }) => ({ sideMenuWidth });
const getMethodsFromStore = ({
  _app: { setState },
  users: { getProfileInfo, getProfileFromStorage, getRoles },
}) => ({
  getRoles,
  getProfileFromStorage,
  setState,
  getProfileInfo,
});

export const AppWrapper = connect(
  getDataFromStore,
  getMethodsFromStore
)(
  ({
    sideMenuWidth = 0,
    getProfileInfo = () => {},
    getProfileFromStorage = () => {},
    getRoles = () => {},
    setState = () => {},
    ...props
  }) => {
    const [loading, setLoading] = useState(false);
    const mainWidth = sideMenuWidth ? `calc(100% - ${sideMenuWidth})` : '100%';

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
      setState({ history });
    }, [history, setState]);

    useEffect(() => {
      async function mountActions() {
        setLoading(true);
        const data = (await getProfileFromStorage()) || {};
        const { token } = data;

        if (token) {
          const inAdminPage = pathChecks.isAdminPath(location);
          if (!inAdminPage) history.push('/admin');

          await getProfileInfo({});
          await getRoles({});
        }

        setLoading(false);
      }

      mountActions();
      initFingerPrint();
    }, [getProfileInfo, getRoles, getProfileFromStorage]);

    return (
      <App>
        {loading ? (
          <Flex margin="100px auto">
            <Spin size="large" />
          </Flex>
        ) : (
          <>
            <Header />

            <MainWrapper>
              <SideBar />
              <Main style={{ width: mainWidth }}>{props.children}</Main>
            </MainWrapper>
          </>
        )}
      </App>
    );
  }
);
