// Libraries
import React, { useState } from 'react';
// Styles
import {
  Button,
  ButtonPrivat,
  CurrencyName,
  Input,
  Table,
  Td,
  Th,
  Title,
  Tr,
  Wrap,
  WrapBorder,
} from './style';

// Components
import { notification } from 'antd';
import Loader from '../../ui/Loader';
import { ManualCurrency } from './manual-currency';
// Providers
import { FormProvider, useForm } from 'react-hook-form';

// API
import { requests } from '../main/controllers';

export const PageCurrency = () => {
  const [currencies, setCurrencies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCanEdit, setIsCanEdit] = useState(false);

  // Gets the current exchange rate
  React.useEffect(() => {
    (async () => {
      const accessValue = await requests.getEditAccessValue();
      setIsCanEdit(accessValue);
      setCurrencies(await requests.getCurrencies());
    })();
  }, []);

  // Updates the exchange rate in the private bank
  const UpdateCurrencyPrivat = async () => {
    setIsLoading(true);
    let body = {
      EUR: { buy: 25, sell: 25 },
      USD: { buy: 25, sell: 25 },
      canChanged: true,
    };
    await requests.setCurrencies(body);
    await requests.postCurrencies();
    let response = await requests.getCurrencies();
    let finalBody = {};
    response.forEach((currency) => {
      const { ccy = 'EUR', buy = '', sell = '' } = currency || {};
      finalBody[`${ccy}_sell`] = sell;
      finalBody[`${ccy}_buy`] = buy;
    });
    reset(finalBody);
    setIsLoading(false);
    if (response?.error) {
      notification.error({
        message: 'Помилка оновлення курсу',
        description: response?.message,
        duration: 5,
      });
    } else {
      notification.success({
        message: 'Курс успішно оновленно з Приват Банку',
        duration: 5,
      });
    }
  };

  const form = useForm();
  const { register, handleSubmit, reset } = form;

  const onSubmit = async (body) => {
    setIsLoading(true);
    const keysArray = Object.keys(body);
    let final = [];
    keysArray.forEach((key, index) => {
      const [ccy] = key.split('_');
      if (index % 2 === 0) {
        final.push({ ccy, buy: +body[key] });
      } else {
        final[Math.floor(index / 2)] = {
          ...final[Math.floor(index / 2)],
          sell: +body[key],
        };
      }
    });
    let requestBody = {};
    requestBody = { canChanged: false };
    final.forEach((currency, index) => {
      const { ccy } = currency;
      requestBody[ccy] = {
        buy: +final[index]['buy'],
        sell: +final[index]['sell'],
      };
    });
    const response = await requests.setCurrencies(requestBody);
    setIsLoading(false);
    if (response?.error) {
      notification.error({
        message: 'Помилка зміни курсу',
        description: response?.message,
        duration: 5,
      });
    } else {
      notification.success({
        message: 'Курс успішно змінено',
        duration: 5,
      });
    }
  };

  return (
    <Wrap>
      <Title>Курси валют</Title>
      <WrapBorder>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Table>
              <thead>
                <Tr>
                  <Th width="50%">У Відділеннях</Th>
                  <Th width="25%">Купівля</Th>
                  <Th width="25%">Продаж</Th>
                </Tr>
              </thead>
              <tbody>
                {currencies?.map((currency, index) => {
                  const { ccy = 'EUR', base_ccy = 'UAH', buy = '', sell = '' } =
                    currency || {};
                  return (
                    <Tr key={index}>
                      <Td width="50%">
                        <CurrencyName>{ccy}</CurrencyName>
                        <CurrencyName grayed>{base_ccy}</CurrencyName>
                      </Td>
                      <Td width="25%" textAlign="left">
                        <Input
                          step="any"
                          type="number"
                          defaultValue={parseFloat(buy).toFixed(1)}
                          name={`${ccy}_buy`}
                          ref={register}
                        />
                      </Td>
                      <Td width="25%" textAlign="left">
                        <Input
                          step="any"
                          type="number"
                          defaultValue={parseFloat(sell).toFixed(1)}
                          name={`${ccy}_sell`}
                          ref={register}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            {isCanEdit &&
              (isLoading ? (
                <Loader />
              ) : (
                <Button disabled={isLoading} type="submit">
                  Зберегти
                </Button>
              ))}
          </form>
        </FormProvider>
        {isCanEdit &&
          (isLoading ? (
            <Loader />
          ) : (
            <ButtonPrivat
              disabled={isLoading}
              type="button"
              onClick={UpdateCurrencyPrivat}>
              Оновити курс з Приватбанку
            </ButtonPrivat>
          ))}
      </WrapBorder>
      <br />
      <ManualCurrency firstCurrency={'PLZ'} secondCurrency={'EUR'} />
      <br />
      <ManualCurrency firstCurrency={'CAD'} secondCurrency={'UAH'} />
    </Wrap>
  );
};
