import React, { useEffect } from 'react';

import { connect } from 'react-redux';
// import moment from 'moment';

import { SyncOutlined } from '@ant-design/icons';
// Utils
import { useHandleFields } from 'utils';

// Components
import { InputNumber, Select, Date, RangeDate } from 'components/Fields';
import { Flex, Input, Title, Button } from '~/ui';
import { inputHeight, container } from './styles';

const Container = container(Flex);
const TextField = inputHeight(Input, '34px');
const NumberField = inputHeight(InputNumber, '32px');

const getDataFromStore = ({
  adminOrders: { search, loading },
  main: { managers },
  users: { userData },
}) => ({
  search,
  loading,
  managers,
  userData,
});
const getMethodsFromStore = ({
  adminOrders: { setState, getOrdersFromServer },
  main: { getManagers },
}) => ({
  setState,
  getOrdersFromServer,
  getManagers,
});

export const Search = connect(
  getDataFromStore,
  getMethodsFromStore
)(
  ({
    search = {},
    loading = false,
    managers = [],
    userData = {},
    setState = () => {},
    getOrdersFromServer = () => {},
    getManagers = () => {},
  }) => {
    const { _id = '', role = '' } = userData;
    const {
      manager_id = '',
      student_name = '',
      payer_name = '',
      contact_phone = '',
      email = '',
      program_name = '',
      order_id = '',
      // create_date = '',
      create_date_range = '',
      paid = '',
      delete1C = '',
    } = search;
    const handleFields = useHandleFields(setState);
    const handleSubmit = (e) => {
      e.preventDefault();
      setState({
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0,
        },
        progressShow: false,
      });
      getOrdersFromServer();
    };

    useEffect(() => {
      getManagers();
      setState({
        search: {
          manager_id: role === 'admin' ? manager_id || 'showAll' : manager_id || _id,
        },
      });
      getOrdersFromServer();
    }, [getManagers]);

    let managerName = '';
    const _saleManagersOptions = managers.map((saleManager = {}) => {
      const { name = '', id } = saleManager;
      if (id === _id) managerName = name;
      return { label: name, value: id, key: id };
    });
    _saleManagersOptions.unshift({
      label: 'Показати усіх',
      value: 'showAll',
      key: '',
    });
    const saleManagerDisabled = !_saleManagersOptions.length;

    let payedSelect = [
      { paid: 'payedAll', id: 1, text: 'Сплачено повністю' },
      { paid: 'payedPart', id: 2, text: 'Сплачено частково ' },
      { paid: 'unpaid', id: 3, text: 'Неоплачені' },
    ];
    const payedOptions = payedSelect.map((option = {}) => {
      const { paid, id, text } = option;
      return { label: text, value: paid, key: id };
    });
    payedOptions.unshift({
      label: 'Показати усі',
      value: '',
      key: '',
    });

    let deletedSelect = [
      { delete1C: false, id: 2, text: 'Активні ' },
      { delete1C: true, id: 1, text: 'Видалені' },
    ];
    const deletedOptions = deletedSelect.map((option = {}) => {
      const { delete1C, id, text } = option;
      return { label: text, value: delete1C, key: id };
    });
    deletedOptions.unshift({
      label: 'Показати усі',
      value: '',
      key: '',
    });

    return (
      <Container direction="column">
        <Title style={{ lineHeight: '22px' }}>
          {loading ? <SyncOutlined spin /> : 'Пошук:'}
        </Title>
        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Flex justify={'flex-start'} wrap="wrap" width={'100%'}>
            <div style={{ width: 'max-content', flexShring: '0' }}>
              <Select
                showSearch
                searchby={'label'}
                bordered={false}
                style={{
                  minWidth: '180px',
                  border: '1px solid #d9d9d9',
                }}
                addonBefore="Менеджер:"
                options={_saleManagersOptions}
                placeholder={'не вибрано'}
                value={manager_id || managerName}
                disabled={saleManagerDisabled}
                //// пошук по label позамовчуваню шукає по value
                optionFilterProp="label"
                onChange={(e) => {
                  handleFields('search.manager_id')(e);
                  setState({
                    pagination: {
                      current: 1,
                      pageSize: 10,
                      total: 0,
                    },
                  });
                  getOrdersFromServer();
                }}
              />
            </div>
            <div style={{ width: 'max-content', flexShring: '0' }}>
              <Select
                showSearch
                searchby={'label'}
                bordered={false}
                style={{
                  minWidth: '180px',
                  border: '1px solid #d9d9d9',
                }}
                addonBefore="Сплачено:"
                options={payedOptions}
                placeholder={'не вибрано'}
                value={paid}
                onChange={(e) => {
                  handleFields('search.paid')(e);
                  setState({
                    pagination: {
                      current: 1,
                      pageSize: 10,
                      total: 0,
                    },
                  });
                  getOrdersFromServer();
                }}
              />
            </div>
            <div style={{ width: 'max-content', flexShring: '0' }}>
              <Select
                showSearch
                searchby={'label'}
                bordered={false}
                style={{
                  minWidth: '180px',
                  border: '1px solid #d9d9d9',
                }}
                addonBefore="Видалено:"
                options={deletedOptions}
                placeholder={'не вибрано'}
                value={delete1C}
                onChange={(e) => {
                  handleFields('search.delete1C')(e);
                  setState({
                    pagination: {
                      current: 1,
                      pageSize: 10,
                      total: 0,
                    },
                  });
                  getOrdersFromServer();
                }}
              />
            </div>
            <div style={{ width: '240px', flexShring: '0' }}>
              <NumberField
                bordered={false}
                value={order_id}
                addonBefore="Номер замовлення"
                onChange={handleFields('search.order_id')}
              />
            </div>
            <div style={{ width: '270px', flexShring: '0' }}>
              <TextField
                value={payer_name}
                addonBefore="ПІБ клієнта"
                onChange={handleFields('search.payer_name')}
              />
            </div>
            <div style={{ width: '270px', flexShring: '0' }}>
              <TextField
                value={student_name}
                addonBefore="ПІБ студента"
                onChange={handleFields('search.student_name')}
              />
            </div>
            <div style={{ width: '210px', flexShring: '0' }}>
              <TextField
                value={contact_phone}
                addonBefore="Телефон"
                onChange={handleFields('search.contact_phone')}
              />
            </div>
            <div style={{ width: '200px', flexShring: '0' }}>
              <TextField
                value={email}
                addonBefore="Email"
                onChange={handleFields('search.email')}
              />
            </div>
            <div style={{ width: '300px', flexShring: '0' }}>
              <TextField
                value={program_name}
                addonBefore="Назва програми"
                onChange={handleFields('search.program_name')}
              />
            </div>
            {/* <div>
              <Date
                addonBefore="Дата створення s"
                onChange={handleFields('search.create_date')}
                format={'DD-MM-YYYY'}
                defaultValue={create_date ? moment(create_date, 'DD-MM-YYYY') : ''}
              />
            </div> */}
            <div>
              <RangeDate
                addonBefore="Дата створення"
                onChange={handleFields('search.create_date_range')}
                // onChange={(e) => console.log('rangeDate', e)}
                format={'DD-MM-YYYY'}
                defaultValue={create_date_range}
              />
            </div>
            <Button
              disabled={loading}
              className="btn btn-lg btn-primary"
              htmlType="submit">
              Пошук
            </Button>
          </Flex>
        </form>
      </Container>
    );
  }
);
