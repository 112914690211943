import React from 'react';
import styled from 'styled-components';

// Styles
const Flex = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || 'space-around'};
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'center'};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
  width: ${({ width = 'inherit' }) => width};

  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
`;

// Component
export default ({ ...props }) => {
  return <Flex {...props}>{props.children}</Flex>;
};
