import React from 'react';

export default ({ Actions, AddUserButton }) => [
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
    ellipsis: true,
  },
  {
    title: 'Заблокований',
    dataIndex: 'blocked',
    key: 'blocked',
    ellipsis: true,
    render: (data, record) => (record?.blocked ? 'Так' : 'Ні'),
  },
  {
    title: "Ім'я",
    dataIndex: 'firstName',
    key: 'firstName',
    ellipsis: true,
  },
  {
    title: 'Прізвище',
    dataIndex: 'lastName',
    key: 'lastName',
    ellipsis: true,
  },
  {
    title: 'Пошта',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
  },
  {
    title: 'Пароль',
    dataIndex: 'password',
    key: 'password',
    ellipsis: true,
    width: 70,
    render: () => '***',
  },
  {
    title: 'Роль',
    dataIndex: 'role',
    key: 'role',
    sorter: (a = {}, b = {}) => a.role > b.role,
    ellipsis: true,
    width: 70,
  },
  // {
  //   title: 'Дата створення',
  //   dataIndex: 'createDate',
  //   key: 'createDate',
  //   sorter: (a = {}, b = {}) => new Date(a.createDate || 0) > new Date(b.createDate || 0),
  //   ellipsis: true,
  //   render: (date) => date,
  // },
  {
    dataIndex: 'actions',
    key: 'actions',
    width: 190,
    render: (data, record) => <Actions record={record} />,
    title: () => <AddUserButton />,
  },
];
