import capitalize from '../utils/capitalize';
import { CAD } from './dictionary/CAD';
import { EUR } from './dictionary/EUR';
import { UAH } from './dictionary/UAH';
import { USD } from './dictionary/USD';
const words = {
  EUR,
  UAH,
  USD,
  CAD,
};

/**
 * Example to use:
 * new TransformNumberInWords(222222222.22, "UAH").transform();
 */
export class TransformNumberInWords {
  constructor(number, currency, separator = '.') {
    this.number = number;
    this.currency = currency || 'UAH';
    this.separator = separator;
    this.resultArray = [];
  }

  separatePartsOfNumber(number, stringNumber) {
    // скільки мільйонів у числі
    const whichMillions = Math.floor(number / 1000000);
    const extractMillionsInThousands =
      whichMillions > 0 ? +stringNumber.slice((whichMillions + '').length) : number;

    // скільки тисяч у числі
    const whichThousands = Math.floor(extractMillionsInThousands / 1000);
    const extractThousandsInHundreds =
      whichThousands > 0 ? +stringNumber.slice(-3) : number;

    // скільки сотень у числі
    const whichHundreds = Math.floor(extractThousandsInHundreds / 100);
    const extractHundredsInTens = +stringNumber.slice(-2);
    // скільки десятків у числі
    const whichTens = Math.floor(extractHundredsInTens / 10);
    // для перевірки на 10-19
    const whichTensOst = +stringNumber.slice(-2);
    // скільки залишок у числі
    const whichSingle = +stringNumber.slice(-1);

    return [
      whichMillions,
      whichThousands,
      whichHundreds,
      whichTens,
      whichTensOst,
      whichSingle,
    ];
  }

  setGenderByCurrency(currencies) {
    return currencies[this.currency] || 'man';
  }

  transformMillions(whichMillions) {
    if (whichMillions > 0) {
      const firstNum = whichMillions;
      const stringNum = firstNum + '';

      let count = '';
      let sign =
        words[this.currency][1000000][firstNum] || words[this.currency][1000000].others;

      // якщо кількість мільйонів менше 10
      if (firstNum < 10) {
        count =
          words[this.currency].lessThen10[
            this.setGenderByCurrency({
              UAH: 'man',
              EUR: 'man',
              USD: 'man',
              CAD: 'man',
            })
          ][firstNum];
      }
      // якщо кількість мільйонів від 10 до 19
      if (firstNum > 9 && firstNum < 20) {
        count = words[this.currency].from10to19[firstNum];
      }
      // якщо кількість мільйонів менше 100 і більше 19
      // ділим кількість на десятки і залишок
      // в залежності від залишку пишем відмінок валюти
      if (firstNum > 19 && firstNum < 100) {
        const [tens, single] = stringNum.split('');

        const tensInWords = words[this.currency].from20to90[Number(tens) * 10];
        const singInWords =
          words[this.currency].lessThen10[
            this.setGenderByCurrency({
              UAH: 'man',
              EUR: 'man',
              USD: 'man',
              CAD: 'man',
            })
          ][Number(single)];

        count = `${tensInWords} ${singInWords}`;
        sign =
          words[this.currency][1000000][Number(single)] ||
          words[this.currency][1000000].others;
      }
      // якщо кількість мільйонів менше 1000 і більше 99
      // ділим кількість на сотні, десятки і залишок
      // в залежності від залишку пишем відмінок валюти
      if (firstNum > 99 && firstNum < 1000) {
        const [thousands, tens, single] = stringNum.split('');

        const thousandsInWords =
          words[this.currency].from100to1000[Number(thousands) * 100];
        const tensInWords = words[this.currency].from20to90[Number(tens) * 10];
        const singInWords =
          words[this.currency].lessThen10[
            this.setGenderByCurrency({
              UAH: 'man',
              EUR: 'man',
              USD: 'man',
              CAD: 'man',
            })
          ][Number(single)];

        count = `${thousandsInWords} ${tensInWords} ${singInWords}`;
        sign =
          words[this.currency][1000000][Number(single)] ||
          words[this.currency][1000000].others;
      }

      this.resultArray.push(`${count} ${sign}`);
    }
  }

  transformThousands(whichThousands, last3Digit) {
    if (whichThousands > 0) {
      const firstNum = whichThousands;
      const stringNum = firstNum + '';
      let count =
        stringNum.length < 2
          ? words[this.currency].lessThen10[
              this.setGenderByCurrency({
                UAH: 'woman',
                EUR: 'woman',
                USD: 'woman',
                CAD: 'woman',
              })
            ][firstNum]
          : words[this.currency].from10to19[firstNum];
      let sign =
        words[this.currency]['from1000to1000000'][firstNum] ||
        words[this.currency]['from1000to1000000'].others;

      if (firstNum < 10) {
        count =
          words[this.currency].lessThen10[
            this.setGenderByCurrency({
              UAH: 'woman',
              EUR: 'woman',
              USD: 'woman',
              CAD: 'woman',
            })
          ][firstNum];

        sign =
          words[this.currency]['from1000to1000000'][Number(firstNum) * 1000] ||
          words[this.currency]['from1000to1000000'].others;
      }
      // якщо кількість тисяч більше 9 і менше 20
      if (firstNum > 9 && firstNum < 20) {
        count = words[this.currency].from10to19[firstNum];
      }
      // якщо кількість тисяч менше 100 і більше 19
      // ділим кількість на десятки і залишок
      // в залежності від залишку пишем відмінок валюти
      if (firstNum > 19 && firstNum < 100) {
        const [tens, single] = stringNum.split('');

        const tensInWords = words[this.currency].from20to90[Number(tens) * 10];
        const singInWords =
          words[this.currency].lessThen10[
            this.setGenderByCurrency({
              UAH: 'woman',
              EUR: 'woman',
              USD: 'woman',
              CAD: 'woman',
            })
          ][Number(single)];

        if (tensInWords) count = `${tensInWords}`;
        if (singInWords) count += ` ${singInWords}`;

        sign =
          words[this.currency]['from1000to1000000'][Number(single) * 1000] ||
          words[this.currency]['from1000to1000000'].others;
      }
      // якщо кількість тисяч менше 1000 і більше 99
      // ділим кількість на сотні, десятки і залишок
      // в залежності від залишку пишем відмінок валюти
      if (firstNum > 99 && firstNum < 1000) {
        const [thousands, tens, single] = stringNum.split('');

        const lastTwoDigits = Number(tens + single); // Отримуємо останні дві цифри
        const thousandsInWords =
          words[this.currency].from100to1000[Number(thousands) * 100];
        const tensInWords =
          lastTwoDigits > 19
            ? words[this.currency].from20to90[Number(tens) * 10]
            : words[this.currency].from10to19[Number(lastTwoDigits)];

        const singInWords =
          (tens < 1 || tens > 1) &&
          words[this.currency].lessThen10[
            this.setGenderByCurrency({
              UAH: 'woman',
              EUR: 'woman',
              USD: 'woman',
              CAD: 'woman',
            })
          ][Number(single)];

        if (thousandsInWords) count = thousandsInWords;
        if (tensInWords) count += ` ${tensInWords}`;
        if (singInWords) count += ` ${singInWords}`;
        sign =
          words[this.currency]['from1000to1000000'][lastTwoDigits * 1000] ||
          words[this.currency]['from1000to1000000'].others;
      }

      if (last3Digit === '000') {
        sign = `${sign} ${words[this.currency].nom.others}`;
      }
      this.resultArray.push(`${count} ${sign}`);
    }
  }

  transformHundreds(whichHundreds, whichTens, last2Digit) {
    if (whichHundreds > 0) {
      const firstNum = whichHundreds * 100;
      let sign =
        words[this.currency]['from100to1000'][firstNum] ||
        words[this.currency]['from100to1000'].others;

      if (whichTens === 0 && last2Digit === '00') {
        sign = `${sign} ${words[this.currency].nom.others}`;
      }

      this.resultArray.push(sign);
    }
  }

  transformTens(whichTens, whichTensOst, whichSingle) {
    if (whichTens > 0) {
      const firstNum = whichTens * 10;

      let sign =
        words[this.currency]['from20to90'][firstNum] ||
        words[this.currency]['from20to90'].others;

      if (whichTensOst > 9 && whichTensOst < 20) {
        sign =
          words[this.currency]['from10to19'][whichTensOst] ||
          words[this.currency]['from10to19'].others;
      }

      if (whichSingle === 0 || (whichTensOst > 9 && whichTensOst < 20)) {
        sign = `${sign} ${
          words[this.currency].nom[whichTensOst || whichTens] ||
          words[this.currency].nom.others
        }`;
      }

      if (sign) this.resultArray.push(sign);
    }
  }

  transformSingle(whichSingle, whichTensOst) {
    if (whichSingle > 0) {
      const firstNum = whichSingle;
      const count =
        words[this.currency]['lessThen10'][
          this.setGenderByCurrency({
            UAH: 'woman',
            EUR: 'man',
            USD: 'man',
            CAD: 'man',
          })
        ][firstNum];
      const sign = words[this.currency].nom[firstNum] || words[this.currency].nom.others;
      let resultSingle = `${sign}`;

      if (whichTensOst > 19 || whichTensOst < 10) {
        resultSingle = `${count} ` + resultSingle;

        this.resultArray.push(resultSingle);
      }
    }
  }

  transformCentsTens(_tens) {
    if (_tens && _tens.length > 0) {
      const firstNum = Number(_tens) * 10;
      const count = words[this.currency]['from20to90'][firstNum];

      this.resultArray.push(count);
    }
  }

  transformCentsSingle(_single) {
    if (_single && _single.length > 0) {
      const firstNum = Number(_single);
      const count =
        words[this.currency].lessThen10[
          this.setGenderByCurrency({
            UAH: 'man',
            EUR: 'man',
            USD: 'man',
            CAD: 'man',
          })
        ][firstNum];
      const sign = words[this.currency].lit[firstNum] || words[this.currency].lit.others;

      this.resultArray.push(`${count} ${sign}`);
    }
  }

  transformCentsPure(count) {
    if (count) {
      const [, second] = count.split('');

      const is11to19 = count > 9 && count < 20;

      const sign = is11to19
        ? words[this.currency].lit.others
        : words[this.currency].lit[Number(second)] || words[this.currency].lit.others;
      const fullNum = count.length === 1 ? (count + '0').slice(0, 2) : count;

      this.resultArray.push(`${fullNum} ${sign}`);
    }
  }

  transform() {
    const [big, lit] = (this.number + '').split(this.separator);
    const last2Digit = big.slice(-2);
    const last3Digit = big.slice(-3);
    const number = Number(big);
    const [
      whichMillions,
      whichThousands,
      whichHundreds,
      whichTens,
      whichTensOst,
      whichSingle,
    ] = this.separatePartsOfNumber(number, big);

    this.transformMillions(whichMillions);
    this.transformThousands(whichThousands, last3Digit);
    this.transformHundreds(whichHundreds, whichTens, last2Digit);
    this.transformTens(whichTens, whichTensOst, whichSingle);
    this.transformSingle(whichSingle, whichTensOst);

    // Cents!
    const [, _single = '00'] = (lit || '').split('');
    // this.transformCentsTens(_tens);
    // this.transformCentsSingle(_single);
    this.transformCentsPure(!lit && _single === '00' ? _single : lit);
    return capitalize(
      this.resultArray
        .filter((x) => x)
        .join(' ')
        .replace('undefined', '')
        .replace(/ {2}/g, ' ')
    );
  }
}
