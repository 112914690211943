export default {
  state: {
    step: 1,
    formdata: {},
  },
  reducers: {
    changeFormStep: (state, step) => ({
      ...state,
      step,
    }),
    changeFormData: (state, payload) => ({
      ...state,
      formdata: payload,
    }),
  },
  effects: (/* dispatch */) => ({
    async sendToServer(/* data, rootState */) {
      // rootState.testModel.formdata
    },
  }),
};
