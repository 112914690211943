// Libraries
import React from 'react';

// Utils
import { saveAs } from 'file-saver';
import { Packer } from 'docx';
import { DocumentCreator } from './cv-generator';
import { trasformData } from '../../../../utils/restructuringOrderData';
import {
  contractBetween,
  contract,
  rightsObligations,
  coastAndPayment,
  serviceProvisionProcedure,
  responsibilitiesParties,
  forceMajeure,
  settlementDisputes,
  end,
} from './cv-data';
import { GenerateButton } from '../PdfCreator/styles';
import config from '../../../../config';

export const CreateDocx = (props) => {
  const orderValues = trasformData(props?.orderValues, props?.bill);

  let url = new URL(window?.location?.origin || config.PROD_URL);
  const generate = async () => {
    let stamp = null;
    let signature = null;
    const stampUrl = new URL(
      `${url.href}api/recipients/image/stamp/${orderValues.order_stamp_link}`
    );
    const signatureUrl = new URL(
      `${url.href}api/recipients/image/signature/${orderValues.order_signature_link}`
    );
    if (props.isStampAndSignature) {
      if (orderValues.order_stamp_link)
        stamp = await fetch(stampUrl.href).then((r) => r.blob());
      if (orderValues.order_signature_link)
        signature = await fetch(signatureUrl.href).then((r) => r.blob());
      if (signature?.type === 'application/json') signature = null;
      if (stamp?.type === 'application/json') stamp = null;
    }

    const documentCreator = new DocumentCreator();
    const doc = documentCreator.create(
      [
        contractBetween,
        contract,
        rightsObligations,
        coastAndPayment,
        serviceProvisionProcedure,
        responsibilitiesParties,
        forceMajeure,
        settlementDisputes,
        end,
      ],
      orderValues,
      stamp,
      signature
    );
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `Договір№${orderValues?.order_num}.docx`);
    });
  };
  return (
    <div>
      <GenerateButton style={{ marginRight: 10, ...props.style }} onClick={generate}>
        Згенерувати
      </GenerateButton>
    </div>
  );
};
