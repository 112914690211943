import { CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InitialState from '../../controllers/initialState';
// Utils
import { useHandleFields } from 'utils';

// UI
import { Button, ButtonError, Checkbox, Flex, Loader, Title } from 'ui';

// Components
import {
  Date as DateField,
  Input,
  InputNumber,
  Label,
  SubmitButton,
  TextArea,
} from 'components/Fields';

// Containers
import Document from '../Document';

// Styled
import { container, PriceForm } from './styles';
//utils
import { isWarszawa } from 'utils/isWarszawa';
// API

const Container = container(Flex);

const getDataFromStore = ({
  main: {
    document_create,
    selectedValues,
    programInfo,
    shedule_payments_fields,
    loading,
    orderCopy,
    main_id,
  },
}) => ({
  document_create,
  selectedValues,
  programInfo,
  shedule_payments_fields,
  loading,
  orderCopy,
  main_id,
});
const getMethodsFromStore = ({
  main: {
    setState,
    changePriceWithDiscount,
    changeAmountPaybelAndConversion,
    createOrder,
  },
}) => ({
  setState,
  changePriceWithDiscount,
  changeAmountPaybelAndConversion,
  createOrder,
});

export default connect(
  getDataFromStore,
  getMethodsFromStore
)(
  ({
    loading = false,
    orderCopy = false,
    main_id = '',
    document_create = false,
    selectedValues = {},
    programInfo = {},
    shedule_payments_fields = [],
    createOrder = () => {},
    changePriceWithDiscount = () => {},
    changeAmountPaybelAndConversion = () => {},
    setState = () => {},
    ...props
  }) => {
    const {
      program_start_date = '',
      program_end_date = '',
      discount = 0,
      conversion_rate,
      conversion_rate_EUR_PLN = 0,
      price_with_discount = '',
      amount_payable = '',
      amount_payable_in_UAH = '',
      amount_payable_in_PLN = '',
      special_wishes = '',
      need_to_send_mail = false,
      attraction_channel = '',
    } = selectedValues;

    const {
      full_price = 0,
      currency = 'UAH',
      min_price = null,
      startDate = '',
      endDate = '',
    } = programInfo || {};
    const [showDocuments, setShowDocuments] = React.useState(false);
    const handleFields = useHandleFields(setState);
    const [blockClick, setBlockClick] = useState(false);
    if (min_price === null || min_price === undefined) {
      handleFields('programInfo.min_price')(full_price);
    }

    let shedulePaymentsCsum = 0;
    (shedule_payments_fields || []).forEach((_, i) => {
      if (selectedValues[`payment_schedule_date_${i}`]) {
        shedulePaymentsCsum += selectedValues[`payment_schedule_summ_${i}`] || 0;
      }
    });
    const handlePrevStepClick = () => {
      setState({ step: 2 });
    };

    const handleShowDocument = async (e) => {
      e.preventDefault();
      if (!blockClick) {
        setBlockClick(true);
        const res = await createOrder();
        setTimeout(() => {
          setBlockClick(false);
        }, 1000);
        if (res?.succes) {
          setShowDocuments(true);
          setState({ document_create: true });
        }
      }
    };
    const handlePriceWithDiscount = (val) =>
      changePriceWithDiscount({
        val,
        handleValue: handleFields('selectedValues.discount'),
        handleWithDiscount: handleFields('selectedValues.price_with_discount'),
      });
    const handleAmountPaybelAndConversion = (val) => {
      changeAmountPaybelAndConversion({
        val: val > price_with_discount ? price_with_discount : val,
        handleValue: handleFields('selectedValues.amount_payable'),
        handleConversion: handleFields('selectedValues.amount_payable_in_UAH'),
        handleConversionPNL: isWarszawa(selectedValues)
          ? handleFields('selectedValues.amount_payable_in_PLN')
          : () => {},
      });
    };

    // SHEDULER
    const handleShedulerDate = (num) => (e) => {
      if (selectedValues[`payment_schedule_date_${num}`] !== 'undefined') {
        handleFields(`selectedValues.payment_schedule_date_${num}`)(e);
      }
      const findExist = shedule_payments_fields.includes(num + 1);
      if (selectedValues[`payment_schedule_summ_${num}`] && !findExist) {
        handleFields(`shedule_payments_fields`)([...shedule_payments_fields, num + 1]);
      }
    };
    // SHEDULER
    const handleShedulerSumm = (num) => (e) => {
      if (selectedValues[`payment_schedule_summ_${num}`] !== 'undefined') {
        handleFields(`selectedValues.payment_schedule_summ_${num}`)(
          e > price_with_discount ? price_with_discount : e
        );
      }
      const findExist = shedule_payments_fields.includes(num + 1);
      if (selectedValues[`payment_schedule_date_${num}`] && !findExist) {
        handleFields(`shedule_payments_fields`)([...shedule_payments_fields, num + 1]);
      }
    };
    const disabledStartDate = (current) => {
      return current && current < moment().startOf('day');
    };
    const disabledEndDate = (current) => {
      return current && current < moment(program_start_date, 'DD-MM-YYYY').endOf('day');
    };
    useEffect(() => {
      if (startDate)
        handleFields('selectedValues.program_start_date')(
          moment(startDate, 'YYYY-MM-DD')?.format('DD-MM-YYYY') || ''
        );
      if (endDate)
        handleFields('selectedValues.program_end_date')(
          moment(endDate, 'YYYY-MM-DD')?.format('DD-MM-YYYY') || ''
        );
    }, []);
    return (
      <Container direction="column">
        {loading ? <Loader /> : ''}
        <Title>Вартість програми</Title>
        <PriceForm {...props}>
          <div className={document_create ? 'block_form_content' : ''}>
            <Label>
              <Input
                disabled={main_id}
                addonBefore={`attraction_channel`}
                onChange={handleFields('selectedValues.attraction_channel')}
                value={attraction_channel}
              />
            </Label>
            <Label required>
              <DateField
                addonBefore="Дата початку програми"
                onChange={(e) => {
                  handleFields('selectedValues.program_start_date')(e);
                  handleFields('selectedValues.program_end_date')('');
                }}
                format={'DD-MM-YYYY'}
                disabledDate={disabledStartDate}
                disabled={main_id || startDate}
                value={program_start_date ? moment(program_start_date, 'DD-MM-YYYY') : ''}
              />
            </Label>

            <Label required>
              <DateField
                addonBefore="Дата закінчення програми"
                onChange={handleFields('selectedValues.program_end_date')}
                format={'DD-MM-YYYY'}
                value={program_end_date ? moment(program_end_date, 'DD-MM-YYYY') : ''}
                disabledDate={disabledEndDate}
                disabled={main_id || !program_start_date || endDate}
              />
            </Label>

            <br />

            <Label>
              <InputNumber
                disabled={main_id}
                addonBefore={`Повна вартість програми ${currency}`}
                onChange={(e) => {
                  e < min_price
                    ? handleFields('programInfo.full_price')(min_price)
                    : handleFields('programInfo.full_price')(e);
                  e < min_price
                    ? handleFields('selectedValues.price_with_discount')(
                        min_price - discount
                      )
                    : handleFields('selectedValues.price_with_discount')(e - discount);
                }}
                value={full_price}
              />
            </Label>

            <Label>
              <InputNumber
                addonBefore={`Сума знижки ${currency}`}
                onChange={handlePriceWithDiscount}
                value={discount}
              />
            </Label>

            <Label>
              <InputNumber
                disabled
                addonBefore={`Сума по договору зі знижкою ${currency}`}
                onChange={handleFields('selectedValues.price_with_discount')}
                value={price_with_discount}
              />
            </Label>

            <Label>
              <InputNumber
                addonBefore={`Сума до сплати в рахунку в ${currency}`}
                onChange={handleAmountPaybelAndConversion}
                value={amount_payable}
              />
            </Label>

            <Label>
              {isWarszawa(selectedValues) ? (
                <InputNumber
                  disabled={main_id}
                  warning={conversion_rate_EUR_PLN === 0}
                  addonBefore={`Сума до сплати в рахунку з перерахуванням ${currency} в  PLN  по курсу ${conversion_rate_EUR_PLN}`}
                  onChange={handleFields('selectedValues.amount_payable_in_PLN')}
                  value={amount_payable_in_PLN}
                />
              ) : (
                <InputNumber
                  disabled={main_id}
                  addonBefore={`Сума до сплати в рахунку з перерахуванням ${currency} в  UAH  по курсу ${conversion_rate}`}
                  onChange={handleFields('selectedValues.amount_payable_in_UAH')}
                  value={amount_payable_in_UAH}
                />
              )}
            </Label>
            <Flex direction="column" margin="20px 0">
              <Flex>
                Графік платежів (
                <b>
                  {currency} [
                  <span
                    style={
                      shedulePaymentsCsum === 0
                        ? { color: 'inherit' }
                        : shedulePaymentsCsum === price_with_discount
                        ? { color: 'green' }
                        : shedulePaymentsCsum > price_with_discount
                        ? { color: 'red' }
                        : { color: 'orange' }
                    }>
                    {shedulePaymentsCsum}
                  </span>{' '}
                  - сумма платежів; {price_with_discount - shedulePaymentsCsum} - ще до
                  сплати]
                </b>
                ):{' '}
              </Flex>
              {(shedule_payments_fields || []).map((_, i) => {
                if (i < 13) {
                  return (
                    <Flex key={i} style={{ width: '100%' }}>
                      <DateField
                        addonBefore="Дата"
                        format={'DD-MM-YYYY'}
                        value={
                          selectedValues[`payment_schedule_date_${i}`]
                            ? moment(
                                new Date(
                                  selectedValues[`payment_schedule_date_${i}`]
                                    .split('-')
                                    .reverse()
                                    .join('-')
                                )
                              )
                            : null
                        }
                        onChange={handleShedulerDate(i)}
                      />
                      &nbsp; &nbsp;
                      <InputNumber
                        value={selectedValues[`payment_schedule_summ_${i}`]}
                        addonBefore={`Сумма`}
                        onChange={handleShedulerSumm(i)}
                      />
                    </Flex>
                  );
                } else {
                  return '';
                }
              })}
            </Flex>
            <Label margin="0 0 20px" text="Додаткова угода: " width="max-content">
              <Checkbox
                onChange={(e) => {
                  handleFields('selectedValues.need_to_send_mail')(e.target.checked);
                }}
                checked={need_to_send_mail}
              />
            </Label>
            {need_to_send_mail ? (
              <Label margin="0 0 20px">
                <TextArea
                  addonBefore="Особливі побажання клієнта:"
                  value={special_wishes}
                  onChange={handleFields('selectedValues.special_wishes')}
                />
              </Label>
            ) : (
              ''
            )}
            <Flex>
              <SubmitButton
                disabled={
                  blockClick || !program_start_date || !program_end_date ? true : false
                }
                text="Створити документ"
                onClick={handleShowDocument}
              />
              <Button type="primary" onClick={handlePrevStepClick}>
                Назад
              </Button>
              {!(showDocuments || document_create) && orderCopy ? (
                <ButtonError
                  type="error"
                  onClick={() => {
                    setState(InitialState);
                  }}>
                  <CloseCircleOutlined />
                  Скинути
                </ButtonError>
              ) : (
                ''
              )}
            </Flex>
          </div>
          {/* [showDocuments, setShowDocuments] */}
          {(showDocuments || document_create) && <Document />}
        </PriceForm>
      </Container>
    );
  }
);
