import React, { useMemo, useEffect, useRef } from 'react';

// Containers
import { OrdersTable } from '../containers/OrdersTable';
import { WorkbookExcel } from '../containers/Workbook';
import { Update1C } from '../containers/Update1C/index';
import { Search } from '../containers/Search';
import { Flex, Button } from '~/ui';
// plugins
import { connect } from '~/plugins/redux';

// Utils
import { transformOrders } from '../services/utils';
import { transformOrdersExcel } from '../services/utilsForExcel';
import { Spin } from 'antd';

// Model data
const getStateFromModel = ({
  adminOrders: { orders, ordersForExcel, loading, pagination, allOrders },
  users: { userData },
}) => ({
  orders,
  ordersForExcel,
  loading,
  userData,
  pagination,
  allOrders,
});
const getMethodsFromModel = ({
  adminOrders: { getOrdersFromServer, setState, getOrdersForExcel },
}) => ({
  setState,
  getOrdersFromServer,
  getOrdersForExcel,
});

export const DownloadOrders = connect(
  getStateFromModel,
  getMethodsFromModel
)(
  // props
  React.memo(
    ({
      pagination = {},
      loading = false,
      allOrders = false,
      orders = [],
      ordersForExcel = [],
      userData = {},
      setState = () => {},
      getOrdersFromServer = () => {},
      getOrdersForExcel = () => {},
    }) => {
      const [ordersKeys, sortedOrders] = useMemo(() => transformOrders(orders), [orders]);
      const [ordersKeysExcel, sortedOrdersExcel] = useMemo(
        () => transformOrdersExcel(ordersForExcel),
        [ordersForExcel]
      );
      const { role = '' } = userData;
      useEffect(() => {
        getOrdersFromServer({});
        // eslint-disable-next-line
      }, []);
      const handlePagination = async (val) => {
        console.log('val', val)
        setState({ pagination: val, progressShow: false });
        if (!allOrders || !(orders.length > 0)) {
          getOrdersFromServer();
        }
      };

      const buttonRefExcel = useRef(null);
      const buttonDownloadExcel = async () => {
        const res = await getOrdersForExcel();
        if (res) {
          buttonRefExcel.current.click();
        }
      };

      return (
        <>
          {/* <Header /> */}
          {role === 'admin' ? (
            <Flex
              justify={'center'}
              align={'center'}
              direction={'column'}
              width={'100%'}
              style={{ padding: '0 20px' }}>
              <Update1C>
                <>
                  <WorkbookExcel
                    ordersKeys={ordersKeysExcel}
                    orders={sortedOrdersExcel}
                    refProps={buttonRefExcel}
                  />
                  <Button
                    onClick={buttonDownloadExcel}
                    style={{ marginTop: '0px', marginBottom: '0px' }}
                    className="btn btn-lg btn-primary">
                    {loading ? <Spin /> : 'Завантажити Excel'}
                  </Button>
                </>
              </Update1C>
            </Flex>
          ) : (
            ''
          )}
          <br />
          <br />
          <Search />
          <OrdersTable
            handlePagination={handlePagination}
            pagination={pagination}
            loading={loading}
            role={role}
            ordersKeys={ordersKeys}
            orders={sortedOrders}
            pureOrders={orders.map((o) => ({ ...o }))}
          />
        </>
      );
    }
  )
);
