export const components = ({ handleFields = () => {} }) => [
  {
    type: 'input',
    value: 'country',
    behaviours: { onChange: handleFields('country') },
    defaultValue: 'country',
    placeholder: 'Країна',
  },
  
];
