export const programName = [
  'Академічна англійська Academic English',
  'Академічна англійська Academic English Печерськ',
  'Академічна англійська Academic English ONLINE',
  'Вечірня англійська Evening English (1 модуль (8 тижнів)',
  'Вечірня англійська Evening English (10 модулей (80 нед)',
  'Вечірня англійська Evening English (11 модулей (88 нед)',
  'Вечірня англійська Evening English (2 модуля (16 тижнів)',
  'Вечірня англійська Evening English (3 модуля (24 тижні)',
  'Вечірня англійська Evening English (4 модуля (32 тижні)',
  'Вечірня англійська Evening English (5 модулей (40 нед)',
  'Вечірня англійська Evening English (6 модулей (48 нед)',
  'Вечірня англійська Evening English (7 модулей (56 нед)',
  'Вечірня англійська Evening English (8 модулей (64 нед)',
  'Вечірня англійська Evening English (9 модулей (72 нед)',
  'Індивідуальна англійська Individual English - підготовка до IELTS',
  'Індивідуальна англійська Individual English - підготовка до IELTS Печерськ з Укр викл',
  'Індивідуальна англійська Individual English з носієм мови',
  'Індивідуальна англійська Individual English з носієм мови в Академії Печерськ',
  'Індивідуальна англійська Individual English з Українським викладачем',
  'Індивідуальна англійська Individual English з Українським викладачем в Академії Печерськ',
  'Smart Teens Печерськ',
  'Індивідуальна англійська Individual English International School ONLINE - підготовка до IELTS',
  'Індивідуальна англійська Individual English International School ONLINE з Українським викладачем',
  'Індивідуальна англійська Individual English ONLINE - підготовка до IELTS',
  'Індивідуальна англійська Individual English ONLINE з носієм мови',
  'Індивідуальна англійська Individual English ONLINE з Українським викладачем',
  'Курс англійської English Challenge',
  'Підготовка до екзамену Cambridge',
  'Підготовка до ЗНО',
  'Підготовка до ЗНО - ONLINE',
  'Підготовка до ЗНО Печерськ',
  'Пробне заняття',
  'Ранкова англійська Morning English (1 модуль (8 тижнів)',
  'Ранкова англійська Morning English (10 модулей (80 нед)',
  'Ранкова англійська Morning English (11 модулей (88 нед)',
  'Ранкова англійська Morning English (2 модуля (16 тижнів)',
  'Ранкова англійська Morning English (3 модуля (24 тижні)',
  'Ранкова англійська Morning English (4 модуля (32 тижні)',
  'Ранкова англійська Morning English (5 модулей (40 нед)',
  'Ранкова англійська Morning English (6 модулей (48 нед)',
  'Ранкова англійська Morning English (7 модулей (56 нед)',
  'Ранкова англійська Morning English (8 модулей (64 нед)',
  'Ранкова англійська Morning English (9 модулей (72 нед)',
  'Smart Teens - 2-ий семестр',
  'Smart Teens (80 хв./2 рази на тиждень)',
  'Smart Teens (80 хв./3 рази на тиждень)',
  'Smart Teens Печерськ - 2-ий семестр',
  'Smart Teens Печерськ (80 хв./2 рази на тиждень)',
  'Smart Teens Печерськ (80 хв./3 рази на тиждень)',
  'Smart Teens ONLINE',
  'Smart Teens ONLINE - 2-ий семестр',
  'Smart Teens ONLINE (60 хв./2 рази на тиждень)',
  'Star Kids (60 хв./2 рази на тиждень)',
  'Star Kids (60 хв./3 рази на тиждень)',
  'Star Kids (60 хв./3 рази на тиждень) - 2-ий семестр',
  'Star Kids (80 хв./2 рази на тиждень)',
  'Star Kids (80 хв./3 рази на тиждень)',
  'Star Kids (80 хв./3 рази на тиждень) - 2-ий семестр',
  'Star Kids Печерськ (60 хв./2 рази на тиждень)',
  'Star Kids Печерськ (60 хв./3 рази на тиждень)',
  'Star Kids Печерськ (60 хв./3 рази на тиждень) - 2-ий семестр',
  'Star Kids Печерськ (80 хв./2 рази на тиждень)',
  'Star Kids Печерськ (80 хв./3 рази на тиждень)',
  'Star Kids Печерськ (80 хв./3 рази на тиждень) - 2-ий семестр',
  'Star Kids ONLINE - 2-ий семестр',
  'Star Kids ONLINE (60 хв./2 рази на тиждень)',
  'Star Kids ONLINE (60 хв./3 рази на тиждень)',
  'Summer Time program',
];
