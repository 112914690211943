import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 5px;
  text-align: center;
  & > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
  }
  &.green {
    & > span > span {
      color: green;
    }
  }
`;
export default ({
  sumOrders = 0,
  programPrice = 0,
  payedAll = 0,
  payedAllInCur = 0,
  currency,
}) => {
  const left_pay = programPrice - sumOrders;
  const color = left_pay < 0 ? 'red' : left_pay === 0 ? 'green' : 'orange';
  return (
    <Container className={left_pay === 0 ? 'green' : ''}>
      <span>
        <b>Вартість:</b>
        <span>{programPrice}</span>
      </span>
      <span>
        <b>Cума рахунків:</b> <span>{+sumOrders.toFixed(2)}</span>
      </span>
      <span>
        <b>Ще до оплати:</b>
        <span style={{ color: color }}>{+left_pay.toFixed(2)}</span>
      </span>
      <span>
        <b>{`Сплачено${currency !== 'UAH' ? '(' + currency + ')' : ''}[грн.]:`}</b>
        <span
          style={{
            color: payedAllInCur >= programPrice ? 'green' : 'orange',
          }}>
          {payedAllInCur !== payedAll && `(${+payedAllInCur.toFixed(1)})`} [
          {+payedAll.toFixed(2)}]
        </span>
      </span>
    </Container>
  );
};
