import React from 'react';
import { connect } from 'react-redux';

// UI
import { Flex } from 'ui';

// Styles
import { container, OrderForm } from './styles';

// Field Containers
import Program from './Program';
import UkrEdu from './UkrEdu';
import AbroadEdu from './AbroadEdu';
import SubmitPart from './SubmitPart';

const Container = container(Flex);

const getDataFromStore = ({ main: { selectedValues } }) => ({
  selectedValues,
});
const getMethodsFromStore = ({ main: { setState } }) => ({
  setState,
});

// selectedValues = {}, setState = () => {}, ...props
export default connect(
  getDataFromStore,
  getMethodsFromStore
)((props) => {
  return (
    <Container>
      <OrderForm {...props}>
        <Program />
        <Flex textAlign="left" direction="column" align="flex-start" padding="0 0 0 3%">
          <UkrEdu />
          <AbroadEdu />
          <SubmitPart />
        </Flex>
      </OrderForm>
    </Container>
  );
});
