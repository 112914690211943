import React from 'react';

export default ({ Actions, AddButton }) => [
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
    ellipsis: true,
    width: '100px',
  },
  {
    title: 'Код номенклатури',
    dataIndex: 'code',
    key: 'code',
    // ellipsis: true,
  },
  {
    title: 'Дата старту програми',
    dataIndex: 'startDate',
    key: 'startDate',
    // ellipsis: true,
  },
  {
    title: 'Дата кінця програми',
    dataIndex: 'finishDate',
    key: 'finishDate',
    // ellipsis: true,
  },
  {
    dataIndex: 'actions',
    key: 'actions',
    width: 130,
    render: (data, record) => <Actions record={record} />,
    title: () => <AddButton />,
  },
];
