export const contractBetween = [
  {
    contract:
      '\tДаний Договір про надання послуг (далі по тексту – «Договір»), укладено між:',
  },
];

export const contract = [
  {
    isCurrent: true,
    notes: [
      "1.1.\tВ порядку та на умовах, встановлених цим Договором, Виконавець зобов'язується надати Замовнику або Студентові, визначеному Замовником в даному Договорі, комплекс освітніх послуг, пов'язаних з вивченням іноземної мови, у тому числі з поглибленою підготовкою у межах розроблених сертифікованими фахівцями навчальних програм (надалі – «Послуги»), а Замовник зобов'язується прийняти вказані Послуги та оплатити Виконавцю їх вартість у повному обсязі. Вказані Послуги також можуть надаватися у форматі онлайн, тобто без відвідування навчального центру STUDY ACADEMY у передбачених Договором випадках.",
      "1.2.\tСтроки надання послуг протягом дії даного Договору, обсяг, вартість та програма вивчення Замовником (або Студентом) іноземної мови зазначаються у Додатку №1, графа «Відомості про комплекс організаційно-освітніх послуг», який є невід'ємною частиною даного Договору.",
      '1.3.\tВиконавець залишає за собою право на власний розсуд змінити дату початку надання Послуг, яка зазначається у Додатку №1 до даного Договору, про що повідомляє Замовника (та Студента) не пізніше ніж за 3 (три) календарних дні до планової дати початку надання Послуг, яка вказана у Додатку №1.',
    ],
  },
];

export const rightsObligations = [
  {
    isCurrent: true,
    executorUndertakes: "Виконавець зобов'язується:",
    obligationsExecutor: [
      '2.1.1.\tНадати Послуги на умовах, визначених у Додатку №1 до Договору.',
      '2.1.2.\tЗабезпечити Замовнику навчання з викладачем з вищою педагогічною або лінгвістичною освітою, компетенція яких підтверджена відповідними сертифікатами.',
      '2.1.3.\tЗабезпечити проведення занять іноземною мовою у відповідності до рівня групи. Розподіл за групами відбувається відповідно до результатів тестування/співбесіди до початку вивчення відповідного курсу.',
      '2.1.4.\tНадати Замовнику необхідні для навчання учбові матеріали відповідного рівня. Після закінчення навчального курсу Замовником надати сертифікат з інформацією про пройдений курс та рівнем володіння іноземною мовою Замовником (у разі успішного складання фінального тестування та відвідування не менше ніж 75% занять).',
      "2.1.5.\tПри виникненні обставин, що перешкоджають належному виконанню своїх зобов'язань, згідно з цим Договором, терміново письмово повідомляти про це Замовника. Повідомлення Замовника здійснюється в телефонному режимі, згідно контактів, які вказані у Розділі 9 даного Договору.",
      '2.1.6.\tНадати Замовнику Акт прийому-передачі послуг, за умови дотримання Замовником п.4.2 даного Договору.',
    ],
    executorHasRight: 'Виконавець має право:',
    executorsRights: [
      '2.2.1. визначати рівень володіння Замовником(Студентом) іноземною мовою на власний розсуд до початку надання Послуг;',
      '2.2.2. за результатом визначення рівня володіння Замовником(Студентом)  іноземною мовою, спрямувати Замовника (Студента) у навчальну групу, яка складається з осіб, які отримують послуги Виконавця на рівні з Замовником та мають рівень володіння іноземною мовою, наближений до рівня володіння іноземною мовою Замовником(Студентом);',
      '2.2.3. до початку надання Послуг, а також у процесі їх надання, якщо це зумовлено обставинами, які знаходяться поза впливом Виконавця, змінювати осіб під керівництвом яких здійснюється безпосереднє надання послуг (проведення уроків з іноземної мови);',
      '2.2.4. змінювати порядок надання Послуг, попередньо повідомивши Замовника, не змінюючи у такому випадку загальний обсяг Послуг, що надається;',
      '2.2.5. формувати Розклад, змінювати його повністю або його окремі складові;',
      '2.2.6. у випадку, якщо Замовник(Студент)  порушує етичні норми поведінки в процесі надання Послуг та заважає процесу навчання, Виконавець має право відсторонити Замовника(Студента) від отримання Послуг без повернення грошових коштів останньому;',
      '2.2.7. у процесі надання Послуг залучати третіх осіб (без додаткового погодження із Замовником(Студентом), залишаючись відповідальним перед Замовником за їх якість;',
      '2.2.8. виконавець має право не надавати послуги і повернути грошові кошти Замовнику у разі, якщо існують причини, які не залежать від волі Виконавця;',
      '2.2.9. вносити зміни та доповнення до запланованої програми занять щодо структури, графіку надання Послуг, порядку викладання матеріалу для покращення його засвоєння та форми проведення занять в односторонньому порядку за умови завчасного попередження Замовника про такі зміни не менше ніж за 1 (один) календарний день до запровадження змін/доповнень. 2.2.10.. змінювати та/або корегувати процес надання Послуг з метою його оптимізації, підвищення якості Послуг, що надаються, включаючи, але не обмежуючись, вносити зміни до складу викладачів, методів та способів надання Послуг, в односторонньому порядку без попередніх повідомлень та/або погоджень із Замовником.',
      '2.2.10. своєчасно та в повному розмірі отримувати оплату послуг в порядку, визначеному в Договорі.',
      '2.2.11. При настанні форс-мажорних обставин у вигляді запровадження на території України (або частини її території) карантинних обмежень (спричинених COVID-19 або іншим інфекційним захворюванням), військового стану, блекауту тощо,  Виконавець залишає за собою право надавати Послуги в дистанційному режимі (в форматі онлайн-занять), без попереднього погодження таких дій з Замовником.',
      '2.2.12. Змінювати та/або корегувати процес надання Послуг з метою їх оптимізації, підвищення якості Послуг, що надаються, включаючи (але не обмежуючись): вносити зміни до складу викладачів, змінювати чисельність Студентів (в навчальній групі), вносити зміни в графік надання Послуг в односторонньому порядку без попередніх погоджень із Замовником.',
      '2.2.13. Послуги Виконавця надаються з обов’язковим дотриманням усіх Студентів процесу надання Послуг, внутрішніх і санітарних норм навчальних центрів Виконавця (у разі необхідності).',
    ],
    customerUndertakes: "Замовник зобов'язується:",
    obligationsCustomer: [
      '2.3.1.\tДотримуватись встановленого Виконавцем порядку надання Послуг та бути присутнім під час їх надання у місці, визначеному Виконавцем, а у випадку визначення Замовником Студента, який отримує Послуги (відповідно до пункту 8.4 Договору), забезпечити присутність Студента та дотримання ним встановленого порядку надання Послуг.',
      "2.3.2.\tНадавати Виконавцеві за його вказівкою всі необхідні документи та інформацію, необхідну для належного виконання зобов'язань по наданню Послуг вказаних пунктом 1.1 Договору. Перелік документів, які може витребовувати Замовник, передбачений п.9.1 даного Договору.",
      '2.3.3.\tПриймати від Виконавця Послуги, що надаються згідно з цим Договором, в порядку, встановленому Договором.',
      '2.3.4.\tСвоєчасно та в повному обсязі здійснювати оплату Послуг в порядку та на умовах, визначених умовами даного Договору.',
      '2.3.5.\tПовідомляти Виконавця про зміну своїх реквізитів, номерів контактних телефонів та іншої інформації, зазначеної в розділі 9 даного Договору протягом 2 (двох) робочих днів з дати таких змін. В разі неповідомлення та/або несвоєчасного повідомлення про зміну вказаних в цьому Договорі реквізитів, Замовник приймає на себе ризики настання негативних наслідків, пов`язаних з таким не повідомленням/несвоєчасним повідомленням.',
    ],
    customerHasRight: 'Замовник має право:',
    customerRights: [
      '2.4.1.\tОтримувати Послуги на умовах та в порядку, передбачених даним Договором.',
      '2.4.2.\tЗмінювати час проведення індивідуальних занять (відмінити/перенести) не пізніше ніж за одну добу, поінформувавши адміністратора Виконавця. У випадку відміни заняття в день, коли заняття повинно відбутися, воно вважається проведеним.',
    ],
  },
];

export const coastAndPayment = [
  {
    coast: [
      '3.1.\tВартість, порядок і строки оплати Послуг, визначаються у Додатку №1 до Договору.',
      '3.2.\tЗамовник здійснює оплату Послуг в національній валюті (гривнях) впродовж 3 (трьох) банківських днів від дати укладання цього Договору, шляхом безготівкового переказу на розрахунковий рахунок Виконавця, який вказаний у розділі 9 даного Договору.',
      '3.3.\tЯкщо Замовник (або Студент) з власної ініціативи та/або через хворобу та/або через відрядження та/або через зміну місця проживання та/або з будь-яких інших причин не отримує Послуги за цим Договором або отримує їх не в повному обсязі, строки надання Послуг не змінюються і не переносяться, якщо Сторонами не буде погоджено інше. Замовник зобов‘язується повністю сплатити вартість Послуг. У зв‘язку з тим, що кількість місць є обмеженою, неотримання Послуг Замовником (або Студентом) не дає Замовнику права на повернення оплачених коштів.',
      '3.4.\tЗамовник погоджується з тим, що сплачені ним грошові кошти, що становлять вартість цих Послуг та які надійшли у повному (частковому) обсязі до Виконавця, не повертаються Замовнику в будь - якому разі, в тому числі і в разі відмови Замовника від цих Послуг.',
    ],
  },
];

export const serviceProvisionProcedure = [
  {
    service: [
      '4.1.\tПослуги надаються шляхом безпосереднього консультування та інформування Замовника (або Студента) спеціалістами Виконавця згідно встановленого Виконавцем розкладу.',
      "4.2.\tЗамовник залишає за собою право (у разі необхідності) запросити у Виконавця Акт прийому-передачі Послуг, але не пізніше 5 (п'яти) календарних днів з дня закінчення строку їх надання, що зазначається у Додатку №1, графа «Обсяг наданих Послуг» до даного Договору.",
      "4.3.\tПослуги вважаються наданими належним чином та у повному обсязі, якщо з дня закінчення строку їх надання минуло більше як 5 (п'ять) календарних днів, та Замовник не надав жодних письмових мотивованих зауважень.",
      "4.4.\tВ зв'язку з тим, що Виконавець (представник Виконавця) надсилає Замовнику на його e-mail рахунок на оплату разом з підписаним Договором, Замовник має право ознайомитись з усіма його умовами.",
      '4.5.\tЗдійснення Замовником оплати є підтвердженням окрім іншого, факту ознайомлення та погодження Замовника з усіма умовами Договору. При наявності факту сплати рахунку, Замовник позбавлений права стверджувати, що він не ознайомлений та не погоджувався з його умовами.',
      '4.6.\tЗамовник має право з метою підписання цього Договору та його Додатків, скористуватись електронним документооборотом будь-якого надавача електронних довірчих послуг (таких як: «Paperless») та підписати Договір та Додатки до нього за допомогою ЕЦП, які створені та функціонують згідно Закону України «Про електронні довірчі послуги».',
      "4.7.\tДокументи, які відправленні факсом чи електронною поштою, мають повну юридичну силу до моменту обміну оригіналами, породжують права та обов'язки для Сторін, можуть бути подані в судові інстанції в якості належних доказів, і не можуть заперечуватись стороною, від імені якої вони були відправлені.",
      '4.8.\tСторони погоджують, що електронна переписка між ними, в будь-якому разі є належним доказом в будь-якому державному органі.',
    ],
  },
];

export const responsibilitiesParties = [
  {
    responsibilities: [
      "5.1.\tУ разі невиконання чи неналежного виконання однією із Сторін своїх зобов'язань, передбачених Договором, винна Сторона несе цивільно-правову відповідальність. Порушенням зобов'язання є його невиконання або неналежне виконання, тобто виконання з порушенням умов, визначених змістом зобов'язання.",
      "5.2.\tСторона, яка порушила зобов'язання, передбачені Договором, повинна усунути такі порушення у найкоротший строк.",
      "5.3.\tУ разі втрати та/або пошкодження Замовником (або Студентом) технічного обладнання та/або інших засобів та/або іншого майна, яке буде використовуватись Замовником (або Студентом) під час отримання Послуг за цим Договором, Замовник зобов'язаний відшкодувати Виконавцю вартість пошкодженого та/або втраченого обладнання, засобів, майна протягом 3 (трьох) робочих днів з дати отримання відповідної вимоги. При цьому Виконавець має право призупинити надання Послуг Замовнику (або Студентові) до моменту отримання відшкодування нанесених збитків/шкоди. В разі прострочення Замовником строку відшкодування збитків/шкоди більше ніж на 5 (п‘ять) робочих днів, Виконавець має право розірвати Договір в односторонньому порядку шляхом надіслання на адресу Замовника відповідного письмового повідомлення за 10 (десять) календарних днів до бажаної дати розірвання. У такому випадку, Виконавець не повертає Замовнику грошові кошти, які були сплачені за даним Договором.",
      '5.4.\tВчинене Замовником (або Студентом) порушення повинно бути підтверджено відповідним Актом, який складається Виконавцем, в якому зазначаються дата, назва вчиненого порушення. Такий Акт повинен бути підписаний Замовником і Виконавцем. В разі відмови Замовника від підписання Акту Виконавець може залучити до підписання акту двох свідків, підписи яких в Акті підтверджують вчинення Замовником порушення.',
      "5.5.\tУ разі, якщо Замовник визначив отримувачем Послуг Студента, відповідальним перед Виконавцем за виконання обов'язків з оплати Послуг та інших умов, передбачених цим Договором залишається Замовник.",
      '5.6.\tВиконавець не несе будь-якої відповідальності (в тому числі і матеріальної) за особисті речі Замовника та/або Студента, які були загублені, викрадені чи пошкоджені в період надання цих Послуг.',
    ],
  },
];

export const forceMajeure = [
  {
    force: [
      "6.1.\tСторони звільняються від відповідальності за неналежне виконання або за невиконання зобов'язань за цим Договором у разі настання обставин непереборної сили (форс-мажор), таких як: повінь, землетрус, пожежа і інші явища природи, введення надзвичайного положення або воєнного стану, страйки, карантини, епідемії (в тому числі, але не обмежуючись таких як коронавірус та ін.), надзвичайна ситуація в сфері охорони здоров'я, обмежувальні дії влади, зміни в діючому як в національному законодавстві, законні дії органів державної влади і їх структурних підрозділів і інші обставини непереборної сили.",
      "6.2.\tСторона, для якої виникли умови, при яких неможливе виконання зобов'язань за Договором внаслідок обставин непереборної сили, повинна сповістити іншу Сторону про настання цих обставин. Повідомлення повинне містити дані про настання й характер обставин, їхні можливі наслідки, а також пропозиції щодо подальших дій Сторін у зв'язку із настанням таких обставин.",
      "6.3.\tПри виникненні форс-мажорних обставин, за згодою Сторін, терміни виконання зобов'язань за даним Договором можуть змінюватись (пропорційно терміну дії форс-мажорних обставин чи їх наслідків) або Договір розривається.",
      "6.4.\tСторони погодили, що для них достатнім підтвердженням форс-мажорних обставин буде опублікування на офіційних веб-сайтах державних органів влади наступних документів: рішення Президента України про запровадження надзвичайної ситуації в Україні або воєнного стану, рішення (або постанова), розпорядження Кабінету Міністрів України щодо визнання окремих місцевостей (областей) України потерпілими від несприятливих погодних умов, запровадження національного (регіонального) карантину в зв'язку з будь-якими епідеміями/вірусними інфекціями (в тому числі, але не обмежуючись: епідемія/карантин через коронавірус), рішення постійної Комісії з питань техногенно-екологічної безпеки та надзвичайних ситуацій щодо впровадження карантину, накази, розпорядження Міністерства охорони здоров'я України і т.д., які стали причиною неможливості виконання зобов'язань.",
      "6.5.\tВиконавець не несе відповідальність за невиконання будь-якого із своїх зобов'язань, якщо воно було викликане перешкодою поза її контролем.",
    ],
  },
];

export const settlementDisputes = [
  {
    settlement: [
      "7.1.\tУсі спори та розбіжності, що виникнуть у процесі виконання умов Договору або у зв'язку з тлумачення його положень вирішуються шляхом проведення переговорів між Сторонами.",
      '7.2.\tЯкщо спори та розбіжності залишилися неврегульованими домовленістю Сторін, вони передаються на розгляд суду, за встановленою підвідомчістю та підсудністю такого спору, визначеному відповідним чинним законодавством України.',
    ],
  },
];

export const end = [
  {
    first: [
      "8.1.\tДоговір набуває чинності з дня його підписання Сторонами та діє до закінчення Строку надання Послуг, що передбачений в Додатку №1 до даного Договору, але в будь-якому разі до повного виконання Сторонами своїх зобов'язань. У разі, якщо процедура припинення дії Договору передбачена не в односторонньому порядку, дострокове припинення дії Договору здійснюється за взаємною згодою сторін.",
      '8.2.\tОдностороння зміна умов Договору або порядку його виконання Сторонами не допускається. Проте, Виконавець залишає за собою право змінювати в односторонньому порядку умови договору та порядок його виконання у наступних виключних випадках:',
      '8.2.1.\tУ випадку одноразового порушення Замовником умов даного Договору щодо оплати Послуг, зазначених у Додатку №1 до даного Договору.',
      '8.2.2.\tУ випадках, передбачених п.5.3 даного Договору.',
      '8.3.\tДаний Договір може бути достроково розірваний Виконавцем в односторонньому порядку шляхом надсилання письмового повідомлення Замовнику не менше ніж за 10 (десять) календарних днів до запланованої дати розірвання у випадках, передбачених п.п.8.2.1 цього Договору. При цьому Виконавець не повертає Замовнику грошові кошти, які були сплачені за Договором.',
      "8.4.\tЗамовник може на власний розсуд визначити у Додатку №1 до Договору Студента, який(яка) отримуватиме Послуги за Договором та матиме права Замовника, визначені Договором залишаючись відповідальним перед Виконавцем за виконання своїх обов'язків з оплати Послуг та позбавляючись права на їх отримання.",
      '8.5.\tПідписанням цього Договору, Замовник надає дозвіл на використання робіт, світлин (фото) або відеозаписів Студента визначеної Замовником для отримання послуг, та інших матеріалів з метою рекламування або презентації ТМ «STUDY ACADEMY», ТМ «STUDYUA» навчального центру «Академія англійської мови «STUDY ACADEMY» та досягнень її студентів.',
      '8.6.\tДоговір укладено у двох примірниках, по одному для кожної із Сторін, які мають однакову юридичну силу.',
      '8.7.\tПідписанням цього Договору Замовник надає Виконавцю згоду на збирання та обробку своїх персональних даних та персональних даних особи, якій надаватимуться Послуги, необхідних для виконання умов цього Договору.',
      "8.8.\tВсі додатки, зміни та доповнення до Договору є його невід'ємними частинами за умови їх підписання Сторонами.",
      '8.9.\tУ випадках, не передбачених даним Договором, Сторони керуються нормами чинного законодавства України.',
      '8.10.\tПри виконанні умов Договору сторони керуються ст. ст. 901-907 ЦК України, якщо інше не передбачено цим Договором.',
    ],
    privacyNumber: '8.11.',
    privacyBold: '\tКонфіденційність: ',
    privacyText:
      'конфіденційною інформацією для цілей цього Договору є будь-яка інформація щодо Замовника (та Студента), виражена в усній, письмовій, електронній чи іншій формі, в тому числі інформація, яка становить комерційну таємницю, що була отримана Виконавцем в процесі виконання Договору, незалежно від способу її отримання.',
    other: [
      "8.12.\tВиконавець з моменту укладення цього Договору зобов'язаний вживати всіх можливих заходів захисту конфіденційної інформації на виконання цього Договору, зобов'язання про нерозголошення чинне протягом строку дії Договору та протягом 3 (трьох) років від дати припинення даного Договору.",
      "8.13.\tУмови зберігання конфіденційної інформації не поширюються на загальнодоступну (загальновідому) інформацію, або інформацію, що одержана через офіційний запит органів, яким Сторони зобов'язані надавати необхідні відомості та інформацію, яка згідно чинного законодавства України не є конфіденційною.",
      '8.14.\tЗамовник погоджується з тим, що надані ним персональні дані, які зазначаються в Договорі є добровільним виявленням Замовника, і Замовник надає дозвіл Товариству на збір, обробку та використання його персональних даних (та/або персональних даних Студента), необхідних для ідентифікації останнього (або останніх), та такі персональні дані можуть бути використані з метою повідомлення Замовника про зміни, які можуть відбуватися в ході виконання цього Договору, а також з метою забезпечення бухгалтерського обліку та реалізації інших питань господарської діяльності Товариства. Також Замовник надає згоду Товариству на отримання повідомлень про різноманітні послуги, які можуть бути запропоновані Замовнику, в тому числі, але не виключно, про курси, тренінги.',
      "8.15.\tВсі Додатки та Додаткові угоди до цього Договору (навіть ті, які будуть підписані в майбутньому) є невід'ємними його частинами.",
    ],
    subtitle: '9.1. До Договору Замовником прикладаються наступні документи:',
    documents: [
      '‑ Свідоцтво про народження/паспорт Студента – копія;',
      '‑ Паспорт Замовника – копія.',
    ],
  },
];
