import axios from 'axios';
import config from '../config';

// import MockAdapter from "axios-mock-adapter";

const LOCAL_DOMAINS = ['localhost', '127.0.0.1'];

let URL = window?.location?.origin || config.PROD_URL;

if (LOCAL_DOMAINS.includes((window || { location: {} }).location.hostname)) {
  URL = `http://localhost:${config.API_PORT}`;
}

export async function getRates() {
  // get request

  //Наличный курс ПриватБанка (в отделениях):
  // https://api.privatbank.ua/p24api/pubinfo?json&exchange&coursid=5

  //Безналичный курс ПриватБанка (конвертация по картам, Приват24, пополнение вкладов)
  // https://api.privatbank.ua/p24api/pubinfo?exchange&json&coursid=11

  let res = '';
  await axios
    .get(
      `${URL}/api/pb`,
      // 'https://api.privatbank.ua/p24api/pubinfo?exchange&json&coursid=11',
      {}
    )
    .then(function (response) {
      // console.log("getRates", response.data);
      res = response.data;
    })
    .catch(function () {})
    .finally(function () {});
  return res;
}
