import { API } from 'plugins/request';

export const orders = new API('orders');
export const pb = new API('currencies/pb');
export const currencyChange = new API('currencies/change');
export const currencyChangeId = new API('currencies-change');
export const locations = new API('locations');
export const recipients = new API('recipients');
export const programTypes = new API('program-types');
export const programDetails = new API('program-details');
export const paymentMethods = new API('payment-methods');
export const users = new API('users');
export const orders_1c = new API('orders/1c');
//new manual currency for PLN
export const currencyManual = new API('currencies-manual');
