import { Text } from 'ui';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const RedText = styled(Text)`
  color: #dc3545;
  font-weight: 700;
`;
