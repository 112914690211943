// Libraries
import React from 'react';

// Containers
import { CreatePDF } from 'modules/main/containers/PdfCreator/CreatePDF';
import { Bills } from '../Bills/index';
import { CopyOrder } from '../CopyOrder/index';
import { Info1C } from '../Info1C/index';
import { MarkToDelete } from '../MarkToDelete/index';
import { Freeze } from '../Freeze/index';
import { Surcharge } from '../Surcharge';

// Utils
import { ShowAgreement } from 'utils/checkShowAgreement';

// Components
import { Flex } from '~/ui';
import { Table } from 'antd';
import { CreateDocx } from '../../../main/containers/DocxCreator/CreateDocx';
import { programName } from '../../services/programName';

const { Column } = Table;

const resizedKeys = ['global_id', 'order_id'];
const resizedKeysButtons = ['ORDER', 'BILL', 'CONTRACT', 'BILLS'];
const title = {
  ORDER: 'Замовлення',
  BILL: 'Рахунок',
  AGREEMENT: 'Договір доручення',
  CONTRACT: 'Додаткова угода',
  BILLS: 'Рахунок',
  COPYORDER: 'Коп. в нове замовлення',
  SURCHARGE: 'Доплата на замовлення',
  INFO1C: '1C',
  FREEZE: 'Заморозити суму',
  TODELETE: 'Відмітити на видалення',
  DOCX: 'Договір docx',
  DOCXSTAMP: 'Договір docx, з печаткою',
};

const buttonStyle = {
  height: '34px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0',
  padding: '5px 10px',
};

// Header
export const OrdersTable = React.memo(
  ({
    pagination = {},
    loading = false,
    ordersKeys = {},
    orders = [],
    pureOrders = [],
    role,
    handlePagination = () => {},
  }) => {
    return (
      <Flex margin="0 auto" width="calc(100% - 40px)">
        <Table
          onChange={(e) => {
            // console.log('Table onChange', e);
            handlePagination(e);
          }}
          pagination={pagination}
          loading={loading}
          className={'ordersCustome'}
          scroll={{ x: 15000, y: 500 }}
          rowClassName={(item, index) => {
            // pureOrders[index]?.delete1C === true ? 'custome_red' : '';
            // console.log('delete1C index[', index, '] ', pureOrders[index]?.delete1C);
            let className = '';
            if (pureOrders[index]?.delete1C === true) className += 'custome_red ';
            if (pureOrders[index]?.freez) className += 'freezen ';
            return className;
          }}
          dataSource={orders.map((o, index) => ({
            ORDER: (
              <CreatePDF
                short
                style={buttonStyle}
                type="order"
                order={pureOrders[index]}
                key={'order_' + pureOrders[index]?.order_id}
              />
            ),
            BILL: (
              <CreatePDF
                short
                style={buttonStyle}
                type={pureOrders[index]?.programType === 'ІТ напрям' ? 'newBill' : 'bill'}
                order={pureOrders[index]}
                key={'bill_' + pureOrders[index]?.order_id}
              />
            ),
            AGREEMENT: (
              <CreatePDF
                short
                style={buttonStyle}
                type="agreement"
                order={pureOrders[index]}
                disabled={
                  !ShowAgreement({
                    programType: pureOrders[index].programType,
                    programName: pureOrders[index].programName,
                    paymant: orders[index].paymant,
                  })
                }
                key={'agreement_' + pureOrders[index]?.order_id}
              />
            ),
            CONTRACT: (
              <CreatePDF
                short
                style={buttonStyle}
                type="contract"
                order={pureOrders[index]}
                key={'contract_' + pureOrders[index]?.order_id}
              />
            ),
            INFO1C: (
              <Info1C
                style={{ fontSize: '18px' }}
                status={pureOrders[index]?.sync_1c}
                text="замовлення"
                key={'info1c_' + pureOrders[index]?.order_id}
              />
            ),
            BILLS: (
              <Bills
                order={pureOrders[index]}
                role={role}
                key={'bills_' + pureOrders[index]?.order_id}
              />
            ),
            COPYORDER: (
              <CopyOrder
                order={pureOrders[index]}
                key={'copy_order_' + pureOrders[index]?.order_id}
              />
            ),
            SURCHARGE: (
              <Surcharge
                order={pureOrders[index]}
                key={'surcharge_' + pureOrders[index]?.order_id}
              />
            ),
            FREEZE: (
              <Freeze
                order={pureOrders[index]}
                key={'freeze' + pureOrders[index]?.order_id}
              />
            ),
            TODELETE: (
              <MarkToDelete
                style={buttonStyle}
                order={pureOrders[index]}
                key={'mark_to_delete' + pureOrders[index]?.order_id}
              />
            ),
            DOCX: (
              <>
                {programName.includes(pureOrders[index]?.programName) ? (
                  <CreateDocx
                    style={buttonStyle}
                    orderValues={pureOrders[index]}
                    key={'order_' + pureOrders[index]?.order_id}
                  />
                ) : (
                  <></>
                )}
              </>
            ),
            DOCXSTAMP: (
              <>
                {programName.includes(pureOrders[index]?.programName) ? (
                  <CreateDocx
                    style={buttonStyle}
                    orderValues={pureOrders[index]}
                    key={'order_' + pureOrders[index]?.order_id}
                    isStampAndSignature
                  />
                ) : (
                  <></>
                )}
              </>
            ),
            ...o,
          }))}>
          {orders.length > 0 &&
            Object.keys({
              ORDER: 0,
              CONTRACT: 1,
              AGREEMENT: 2,
              INFO1C: 3,
              BILLS: 4,
              COPYORDER: 5,
              SURCHARGE: 6,
              FREEZE: 7,
              TODELETE: 8,
              DOCX: 9,
              DOCXSTAMP: 10,
              ...orders[0],
            }).map((key, i) =>
              key !== 'ORDER' &&
              key !== 'BILL' &&
              key !== 'AGREEMENT' &&
              key !== 'CONTRACT' &&
              key !== 'INFO1C' &&
              key !== 'BILLS' &&
              key !== 'COPYORDER' &&
              key !== 'SURCHARGE' &&
              key !== 'FREEZE' &&
              key !== 'TODELETE' &&
              key !== 'DOCX' &&
              key !== 'DOCXSTAMP' ? (
                <Column
                  {...{
                    width: resizedKeys.includes(key)
                      ? 100
                      : resizedKeysButtons.includes(key)
                      ? 185
                      : 'auto',
                  }}
                  ellipsis={true}
                  title={ordersKeys[i - 11]}
                  dataIndex={key}
                  key={key}
                />
              ) : key === 'CONTRACT' && role !== 'admin' ? null : (
                <Column
                  width={key === 'BILLS' ? '332px' : key === 'INFO1C' ? '50px' : '130px'}
                  dataIndex={key}
                  key={key}
                  title={title[key] || ''}
                />
              )
            )}
        </Table>
      </Flex>
    );
  }
);

export default OrdersTable;
