import React from 'react';
import styled from 'styled-components';

// Component
export const UIButtonError = (button) => {
  const Button = styled(button)`
    background-color: #ff4343 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border: none !important;
    margin: 0 4px;
    border-radius: 5px !important;
  `;

  return (p) => {
    return <Button {...p}>{p.children}</Button>;
  };
};
export default (button) => {
  const Button = styled(button)`
    background-color: #61b5ff !important;
    color: #fff !important;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border: none !important;
    margin: 0 4px;
    border-radius: 5px !important;
  `;

  return (p) => {
    return <Button {...p}>{p.children}</Button>;
  };
};
