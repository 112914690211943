import React from 'react';
import styled from 'styled-components';

const s = (inputGroup) => styled(inputGroup)``;

export default (inputGroup) => {
  const InputGroup = s(inputGroup);

  return (props) => <InputGroup>{props.children}</InputGroup>;
};
