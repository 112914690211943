import React from 'react';
import styled from 'styled-components';

const s = (title) => styled(title)``;

export default (title) => {
  const Title = s(title);

  return (p) => <Title {...p}>{p.children}</Title>;
};
