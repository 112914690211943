import React, { useState } from 'react';
import moment from 'moment';

import { Modal, Button } from 'antd';

// plugins
import { connect } from '~/plugins/redux';
//ui
import { InputGroup, Title } from 'ui';
//styles
import { SButton, IconFreeze } from './styles';
//utils
import transformToNum from 'utils/getNumVal';
// Components
import { InputNumber, Date as DateField } from 'components/Fields';

// Model data
const getStateFromModel = ({ main: { loading }, users: { userData } }) => ({
  userData,
  loading,
});
const getMethodsFromModel = ({
  adminOrders: { setState, freezOrder, deleteFreezOrder, getOrdersFromServer },
}) => ({
  setState,
  freezOrder,
  deleteFreezOrder,
  getOrdersFromServer,
});

export const Freeze = connect(
  getStateFromModel,
  getMethodsFromModel
)(
  ({
    order = {},
    userData = {},
    loading = false,
    setState = () => {},
    freezOrder = () => {},
    deleteFreezOrder = () => {},
    getOrdersFromServer = () => {},
    ...props
  }) => {
    const { order_id, date, currency, amount_payable, freez } = order;

    const [visible, setVisible] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const dateFormatList = ['DD-MM-YYYY', 'DD.MM.YYYY'];
    const [dateDefault, setDateDefault] = useState(
      date ? moment(date).format(dateFormatList[1]) : moment().format(dateFormatList[1])
    );
    const [sum, setSum] = useState(freez?.sum);

    const handleChangeDate = (e) => {
      setDateDefault(moment(e).format(dateFormatList[1]));
    };
    const handleChangeSum = (e) => {
      const val = transformToNum(e);
      if (val > amount_payable) setSum(amount_payable);
      else setSum(val);
    };
    const handleOk = async () => {
      if (sum) {
        setDataLoading(true);
        try {
          await freezOrder({ order_id, sum, date: dateDefault, currency });
        } catch (error) {}
        setDataLoading(false);
        setVisible(false);
        await getOrdersFromServer();
      }
    };
    const deleteFreez = async () => {
      setDataLoading(true);
      try {
        await deleteFreezOrder({ order_id });
      } catch (error) {}
      setDataLoading(false);
      setVisible(false);
      await getOrdersFromServer();
    };
    const showModal = () => {
      setVisible(true);
    };
    const handleCancel = () => {
      setVisible(false);
    };
    return (
      <>
        <Modal
          title={`Замовлення №${order_id}`}
          visible={visible}
          onOk={handleOk}
          confirmLoading={dataLoading}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Закрити
            </Button>,
            <Button
              key="remove_freez"
              type="primary"
              danger
              loading={dataLoading}
              onClick={deleteFreez}
              disabled={!freez}>
              Розморозити
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={dataLoading}
              onClick={handleOk}
              disabled={!sum}>
              Заморозити
            </Button>,
          ]}>
          <>
            <Title>{`Заморозити суму(повна вартість - ${amount_payable} ${currency}):`}</Title>
            <InputGroup>
              <DateField
                addonBefore="Дата"
                onChange={handleChangeDate}
                disabled={true}
                defaultValue={moment(dateDefault, dateFormatList[1])}
                format={dateFormatList[0]}
              />
              <InputNumber
                addonBefore={`Сума (${currency})`}
                onChange={handleChangeSum}
                value={sum}
              />
            </InputGroup>
          </>
        </Modal>
        <SButton>
          <IconFreeze onClick={showModal} />
        </SButton>
      </>
    );
  }
);
