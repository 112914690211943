import React from 'react';
import {
  Page,
  Text,
  Note,
  View,
  Link,
  Document,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';

import LiberationSerifReg from './fonts/LiberationSerif-Regular.ttf';
import LiberationSerifBold from './fonts/LiberationSerif-Bold.ttf';
import LiberationSerifItalick from './fonts/LiberationSerif-Italic.ttf';

Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifReg,
});
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifBold,
  fontWeight: 'bold',
});
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifItalick,
  fontStyle: 'italic',
});
const text_m = {
  '01': 'січня',
  '02': 'лютого',
  '03': 'березня',
  '04': 'квітня',
  '05': 'травня',
  '06': 'червня',
  '07': 'липня',
  '08': 'серпня',
  '09': 'вересня',
  10: 'жовтня',
  11: 'листопада',
  12: 'грудня',
};
const styles = StyleSheet.create({
  page: {
    padding: '40px 60px',
    backgroundColor: '#fff',
    fontSize: 10,
    fontFamily: 'LiberationSerif',
    textAlign: 'justify',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  t_blod: {
    fontFamily: 'LiberationSerif',
    fontWeight: 'bold',
  },
  t_italic: {
    fontFamily: 'LiberationSerif',
    fontStyle: 'italic',
  },
  t_center: {
    textAlign: 'center',
    marginBottom: 10,
  },
  t_i_blod: {
    textIndent: 20,
    fontFamily: 'LiberationSerif',
    fontWeight: 'bold',
  },
  text: { textIndent: 20, marginBottom: 5 },
});
export const Contract = (props) => {
  let sName = (
    props.orderValues?.recipient?.name ||
    props.orderValues?.recipient?.title ||
    ''
  ).replace('ФОП', '');
  sName = sName.replace(/\s+/g, ' ').trim();
  let arrName = sName.split(' ');
  let arrDirector = (props.orderValues?.recipient?.director || '')
    .replace(/\s+/g, ' ')
    .trim()
    .split(' ');
  let pibName = sName;
  let pibDirector = null;
  if (arrName.length === 3) {
    pibName = arrName[0];
    pibName += ' ' + arrName[1].substr(0, 1) + '.';
    pibName += arrName[2].substr(0, 1) + '.';
  }
  if (arrDirector.length === 3) {
    pibDirector = arrDirector[0];
    pibDirector += ' ' + arrDirector[1].substr(0, 1) + '.';
    pibDirector += arrDirector[2].substr(0, 1) + '.';
  }
  const counterCorectNums = (props.orderValues?.special_wishes || '') !== '' ? 0 : 3;

  const recipient = props?.orderValues.recipient || {};
  const recipientType = recipient?.type || '';
  const recipientPlace = recipient?.place || '';

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.t_center}>
          <Text style={styles.t_blod}>ДОДАТКОВА УГОДА</Text>
          <Text style={styles.t_blod}>до публічного Договору про надання послуг</Text>
          <Text style={styles.t_italic}>в порядку ст.ст. 633, 634 ЦК України</Text>
        </View>
        <View style={styles.row}>
          <Text style={{ textIndent: 20, fontWeight: 'bold' }}>м. Київ</Text>
          <Text style={styles.t_blod}>
            {props?.orderValues?.createDate?.d || ''}{' '}
            {text_m[props.orderValues?.createDate?.m] || ''}{' '}
            {props?.orderValues?.createDate?.y || ''} року
          </Text>
        </View>

        <Text style={styles.text}>
          Керуючись ст.ст. 633, 634 ЦК України, а також умовами акцептованого Замовником
          публічного Договору про надання послуг, який розміщений на сайті{' '}
          <Link
            src={
              (props?.orderValues || {})?.offer_agreement_link ||
              (props?.orderValues?.selectedValues || {})?.offer_agreement_link ||
              props.orderValues?.recipient?.offer_agreement_link ||
              ''
            }>
            {(props?.orderValues || {})?.offer_agreement_link ||
              (props?.orderValues?.selectedValues || {})?.offer_agreement_link ||
              props.orderValues?.recipient?.offer_agreement_link ||
              ''}
          </Link>
          , Сторони, а саме:{' '}
        </Text>
        <Text style={styles.text}>
          <Note style={{ textIndent: 20, fontWeight: 'bold' }}>
            {(
              props?.orderValues?.recipient?.name ||
              props?.orderValues?.recipient?.title ||
              ''
            )
              .replace('ФОП', 'Фізична особа-підприємець ')
              .toUpperCase()}{' '}
            ТМ «STUDY ACADEMY»
          </Note>{' '}
          (далі – <Note style={styles.t_blod}>«Виконавець»</Note>), код ЄДРПОУ{' '}
          {(props?.orderValues?.recipient || {})?.edrpou ||
            ((props?.orderValues?.selectedValues || {})?.recipient || {})?.edrpou ||
            ''}
          {', '}
          {props.orderValues?.recipient?.type === 'ТОВ'
            ? 'в особі Директора ' +
              (props.orderValues?.recipient?.director || '') +
              ', який/яка діє на підставі Статуту, '
            : 'який/яка діє на підставі Виписки з ЄДР, '}
          з однієї сторони, та Замовник, який приєднався до цих умов у встановленому
          Договором порядку (далі – <Note style={styles.t_blod}>«Замовник»</Note>), при
          спільному згадуванні – <Note style={styles.t_blod}>«Сторони»</Note>, уклали цю
          Додаткову угоду про наступне:
        </Text>
        <Text style={styles.text}>
          <Note style={styles.t_i_blod}>1. </Note>Виконавець підтверджує свої зобов’язання
          перед Замовником, передбачені умовами публічного Договору. За невиконання,
          неналежне виконання або порушення зобов’язань за Договором, Сторони несуть
          відповідальність в порядку, передбаченому чинним законодавством України.
        </Text>
        <Text style={styles.text}>
          <Note style={styles.t_i_blod}>2. </Note>Окрім того, Сторони підтверджують
          дійсність та актуальність всіх даних та інформацію, які вказані в Замовленні №{' '}
          {props?.orderValues?.order_id || ''}
          {'-'}
          {props?.orderValues?.createDate?.d || ''}
          {props?.orderValues?.createDate?.m || ''}
          {props?.orderValues?.createDate?.y || ''} від{' '}
          {props?.orderValues?.createDate?.d || ''}
          {'.'}
          {props?.orderValues?.createDate?.m || ''}
          {'.'}
          {props?.orderValues?.createDate?.y || ''} року, яке було отримано Замовником та
          є невід’ємною частиною публічного Договору.
        </Text>
        {counterCorectNums === 0 ? (
          <Text style={styles.text}>
            <Note style={styles.t_i_blod}>3. </Note>Керуючись ч. 2 ст. 634 ЦК України, на
            вимогу Замовника, Сторони дійшли взаємної згоди змінити в тексті акцептованого
            Замовником публічного Договору п. _____ та викласти його в наступній редакції
            «п. ____ ________________________________________».
          </Text>
        ) : null}
        {counterCorectNums === 0 ? (
          <Text style={styles.text}>
            <Note style={styles.t_i_blod}>4. </Note>Керуючись ч. 2 ст. 634 ЦК України, на
            вимогу Замовника, Сторони дійшли взаємної згоди виключити з тексту
            акцептованого Замовником публічного Договору пункт ______.
          </Text>
        ) : null}
        {counterCorectNums === 0 ? (
          <Text style={styles.text}>
            <Note style={styles.t_i_blod}>5. </Note>
            Керуючись ч. 2 ст. 634 ЦК України, на вимогу Замовника, Сторони дійшли
            взаємної згоди додати до тексту акцептованого Замовником публічного Договору
            пункт ______, та викласти його в наступній редакції: «п. ___
            ________________________________».
          </Text>
        ) : null}

        <Text style={styles.text}>
          <Note style={styles.t_i_blod}>{6 - counterCorectNums}. </Note>Ця Додаткова Угода
          набирає чинності з дати її підписання Сторонами та є невід’ємною частиною
          публічного Договору.
        </Text>
        <Text style={styles.text}>
          <Note style={styles.t_i_blod}>{7 - counterCorectNums}. </Note>Всі інші положення
          публічного Договору, які не змінені цією Додатковою Угодою, залишаються такими,
          що діють, і Сторони підтверджують свої зобов’язання за ними.
        </Text>
        <Text style={styles.text}>
          <Note style={styles.t_i_blod}>{8 - counterCorectNums}. </Note>Цю Додаткову угоду
          складено українською мовою в двох примірниках, кожний з яких має однакову
          юридичну силу: по одному для кожної із Сторін.
        </Text>
        <Text style={styles.text}>
          <Note style={styles.t_i_blod}>{9 - counterCorectNums}. </Note> Замовник розуміє,
          що оплата згідно публічного Договору не підлягає відшкодуванню.
        </Text>

        <Text
          style={{
            textAlign: 'center',
            // fontFamily: 'TimeNewRoman_bold',
            fontWeight: 'bold',
            marginBottom: 10,
            marginTop: 10,
          }}>
          {10 - counterCorectNums}. РЕКВІЗИТИ ТА ПІДПИСИ СТОРІН
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            textAlign: 'left',
            position: 'relative',
          }}>
          <Text
            style={{
              lineHeight: 2,
              position: 'absolute',
              top: 150,
              left: 0,
              fontWeight: 'bold',
            }}>
            {props.orderValues?.recipient?.type === 'ТОВ'
              ? 'Директор  \n' +
                '________________/ ' +
                (pibDirector || props.orderValues?.recipient?.director || '')
              : 'ФОП  \n' + '________________/ ' + pibName ||
                (
                  props.orderValues?.recipient?.name ||
                  props.orderValues?.recipient?.title ||
                  ''
                ).replace('ФОП', '')}
          </Text>
          <Text style={{ lineHeight: 2, position: 'absolute', top: 150, left: 270 }}>
            <Note style={styles.t_blod}>
              Замовник
              {'\n'} ________________/
            </Note>{' '}
            <Note style={styles.t_italic}>підпис</Note>
          </Text>
          <Text style={{ width: 250, marginRight: 20 }}>
            <Text
              style={{
                textAlign: 'center',
                lineHeight: 2,
                fontWeight: 'bold',
              }}>
              ВИКОНАВЕЦЬ {'\n'}
            </Text>
            <Text style={{ lineHeight: 1.5 }}>
              <Note style={styles.t_blod}>
                {(
                  props?.orderValues?.recipient?.name ||
                  props.orderValues?.recipient?.title ||
                  ''
                ).replace('Товариство з обмеженою відповідальністю', 'ТОВ')}
              </Note>
              {'\n'}
              {recipientType === 'ТОВ'
                ? `Місцезнаходження: ${recipientPlace}\n`
                : `Місце здійснення діяльності: ${recipientPlace}\n`}
              Код ЄДРПОУ:{' '}
              {(props?.orderValues?.recipient || {})?.edrpou ||
                ((props?.orderValues?.selectedValues || {})?.recipient || {})?.edrpou ||
                ''}
              {'\n'}
              Рахунок:{' '}
              {(props?.orderValues?.recipient || {})?.iban ||
                ((props?.orderValues?.selectedValues || {})?.recipient || {})?.iban ||
                ''}
              {'\n'} в{' '}
              {(props?.orderValues?.recipient || {})?.bank ||
                ((props?.orderValues?.selectedValues || {})?.recipient || {})?.bank ||
                ''}{' '}
              {'\n'}
            </Text>
          </Text>

          <Text style={{ width: 250 }}>
            <Text
              style={{
                textAlign: 'center',
                lineHeight: 2,
                fontWeight: 'bold',
              }}>
              ЗАМОВНИК{'\n'}
            </Text>
            <Text style={{ lineHeight: 2.0 }}>
              <Note style={styles.t_blod}>ПІБ:</Note>{' '}
              {(props?.orderValues?.client || {})?.payer_name || ''}
              {'\n'}
              <Note style={styles.t_blod}>Паспорт:</Note>{' '}
              {(props?.orderValues?.client || {})?.passport || ''}
              {'\n'}
              <Note style={styles.t_blod}>Ідентифікаційний номер:</Note>{' '}
              {(props?.orderValues?.client || {})?.payer_tax_ID || ''}
              {'\n'}
              <Note style={styles.t_blod}>Контактний телефон:</Note>{' '}
              {(props?.orderValues?.client || {})?.contact_phone || ''}
              {'\n'}
              {'\n'}
            </Text>
          </Text>
        </View>
      </Page>
    </Document>
  );
};
export default Contract;
