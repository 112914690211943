// import usersController from './requests';
import controller from './requests';

import { handleRequestError } from 'utils';
import initialState from './initialState';

export const model = {
  state: initialState,
  reducers: {
    resetState: () => initialState,
    addProgramDates(state, newProgramDates) {
      return {
        ...state,
        program_dates: [...state.program_dates, newProgramDates],
      };
    },
    setProgramDates(state, payload) {
      return {
        ...state,
        program_dates: payload,
      };
    },
    editProgramDates(state, edDates) {
      return {
        ...state,
        program_dates: state.program_dates.map((r) =>
          String(edDates._id) === String(r._id) ? edDates : r
        ),
      };
    },
    setModalData(state, payload) {
      return {
        ...state,
        modalData: payload,
      };
    },
    setModal(state, payload) {
      return {
        ...state,
        modal: payload,
      };
    },
    setCurrentRecord(state, payload) {
      return {
        ...state,
        currentRecord: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async getAllProgramDates(params, rootState) {
      params.limit = 1000; //
      try {
        const result = await controller.getAllProgramDates(params);

        if (!Array.isArray(result)) throw result;

        dispatch.adminProgramDates.setProgramDates(result[0]);
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminProgramDates.getAllProgramDates,
          params,
        });
      }
    },
    async addProgramDatesToServer(params, rootState) {
      try {
        const result = await controller.addProgramDatesToServer(params);
        await dispatch.adminProgramDates.addProgramDates(params);
        await dispatch.adminProgramDates.getAllProgramDates({});
        return result;
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminProgramDates.addProgramDatesToServer,
          params,
        });
      }
    },
    async editProgramDatesToServer(params = {}, rootState) {
      const currentRecord = rootState?.adminProgramDates?.currentRecord || {};
      const requestBody = { ...currentRecord, ...params };
      try {
        await controller.editProgramDatesToServer(requestBody);
        dispatch.adminProgramDates.editProgramDates(params);
        dispatch.adminProgramDates.getAllProgramDates({});
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminProgramDates.editProgramDatesToServer,
          params: requestBody,
        });
      }
    },
    async removeProgramDatesFromServer(_id = '', rootState) {
      try {
        await controller.removeProgramDatesFromServer(_id);
        await dispatch.adminProgramDates.getAllProgramDates({});
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.adminProgramDates.removeProgramDatesFromServer,
          params: _id,
        });
      }
    },
  }),
};

export default model;
