import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
// import TimeNewRoman from './fonts/Times_New_Roman.ttf';
// import TimeNewRoman_bold from './fonts/Times_New_Roman_Bold.ttf';
// import TimeNewRoman_italic from './fonts/Times_New_Roman_Italic.ttf';

import LiberationSerifReg from './fonts/LiberationSerif-Regular.ttf';
import LiberationSerifBold from './fonts/LiberationSerif-Bold.ttf';
import LiberationSerifItalick from './fonts/LiberationSerif-Italic.ttf';
// import LiberationSerifBoldItalic from './fonts/LiberationSerif-BoldItalic.ttf';

import { TransformNumberInWords } from 'services/NumbersInWords';
import { trasformData } from 'utils/restructuringOrderData';

// Font.register({
//   family: 'TimeNewRoman',
//   src: TimeNewRoman,
// });
// Font.register({ family: 'TimeNewRoman_bold', src: TimeNewRoman_bold });
// Font.register({ family: 'TimeNewRoman_italic', src: TimeNewRoman_italic });

Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifReg,
});
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifBold,
  fontWeight: 'bold',
});
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifItalick,
  fontStyle: 'italic',
});
const styles = StyleSheet.create({
  page: {
    padding: '30px 40px',
    backgroundColor: '#fff',
    fontSize: 12,
    // fontFamily: 'TimeNewRoman',
    fontFamily: 'LiberationSerif',
    textAlign: 'justify',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  col: {
    width: '50%',
    paddingRight: '20px',
  },
  t_blod: {
    // fontFamily: 'TimeNewRoman_bold',
    fontFamily: 'LiberationSerif',
    fontWeight: 'bold',
  },
  t_italic: {
    // fontFamily: 'TimeNewRoman_italic',
    fontFamily: 'LiberationSerif',
    fontStyle: 'italic',
  },
  t_center: {
    textAlign: 'center',
  },
  t_i_blod: {
    // fontFamily: 'TimeNewRoman_bold',
    fontFamily: 'LiberationSerif',
    fontWeight: 'bold',
  },
  text: { textIndent: 20, marginBottom: 20 },
});
export const Agreement = (props) => {
  const orderValues = trasformData(props?.orderValues, props?.bill);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.t_center}>
          <Text style={styles.text}>
            <Text style={styles.t_blod}>ДОГОВІР ДОРУЧЕННЯ</Text>
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={{ textIndent: 20 }}>м. Київ</Text>
          <Text>{orderValues?.fullTextDate}</Text>
        </View>

        <View style={styles.text}>
          <Text style={{ textIndent: 20 }}>
            Фізична особа{' '}
            <Text style={styles.t_italic}>{orderValues?.manager_full_name}</Text> надалі
            за текстом – «Повірений», що займає посаду менеджеру з продажу Товариства з
            обмеженою відповідальністю «СТАДI ХОЛIДЕЙ» ТМ «Українська освітня компанія
            «STUDYUA» (код ЄДРПОУ 42467735), та
          </Text>
          <Text style={{ textIndent: 20 }}>
            Фізична особа{' '}
            <Text style={styles.t_italic}>{orderValues?.client_payer_name}</Text>, надалі
            за текстом «Довіритель», разом – «Сторони», домовились про наступне:
          </Text>
        </View>
        <View style={styles.text}>
          <Text style={styles.t_center}>1. ПРЕДМЕТ ДОГОВОРУ</Text>
          <Text style={{ textIndent: 20 }}>
            1.1. Повірений, діючи на підставі цього договору доручення (далі – Договір) на
            безоплатній основі зобов'язується від імені Довірителя здійснити внесення
            грошових коштів у сумі{' '}
            {`${orderValues?.price_wiht_discountStr}.00 ${
              orderValues?.currency === 'UAH' ? 'грн.' : orderValues?.currency
            } (${new TransformNumberInWords(
              orderValues.price_wiht_discount,
              orderValues.currency
            ).transform()}) `}
            на наступні реквізити Товариства з обмеженою відповідальністю «СТАДI ХОЛIДЕЙ»:
            <Text style={styles.t_italic}>
              IBAN UA 63 300528 00000 26003455058769 в АТ «ОТП БАНК», м. Київ, МФО 300528.
            </Text>
          </Text>
          <Text style={{ textIndent: 20 }}>
            1.2. Кошти сплачується в національній валюті України гривні відповідно до
            комерційного курсу АТ «ОТП Банк» у день укладання цього договору. Зазначена
            сума буде зарахована в якості оплати по Договору про надання послуг для
            бронювання навчальної програми за кордоном на{' '}
            {orderValues?.program_start_year}-{orderValues?.program_end_year} рік Студента
            Довірителя.
          </Text>
        </View>
        <View style={styles.text}>
          <Text style={styles.t_center}>2. ПРАВА ТА ОБОВ`ЯЗКИ СТОРІН</Text>
          <Text style={{ textIndent: 20 }}>
            2.1. Сторони мають усі права та обов'язки, передбачені чинним законодавством
            України. Жодні обумовлювання, домовленості, та спеціальні умови, можливість
            внесення яких допускається нормами чинного законодавства України, та які
            змінюють та припиняють права та обов'язки Сторін по Договору, в даному
            Договорі не застосовуються.
          </Text>
        </View>
        <View style={styles.text}>
          <Text style={styles.t_center}>3. ПРИКІНЦЕВІ ПОЛОЖЕННЯ</Text>
          <Text style={{ textIndent: 20 }}>
            3.1. Підписання Повіреним даного Договору свідчить, що грошові кошти для
            виконання даного доручення отримані Повіреним у повному обсязі в сумі,
            передбаченій п. 1.1 цього Договору.
          </Text>
          <Text style={{ textIndent: 20 }}>
            3.2. Отримання Довірителем квитанції банківської установи про зарахування
            коштів на розрахунковий рахунок Товариства з обмеженою відповідальністю «СТАДI
            ХОЛIДЕЙ» ТМ «Українська освітня компанія «STUDYUA» (код ЄДРПОУ 42467735) є
            доказом виконання даного Договору Повіреним.
          </Text>
          <Text style={{ textIndent: 20 }}>
            3.3. Договір складено в двох примірниках по одному для кожної із Сторін, які
            мають однакову юридичну силу.
          </Text>
          <Text style={{ textIndent: 20 }}>
            3.4. Договір складено на українській мові в простій письмовій формі.
          </Text>
          <Text style={{ textIndent: 20 }}>
            3.5. Договір не потребує нотаріального посвідчення, так як юридичні дії,
            делеговані Довірителем Повіреному, також не потребують нотаріального
            посвідчення.
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={{ width: '50%', fontWeight: 'bold' }}>Повірений</Text>
          <Text style={{ width: '50%', fontWeight: 'bold' }}>Довіритель</Text>
          <Text style={styles.col}>прийняв грошові кошти</Text>
          <Text style={styles.col}>видав грошові кошти</Text>
          <Text style={{ width: '50%', marginTop: 15 }}>
            {orderValues?.manager_full_name}
          </Text>
          <Text style={{ width: '50%', marginTop: 15 }}>
            {orderValues?.client_payer_name}
          </Text>
          <Text style={styles.col}>підпис ____________________</Text>
          <Text style={styles.col}>підпис ____________________</Text>
        </View>
      </Page>
    </Document>
  );
};
export default Agreement;
