import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

import LiberationSerifReg from './fonts/LiberationSerif-Regular.ttf';
import LiberationSerifBold from './fonts/LiberationSerif-Bold.ttf';
import LiberationSerifItalic from './fonts/LiberationSerif-Italic.ttf';

import { trasformData } from 'utils/restructuringOrderData';

Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifReg,
});
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifBold,
  fontWeight: 'bold',
});
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifItalic,
  fontStyle: 'italic',
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingLeft: 30,
    backgroundColor: '#fff',
    fontSize: 10,
    fontFamily: 'LiberationSerif',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  border: {
    border: 1,
    padding: '10px 5px',
    position: 'relative',
  },
  p_absolute: {
    position: 'absolute',
    top: 50,
    right: 20,
    flexDirection: 'colum',
    alignItems: 'center',
  },
  m_b: {
    marginBottom: 5,
  },
  title: {
    fontSize: 13,
    marginTop: 15,
    marginBottom: 15,
    padding: '2px 5px',
    borderBottom: 1,
  },
  text_left: {
    minWidth: 100,
    textAlign: 'left',
    marginRight: 10,
  },
  center_block: {
    minWidth: 500,
  },
  row_mb: {
    flexDirection: 'row',
    marginBottom: 10,
  },
});

export const AccountWarszawaServices = (props) => {
  const orderValues = trasformData(props?.orderValues, props?.bill);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View>
            <View
              style={{
                alignItems: 'flex-end',
                marginBottom: 30,
              }}>
              <Text
                style={{
                  width: '200',
                  padding: '3',
                  borderTop: 1,
                  borderLeft: 1,
                  borderRight: 1,
                }}>
                {`Prepayment Invoice ${orderValues.order_number}`}
              </Text>
              <Text
                style={{
                  width: '200',
                  padding: '3',
                  border: 1,
                }}>
                {`Dated ${orderValues.date}`}
              </Text>
            </View>
            <View
              style={{
                width: 400,
                alignSelf: 'flex-end',
                flexDirection: 'column',
              }}>
              <Text
                style={{
                  textDecoration: 'underline',
                  marginBottom: 3,
                  width: '100%',
                }}>
                Sent by:
              </Text>
              <View
                style={{
                  fontWeight: 'bold',
                  width: '100%',
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  Company Name:
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  {orderValues?.recipient_name}
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  Address:
                </Text>
                <Text style={{ width: 200 }}>{orderValues?.recipient_place_name}</Text>
              </View>
              {orderValues?.recipient_edrpou === '1231524494' && (
                <>
                  <View
                    style={{
                      width: '100%',
                      flexDirection: 'row',
                    }}>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        width: 200,
                      }}>
                      Region:
                    </Text>
                    <Text style={{ width: 200 }}>524001850</Text>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      flexDirection: 'row',
                    }}>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        width: 200,
                      }}>
                      NIP:
                    </Text>
                    <Text style={{ width: 200 }}>1231524494</Text>
                  </View>
                </>
              )}
              <Text
                style={{
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  marginBottom: 3,
                  marginTop: 20,
                  width: '100%',
                }}>
                Sent to:
              </Text>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  Customer Name:
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  {orderValues.client_payer_name}
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  Student Name:
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  {orderValues.client_student_name}
                </Text>
              </View>
              <View
                style={{
                  fontWeight: 'bold',
                  width: '100%',
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  Address:
                </Text>
                <Text style={{ width: 200 }}>{orderValues.client_address}</Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                marginTop: 20,
                textAlign: 'center',
                fontWeight: 'bold',
              }}>
              <Text style={{ border: 1, width: 355, padding: 10 }}>Details</Text>
              <Text
                style={{
                  borderTop: 1,
                  borderRight: 1,
                  borderBottom: 1,
                  width: 190,
                  padding: '10px',
                }}>
                Amount
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 0,
                textAlign: 'center',
              }}>
              <Text
                style={{
                  borderRight: 1,
                  borderBottom: 1,
                  borderLeft: 1,
                  width: 355,
                  padding: 10,
                  textAlign: 'left',
                }}>
                {orderValues?.program_title || orderValues.program_name_for_docs}
              </Text>
              <Text
                style={{
                  borderRight: 1,
                  borderBottom: 1,
                  width: 190,
                  padding: '10px 2px',
                }}>
                {orderValues?.amount_payable_in_PLN
                  ? orderValues?.amount_payable_in_PLN_str
                  : '0'}
                {' PLN'}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 0,
                textAlign: 'center',
                marginBottom: 20,
                fontWeight: 'bold',
              }}>
              <Text
                style={{
                  width: 145,
                  padding: 2,
                }}></Text>
              <Text
                style={{
                  borderRight: 1,
                  borderBottom: 1,
                  borderLeft: 1,
                  width: 210,
                  padding: 2,
                  textAlign: 'right',
                }}>
                Total
              </Text>
              <Text
                style={{
                  borderRight: 1,
                  borderBottom: 1,
                  width: 190,
                  padding: '2px',
                }}>
                {orderValues?.amount_payable_in_PLN
                  ? orderValues?.amount_payable_in_PLN_str
                  : '0'}
                {' PLN'}
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontWeight: 'bold',
                  marginBottom: 5,
                }}>
                Please remit payments to:
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 2,
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  Beneficiary name:
                </Text>
                <Text
                  style={{
                    width: 200,
                  }}>
                  {orderValues?.recipient_name}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 2,
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  PLN IBAN:
                </Text>
                {/* <Text>{orderValues?.recipient_iban}</Text> */}
                <Text>PL18 1090 1870 0000 0001 5308 6451</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  {orderValues?.recipient_edrpou === '1231524494'
                    ? 'SWIFT:'
                    : 'SWIFT Code:'}
                </Text>
                <Text>{orderValues?.recipient_swift}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  Bank name:
                </Text>
                <Text>{orderValues?.recipient_bank}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View>
          <View>
            <View
              style={{
                alignItems: 'flex-end',
                marginBottom: 30,
              }}>
              <Text
                style={{
                  width: '200',
                  padding: '3',
                  borderTop: 1,
                  borderLeft: 1,
                  borderRight: 1,
                }}>
                {`Prepayment Invoice ${orderValues.order_number}`}
              </Text>
              <Text
                style={{
                  width: '200',
                  padding: '3',
                  border: 1,
                }}>
                {`Dated ${orderValues.date}`}
              </Text>
            </View>
            <View
              style={{
                width: 400,
                alignSelf: 'flex-end',
                flexDirection: 'column',
              }}>
              <Text
                style={{
                  textDecoration: 'underline',
                  marginBottom: 3,
                  width: '100%',
                }}>
                Sent by:
              </Text>
              <View
                style={{
                  fontWeight: 'bold',
                  width: '100%',
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  Company Name:
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  {orderValues?.recipient_name}
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  Address:
                </Text>
                <Text style={{ width: 200 }}>{orderValues?.recipient_place_name}</Text>
              </View>
              {orderValues?.recipient_edrpou === '1231524494' && (
                <>
                  <View
                    style={{
                      width: '100%',
                      flexDirection: 'row',
                    }}>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        width: 200,
                      }}>
                      Region:
                    </Text>
                    <Text style={{ width: 200 }}>524001850</Text>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      flexDirection: 'row',
                    }}>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        width: 200,
                      }}>
                      NIP:
                    </Text>
                    <Text style={{ width: 200 }}>1231524494</Text>
                  </View>
                </>
              )}
              <Text
                style={{
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  marginBottom: 3,
                  marginTop: 20,
                  width: '100%',
                }}>
                Sent to:
              </Text>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  Customer Name:
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  {orderValues.client_payer_name}
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  Student Name:
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  {orderValues.client_student_name}
                </Text>
              </View>
              <View
                style={{
                  fontWeight: 'bold',
                  width: '100%',
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: 200,
                  }}>
                  Address:
                </Text>
                <Text style={{ width: 200 }}>{orderValues.client_address}</Text>
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                marginTop: 20,
                textAlign: 'center',
                fontWeight: 'bold',
              }}>
              <Text style={{ border: 1, width: 355, padding: 10 }}>Details</Text>
              <Text
                style={{
                  borderTop: 1,
                  borderRight: 1,
                  borderBottom: 1,
                  width: 190,
                  padding: '10px',
                }}>
                Amount
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 0,
                textAlign: 'center',
              }}>
              <Text
                style={{
                  borderRight: 1,
                  borderBottom: 1,
                  borderLeft: 1,
                  width: 355,
                  padding: 10,
                  textAlign: 'left',
                }}>
                {orderValues?.program_title || orderValues.program_name_for_docs}
              </Text>
              <Text
                style={{
                  borderRight: 1,
                  borderBottom: 1,
                  width: 190,
                  padding: '10px 2px',
                }}>
                {orderValues?.amount_payable_str || 0} {orderValues.currency}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 0,
                textAlign: 'center',
                marginBottom: 20,
                fontWeight: 'bold',
              }}>
              <Text
                style={{
                  width: 145,
                  padding: 2,
                }}></Text>
              <Text
                style={{
                  borderRight: 1,
                  borderBottom: 1,
                  borderLeft: 1,
                  width: 210,
                  padding: 2,
                  textAlign: 'right',
                }}>
                Total
              </Text>
              <Text
                style={{
                  borderRight: 1,
                  borderBottom: 1,
                  width: 190,
                  padding: '2px',
                }}>
                {orderValues?.amount_payable_str || 0} {orderValues.currency}
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontWeight: 'bold',
                  marginBottom: 5,
                }}>
                Please remit payments to:
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 2,
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  Beneficiary name:
                </Text>
                <Text
                  style={{
                    width: 200,
                  }}>
                  {orderValues?.recipient_name}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 2,
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  EUR IBAN:
                </Text>
                <Text>{orderValues?.recipient_iban}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  {orderValues?.recipient_edrpou === '1231524494'
                    ? 'SWIFT:'
                    : 'SWIFT Code:'}
                </Text>
                <Text>{orderValues?.recipient_swift}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    width: 150,
                  }}>
                  Bank name:
                </Text>
                <Text>{orderValues?.recipient_bank}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default AccountWarszawaServices;
