// Models
import testModel from './components/Testhooks/model';

// All application modules list
import modules from './modules';

// Plugins
import { initStore } from './plugins/rematch';

// Create root store from models
const models = { testModel };

modules.forEach((module) => (models[module.name] = module.model));

// Init global redux storage from models
export const StoreWrapper = initStore(models);
