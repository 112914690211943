import React from 'react';

export default ({ Actions, AddUserButton }) => [
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
    ellipsis: true,
    width: '100px',
  },
  {
    title: 'Країна',
    dataIndex: 'country',
    key: 'country',
    // ellipsis: true,
  },
  {
    dataIndex: 'actions',
    key: 'actions',
    width: 130,
    render: (data, record) => <Actions record={record} />,
    title: () => <AddUserButton />,
  },
];
