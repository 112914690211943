import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// Utils
import { useHandleFields } from 'utils';

// Components
import { Input, Date, TextArea, Label } from 'components/Fields';

// Styled
import { Title } from '../styles';

// Redux
const getDataFromStore = ({ main: { abroad_education, main_id } }) => ({
  abroad_education,
  main_id,
});
const getMethodsFromStore = ({ main: { setState } }) => ({
  setState,
});

export default connect(
  getDataFromStore,
  getMethodsFromStore
)(({ abroad_education = {}, setState = () => {}, main_id = '' }) => {
  const {
    school = '',
    date = '',
    course = '',
    course_progression = '',
    city = '',
    details = '',
  } = abroad_education;
  const handleFields = useHandleFields(setState);

  return (
    <>
      <Title>ДОДАТКОВА ІНФОРМАЦІЯ ПРО ОСВІТУ ЗА КОРДОНОМ</Title>
      <hr />
      <Label margin="0">
        <Input
          disabled={main_id}
          addonBefore="Навчальний заклад"
          value={school}
          onChange={handleFields('abroad_education.school')}
        />
      </Label>

      <Label>
        <Date
          disabled={main_id}
          addonBefore="Дата початку навчання за кордоном"
          onChange={handleFields('abroad_education.date')}
          format={'DD-MM-YYYY'}
          defaultValue={date ? moment(date, 'DD-MM-YYYY') : ''}
        />
      </Label>

      <Label>
        <Input
          disabled={main_id}
          value={course}
          addonBefore="Назва освітнього курсу"
          onChange={handleFields('abroad_education.course')}
        />
      </Label>

      <Label>
        <Input
          disabled={main_id}
          value={course_progression}
          addonBefore="Прогресія після освітнього курсу"
          onChange={handleFields('abroad_education.course_progression')}
        />
      </Label>

      <Label>
        <Input
          disabled={main_id}
          value={city}
          addonBefore="Країна/місто освітнього курсу(автоматично)"
          onChange={handleFields('abroad_education.city')}
        />
      </Label>

      <Label
        text="Деталі додаткових послуг (якщо замовлені) - тип проживання, харчування, страховка,
        трансфер і т.і.">
        <TextArea
          disabled={main_id}
          value={details}
          onChange={handleFields('abroad_education.details')}
        />
      </Label>
    </>
  );
});
