import styled from 'styled-components';
export const Container = styled.div`
  width: 100%;
  padding: 0px 5%;
  margin-bottom: 10px;
  span.ant-steps-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > * {
    max-width: 800px;
    margin: 0 auto;
  }
`;
