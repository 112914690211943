import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { components as _components } from './AddProgramDatesModalComponents';

// Utils

// Components
import { Input, Text, InputPassword, Select, DatePicker } from 'ui';

// Styled
import { ModalContent } from '../ProgramDatesTable/styles';

const ComponentsList = {
  input: Input,
  select: Select,
  inputPassword: InputPassword,
  date: DatePicker,
};

/**
 * Component
 */
export const AddProgramDatesModalBody = connect(
  ({ adminProgramDates: { modalData, currentRecord }, users: { roles } }) => ({
    roles,
    modalData,
    currentRecord,
  }),
  ({ adminProgramDates: { setModalData } }) => ({ setModalData })
)(({ modalData = {}, roles = [], setModalData = () => {}, currentRecord = {} }) => {
  const [localData, setLocalData] = useState(modalData);

  const handleFields = (key) => (e) => {
    if (key === 'startDate' || key === 'finishDate') {
      setLocalData({
        ...localData,
        [key]: e?._isValid ? moment(e)?.format('YYYY-MM-DD') : '',
      });
    } else {
      setLocalData({ ...localData, [key]: e?.target ? e?.target?.value : e });
    }
  };
  console.log('localData', localData);
  const components = _components({ handleFields });
  const selectData = {
    roles,
  };
  return (
    <ModalContent onMouseLeave={() => setModalData({ ...modalData, ...localData })}>
      {components.map((comp, index) => {
        const Field = ComponentsList[comp.type];
        const additionalProps = {};

        if (comp.isHasItems) {
          additionalProps.items = (selectData[comp.listName] || []).map((fld, k) => (
            <option key={k} value={fld}>
              {fld}
            </option>
          ));
        }

        const { placeholder = '', type = '' } = comp;

        return (
          <div>
            <Text style={{ marginLeft: '10px' }}>
              {placeholder ? `${placeholder}:` : ''}
            </Text>
            {type === 'date' ? (
              <Field
                key={index}
                className={'Field'}
                {...comp.behaviours}
                placeholder={comp.placeholder}
                format={'YYYY-MM-DD'}
                value={
                  localData[comp.value] ? moment(localData[comp.value], 'YYYY-MM-DD') : ''
                }
                // defaultValue={
                //   modalData[comp.defaultValue] || currentRecord[comp.defaultValue]
                // }
                {...additionalProps}
              />
            ) : (
              <Field
                key={index}
                className={'Field'}
                {...comp.behaviours}
                placeholder={comp.placeholder}
                value={localData[comp.value]}
                defaultValue={
                  modalData[comp.defaultValue] || currentRecord[comp.defaultValue]
                }
                {...additionalProps}
              />
            )}
          </div>
        );
      })}
    </ModalContent>
  );
});

export default AddProgramDatesModalBody;
