import React from 'react';
import styled from 'styled-components';

// UI
import { Menu } from 'ui';

export const MenuItem = styled((p) => (
  <Menu type="item" {...p}>
    {p.children}
  </Menu>
))`
  display: flex;
  align-items: center;
  height: 50px !important;
  span {
    line-height: 15px !important;
    white-space: normal !important;
  }
`;

export const SideMenu = styled(Menu)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
  margin: 0;
  top: 0;
  min-height: calc(100vh - 80px);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 99;
  transition: left 0.5s ease;
  @media (max-height: 700px) {
    padding-top: 300px;
  }

  [class*='anticon-'] {
    font-size: 20px;
  }
  [class='ant-menu-submenu-arrow'] {
    opacity: 0;
  }
  [class='ant-menu-submenu-title'] {
    min-height: 80px;
    font-size: 11px !important;
  }

  .Logo {
    width: 40px;
    transform: rotate(-90deg);
  }

  .LogoText {
    font-weight: 700;
    font-size: 52px;
    color: #4556ac;
  }

  .settings {
    font-size: 30px;
    width: 130px;
  }

  a {
    width: 130px;
    padding: 0 40px;
  }
`;
