import React from 'react';

// Utils
import { isEmpty } from 'utils';

// UI
import { Button, Text, Title } from 'ui';

// Styled
import { RedText, Container } from './styles';

export default ({ _recipient = {}, _offer_agreement_link }) => {
  const {
    name = '',
    title = '',
    iban = '',
    bank = '',
    edrpou = '',
    details = '',
    // offer_agreement_link = '',
  } = _recipient;

  const isEmptyRecipient = isEmpty(_recipient);
  return (
    <Container>
      <Title>Виконавець:</Title>
      <hr />
      {isEmptyRecipient ? (
        <RedText>* Оберіть локацію продажу для показу виконавця</RedText>
      ) : (
        <Text>{name || title}</Text>
      )}
      <hr />
      <Title>Реквізити:</Title>
      <hr />

      {isEmptyRecipient ? (
        <RedText>* Оберіть локацію продажу для показу реквізитів</RedText>
      ) : (
        <Text>{`${
          name || title
        }, ${iban}, в ${bank}, код за ЄДРПОУ ${edrpou} ${details} `}</Text>
      )}

      <hr />
      {isEmptyRecipient ? (
        <RedText>
          * Оберіть локацію продажу для показу посилання на договір оферти
        </RedText>
      ) : (
        <Button type="link" href={_offer_agreement_link} target="_blank">
          договір оферти
        </Button>
      )}
      <hr />
    </Container>
  );
};
