import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Select } from 'antd';
import { useHandleFields } from '../../../../utils';

const { Option } = Select;

const getDataFromStore = ({ main: { client } }) => ({
  client,
});
const getMethodsFromStore = ({
  main: { searchCleintStudent, setState },
  adminOrders: { setState: adminSetState, getOrdersFromServer },
}) => ({
  adminSetState,
  getOrdersFromServer,
  setState,
  searchCleintStudent,
});

export const SearchStudent = connect(
  getDataFromStore,
  getMethodsFromStore
)(
  ({
    setState = () => {},
    getOrdersFromServer = () => {},
    adminSetState = () => {},
    searchCleintStudent = () => {},
    setIsOrder = () => {},
  }) => {
    const [data, setData] = useState([]);
    const handleFields = useHandleFields(adminSetState);
    const clientSearchFunc = async (val) => {
      if (val?.length > 3) {
        const searchRes = await searchCleintStudent({
          name: val,
        });
        if (searchRes) setIsOrder(true);
        searchRes && setData(searchRes);
      } else {
        setData([]);
      }
    };
    let timeout;
    const handleSearch = (val) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      timeout = setTimeout(clientSearchFunc(val), 500);
    };
    const handleChange = (val) => {
      const res = data.find((x) => x?.uid === val);
      handleFields('search.student_name')(res.name);
      setState({
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0,
        },
      });
      getOrdersFromServer();
      res &&
        setState((prev) => ({
          ...prev,
          client: {
            ...prev?.client,
            student_name: res?.name || '',
            birth_date: moment(res?.birth_date).format('DD-MM-YYYY') || '',
          },
        }));
    };
    const options = data?.map((d) => (
      <Option key={d?.uid}>{`${d?.name} || ${
        d?.birth_date ? 'д.н.: ' + moment(d?.birth_date).format('DD-MM-YYYY') || '' : ''
      }`}</Option>
    ));
    return (
      <>
        <Select
          showSearch
          placeholder={'Пошук (ПІБ)'}
          style={{ minWidth: 200, width: '100%' }}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={null}
          allowClear={true}
          onClear={() => setData([])}>
          {options}
        </Select>
      </>
    );
  }
);
export default SearchStudent;
