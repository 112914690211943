import React from 'react';
import ReactDOM from 'react-dom';

// Routes
import { Router } from './setupRouter';

// Store
import { StoreWrapper } from './setupStore';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <StoreWrapper>
      <Router />
    </StoreWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);
