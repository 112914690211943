import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import TimeNewRoman from './fonts/Times_New_Roman.ttf';
import TimeNewRoman_bold from './fonts/Times_New_Roman_Bold.ttf';
import { TransformNumberInWords } from '../../services/NumbersInWords';

Font.register({
  family: 'TimeNewRoman',
  src: TimeNewRoman,
});
Font.register({ family: 'TimeNewRoman_bold', src: TimeNewRoman_bold });
const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingLeft: 30,
    backgroundColor: '#fff',
    fontSize: 11,
    fontFamily: 'TimeNewRoman',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  border: {
    border: 1,
    padding: '10px 5px',
    position: 'relative',
  },
  p_absolute: {
    position: 'absolute',
    top: 50,
    right: 20,
    flexDirection: 'colum',
    alignItems: 'center',
  },
  m_b: {
    marginBottom: 5,
  },
  title: {
    fontSize: 13,
    marginTop: 15,
    marginBottom: 15,
    padding: '2px 5px',
    borderBottom: 1,
    fontFamily: 'TimeNewRoman_bold',
  },
  text_left: {
    minWidth: 100,
    textAlign: 'left',
    marginRight: 10,
  },
  center_block: {
    minWidth: 500,
  },
  row_mb: {
    flexDirection: 'row',
    marginBottom: 10,
  },
});
export const Account = (props) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View>
        <Text style={{ width: '70%', border: 1, marginBottom: 20 }}></Text>
        <Text style={styles.m_b}>
          Замовлення №{' '}
          {props.orderValues.order_id || props.orderValues.unique_order_number || ''}
          {' від '}
          {props.orderValues.date_create || ''} року
        </Text>
        <Text style={{ fontFamily: 'TimeNewRoman_bold', marginBottom: 50 }}>
          Виконавець:{' '}
          {(props.orderValues.recipient || {}).title ||
            ((props.orderValues.selectedValues || {}).recipient || {}).title ||
            ''}
        </Text>
        <Text style={styles.m_b}>
          Замовник: {(props.orderValues.client || {}).payer_name || ''}
        </Text>
        <Text style={styles.m_b}>
          IПН Замовника: {(props.orderValues.client || {}).payer_tax_ID || ''}
        </Text>
        <Text style={styles.m_b}>
          Паспорт: {(props.orderValues.client || {}).passport || ''}
        </Text>
        <Text style={styles.m_b}>
          Дата народження:{' '}
          {((props.orderValues.client || {}).birth_date || '')
            .split('-')
            .reverse()
            .join('.')}
        </Text>
        <Text style={styles.m_b}>
          Клас/курс: {(props.orderValues.client || {}).now_class_course || ''}
        </Text>
        <Text style={styles.m_b}>
          Графік оплат: &nbsp;
          {(props.orderValues.shedule_payments_fields || [])
            .map((cnt, num) => {
              const ndate = (
                (props.orderValues.selectedValues || {})[
                  `payment_schedule_date_${num}`
                ] || ''
              )
                .split('-')
                .reverse()
                .join('.');

              const nsumm = (
                (props.orderValues.selectedValues || {})[`payment_schedule_${num}`] || ''
              )
                .split('-')
                .reverse()
                .join('.');

              return (props.orderValues.selectedValues || {})[
                `payment_schedule_date_${num}`
              ]
                ? `дата: ${ndate}, сума: ${nsumm}`
                : '';
            })
            .join('. ')}
        </Text>
        <Text style={styles.m_b}>
          Студент: {(props.orderValues.client || {}).student_name || ''}
        </Text>
        <Text style={styles.m_b}>
          Дата початку програми:{' '}
          {props.orderValues.program_start_date ||
            (props.orderValues.selectedValues || {}).program_start_date ||
            ''}
        </Text>
        <Text style={styles.m_b}>
          Дата закінчення програми:{' '}
          {props.orderValues.program_end_date ||
            (props.orderValues.selectedValues || {}).program_end_date ||
            ''}
        </Text>
        {props.orderValues.abroad_education &&
        (props.orderValues.abroad_education.school ||
          props.orderValues.abroad_education.date ||
          props.orderValues.abroad_education.course ||
          props.orderValues.abroad_education.course_progression ||
          props.orderValues.abroad_education.city ||
          props.orderValues.abroad_education.details) ? (
          <View>
            <Text style={{ marginTop: 20, textAlign: 'center', marginBottom: 10 }}>
              ДОДАТКОВА ІНФОРМАЦІЯ ПРО ОСВІТУ ЗА КОРДОНОМ
            </Text>
            {props.orderValues.abroad_education.school !== '' ? (
              <Text style={styles.m_b}>
                Навчальний заклад: {props.orderValues.abroad_education.school || ''}
              </Text>
            ) : null}
            {props.orderValues.abroad_education.date ? (
              <Text style={styles.m_b}>
                Дата початку навчання за кордоном:{' '}
                {props.orderValues.abroad_education.date || ''} року
              </Text>
            ) : null}
            {props.orderValues.abroad_education.course !== '' ? (
              <Text style={styles.m_b}>
                Назва освітнього курсу: {props.orderValues.abroad_education.course || ''}
              </Text>
            ) : null}
            {props.orderValues.abroad_education.course_progression !== '' ? (
              <Text style={styles.m_b}>
                Прогресія після освітнього курсу:{' '}
                {props.orderValues.abroad_education.course_progression || ''}
              </Text>
            ) : null}
            {props.orderValues.abroad_education.details !== '' ? (
              <Text style={styles.m_b}>
                Деталі додаткових послуг:{' '}
                {props.orderValues.abroad_education.details || ''}
              </Text>
            ) : null}
          </View>
        ) : null}
        {props.orderValues.ukraine_education &&
        (props.orderValues.ukraine_education.attraction_channel ||
          props.orderValues.ukraine_education.english_level ||
          props.orderValues.ukraine_education.education_grup) ? (
          <View>
            <Text style={{ marginTop: 20, textAlign: 'center', marginBottom: 10 }}>
              ДОДАТКОВА ІНФОРМАЦІЯ ПРО НАВЧАННЯ В УКРАЇНІ
            </Text>
            {props.orderValues.ukraine_education.education_grup ? (
              <Text style={styles.m_b}>
                Номер групи навчання:{' '}
                {props.orderValues.ukraine_education.education_grup || ''}
              </Text>
            ) : null}
            {props.orderValues.ukraine_education.english_level !== '' ? (
              <Text style={styles.m_b}>
                Рівень английскої на сьогодні:{' '}
                {props.orderValues.ukraine_education.english_level || ''}
              </Text>
            ) : null}
            {/* {props.orderValues.ukraine_education.attraction_channel !== '' ? (
              <Text style={styles.m_b}>
                Канал залучення:{' '}
                {props.orderValues.ukraine_education.attraction_channel}
              </Text>
            ) : null} */}
          </View>
        ) : null}
        <View
          style={{
            flexDirection: 'row',
            marginTop: 20,
            textAlign: 'center',
            fontSize: 9,
          }}>
          <Text style={{ border: 1, width: 30, padding: 3 }}>№</Text>
          <Text style={{ border: 1, width: 220, padding: 3, borderLeft: 0 }}>
            Номенклатура
          </Text>
          <Text style={{ border: 1, width: 40, padding: 3, borderLeft: 0 }}>Кіл-сть</Text>
          <Text style={{ border: 1, width: 50, padding: 3, borderLeft: 0 }}>
            Од. вим.
          </Text>
          <Text style={{ border: 1, width: 55, padding: 3, borderLeft: 0 }}>
            Вартість
          </Text>
          <Text style={{ border: 1, width: 80, padding: 3, borderLeft: 0 }}>
            Cума знижки
          </Text>
          <Text style={{ border: 1, width: 70, padding: 3, borderLeft: 0 }}>
            Сума до сплати
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginTop: 0,
            textAlign: 'center',
            marginBottom: 20,
            fontSize: 9,
          }}>
          <Text style={{ border: 1, width: 30, padding: 3, borderTop: 0 }}>1</Text>
          <Text
            style={{
              border: 1,
              width: 220,
              padding: 3,
              borderLeft: 0,
              borderTop: 0,
              textAlign: 'left',
            }}>
            {(props.orderValues.programName || {}).program_name_for_docs ||
              ((props.orderValues.selectedValues || {}).programName || {})
                .program_name_for_docs ||
              ''}
          </Text>
          <Text
            style={{
              border: 1,
              width: 40,
              padding: 3,
              borderLeft: 0,
              borderTop: 0,
            }}>
            1
          </Text>
          <Text
            style={{
              border: 1,
              width: 50,
              padding: 3,
              borderLeft: 0,
              borderTop: 0,
            }}>
            послуга
          </Text>
          <Text
            style={{
              border: 1,
              width: 55,
              padding: 3,
              borderLeft: 0,
              borderTop: 0,
            }}>
            {parseInt(
              (
                (props.orderValues.programName || {}).full_price ||
                ((props.orderValues.selectedValues || {}).programName || {}).full_price ||
                ''
              ).split('.')[0]
            )
              .toLocaleString()
              .replace(/,|\.|-/g, ' ')}
            .00
          </Text>
          <Text
            style={{
              border: 1,
              width: 80,
              padding: 3,
              borderLeft: 0,
              borderTop: 0,
            }}>
            {(
              parseInt(
                (
                  (props.orderValues.programName || {}).full_price ||
                  ((props.orderValues.selectedValues || {}).programName || {})
                    .full_price ||
                  ''
                ).split('.')[0]
              ) -
              parseInt(
                (
                  (props.orderValues.price_wiht_discount ||
                    (props.orderValues.selectedValues || {}).price_wiht_discount ||
                    0) + ''
                ).split('.')[0]
              )
            )
              .toLocaleString()
              .replace(/,|\.|-/g, ' ')}
            .00
          </Text>
          <Text
            style={{
              border: 1,
              width: 70,
              padding: 3,
              borderLeft: 0,
              borderTop: 0,
            }}>
            {parseInt(
              (
                (props.orderValues.price_wiht_discount ||
                  (props.orderValues.selectedValues || {}).price_wiht_discount ||
                  0) + ''
              ).split('.')[0]
            )
              .toLocaleString()
              .replace(/,|\.|-/g, ' ')}
            .00
          </Text>
        </View>
        <Text
          style={{
            width: '100%',
            textAlign: 'right',
            fontFamily: 'TimeNewRoman_bold',
          }}>
          Разом:{'       '}
          {parseInt(
            (
              (props.orderValues.price_wiht_discount ||
                (props.orderValues.selectedValues || {}).price_wiht_discount ||
                0) + ''
            ).split('.')[0]
          )
            .toLocaleString()
            .replace(/,|\.|-/g, ' ')}
          .00
        </Text>
        <Text
          style={{
            width: 350,
          }}>
          Всього найменувань 1, на суму{' '}
          {parseInt(
            (
              (props.orderValues.price_wiht_discount ||
                (props.orderValues.selectedValues || {}).price_wiht_discount ||
                0) + ''
            ).split('.')[0]
          )
            .toLocaleString()
            .replace(/,|\.|-/g, ' ')}
          .00{' '}
          {(
            props.orderValues.programName ||
            (props.orderValues.selectedValues || {}).programName ||
            {}
          ).currency === 'EUR'
            ? 'євро'
            : (
                props.orderValues.programName ||
                (props.orderValues.selectedValues || {}).programName ||
                {}
              ).currency === 'USD'
            ? 'доларів США'
            : 'гривень'}
          .{'\n'}
          {new TransformNumberInWords(
            props.orderValues.price_wiht_discount ||
              (props.orderValues.selectedValues || {}).price_wiht_discount,
            (
              props.orderValues.programName ||
              (props.orderValues.selectedValues || {}).programName ||
              {}
            ).currency || 'UAH'
          ).transform()}
          {'.'}
        </Text>
        <Text
          style={{
            fontFamily: 'TimeNewRoman_bold',
            fontSize: '9.5',
            marginBottom: 20,
            marginTop: 5,
          }}>
          Вартість послуг Виконавця сплачується Замовником у гривневому еквіваленті
          відповідно до комерційного курсу банківської установи України, яка зазначена у
          виставленому Виконавцем рахунку на оплату.
        </Text>
        {/* <Text
          style={{
            width: '100%',
            border: 1,
            borderColor: '#000',
            backgroundColor: '#000',
            marginBottom: 20,
          }}
        ></Text>
        <Text style={{ fontFamily: 'TimeNewRoman_bold', marginBottom: 30 }}>
          Виконавець: {(props.orderValues.selectedValues || {}).recipient.title}
          {'  '}__________________
        </Text>
        <Text style={{ fontFamily: 'TimeNewRoman_bold', marginBottom: 30 }}>
          Замовник: {(props.orderValues.client || {}).payer_name}
          {'  '}__________________
        </Text> */}
      </View>
    </Page>
  </Document>
);
export default Account;
