import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Select, Radio } from 'antd';

const searchField = [
  { label: 'ПІБ', value: 'name' },
  { label: 'телефон', value: 'tel' },
  { label: 'Email', value: 'email' },
  { label: 'ІНН', value: 'inn' },
];
const { Option } = Select;

const getDataFromStore = ({ main: { client } }) => ({
  client,
});
const getMethodsFromStore = ({ main: { setState, searchCleintPayer } }) => ({
  setState,
  searchCleintPayer,
});

export const SearchPayer = connect(
  getDataFromStore,
  getMethodsFromStore
)(({ client = {}, setState = () => {}, searchCleintPayer = () => {}, ...props }) => {
  const [data, setData] = useState([]);
  const [valueSField, setValueSField] = useState(searchField?.[0]?.value);
  const clientSearchFunc = async (val) => {
    if (
      ((valueSField === 'name' || valueSField === 'student_name') && val?.length > 3) ||
      val?.length > 5
    ) {
      const searchRes = await searchCleintPayer({
        name: '',
        inn: '',
        tel: '',
        email: '',
        [valueSField]: val,
      });
      searchRes && setData(searchRes);
    } else {
      setData([]);
    }
  };
  let timeout;
  const handleSearch = (val) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(clientSearchFunc(val), 500);
  };
  const handleChange = (val) => {
    const res = data.find((x) => x?.uid === val);
    res &&
      setState((prev) => ({
        ...prev,
        client: {
          ...prev?.client,
          payer_name: res?.name || '',
          passport: res?.passport || '',
          payer_tax_ID: res?.payer_tax_ID || '',
          contact_phone: res?.tel || '',
        },
      }));
  };
  const options = data?.map((d) => (
    <Option key={d?.uid}>{`${d?.name} || ${d?.tel ? 'tel: ' + d?.tel : ''} ${
      d?.payer_tax_ID ? 'inn: ' + d?.payer_tax_ID : ''
    } ${d?.passport ? 'p: ' + d?.passport : ''}`}</Option>
  ));
  return (
    <>
      <Radio.Group
        options={searchField}
        onChange={(e) => {
          setValueSField(e?.target?.value);
        }}
        value={valueSField}
      />
      <br />
      <br />

      <Select
        showSearch
        // value={value}
        placeholder={'Пошук'}
        style={{ minWidth: 200, width: '100%' }}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={null}
        allowClear={true}
        onClear={() => setData([])}>
        {options}
      </Select>
    </>
  );
});
export default SearchPayer;
