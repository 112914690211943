import requests from './requests';
import { Notification } from 'ui';
import { setState, applyResetState, handleRequestError, RequestError } from 'utils';
import { userData, userToken } from '../services/localForage';
import stores from 'plugins/localForage';
import getFingerPrint from 'plugins/fingerprint';
import initialState from './initialState';

export const model = {
  state: initialState,
  reducers: {
    resetState(state, payload) {
      const { roles = [] } = state;
      return { ...initialState, roles };
    },
    setState,
  },
  effects: (dispatch) => ({
    async getProfileFromStorage() {
      const mainStore = stores.get('main');
      const token = (await mainStore.getItem('token')) || '';
      const userData = (await mainStore.getItem('userData')) || {};
      const newState = { token, userData };

      dispatch.users.setState(newState);

      return newState;
    },

    async getProfileInfo(payload, rootState) {
      const _id = rootState?.users?.userData?._id || '';
      const params = { _id };

      try {
        const result = await requests.getProfileInfo(params);
        const data = result || rootState?.users?.userData || {};

        dispatch.users.setState({ userData: data });
        userData.set = data;

        const userID = data?._id;
        dispatch.main.setState((state) => ({
          selectedValues: { ...state.selectedValues, manager: userID },
        }));
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.users.getProfileInfo,
          params,
        });
      }
    },

    async getRoles(payload, rootState) {
      try {
        const result = await requests.getRoles();

        dispatch.users.setState({ roles: result });
      } catch (error) {
        handleRequestError({ error, dispatch, retry: dispatch.users.getRoles });
      }
    },

    async editProfileInfo(params = {}, rootState) {
      const requestBody = {
        ...(rootState?.users?.userData || {}),
        ...params,
      };

      try {
        const result = await requests.editProfileInfo(requestBody);

        await dispatch.users.getProfileInfo();
        return result;
      } catch (error) {
        handleRequestError({
          error,
          dispatch,
          retry: dispatch.users.editProfileInfo,
          params: requestBody,
        });
      }
    },

    async loginIn(data, rootState = {}) {
      try {
        const history = rootState?._app?.history || {};
        const historyPush = history?.push || (() => {});
        const fingerPrint = getFingerPrint();

        dispatch.users.setState({
          loading: true,
        });

        const result = await requests.loginIn({
          ...data,
          fingerPrint,
        });
        const { accessToken = null, info = {} } = result;

        userToken.set = accessToken;
        userData.set = info;

        dispatch.users.setState({
          token: accessToken,
          userData: info,
          loading: false,
        });

        if (accessToken) {
          historyPush('/admin/createOrder');
        }
      } catch (error) {
        Notification.error({
          message: 'Помилка входу',
          description: error.message,
        });

        dispatch.users.setState({
          loading: false,
        });
      }
    },

    async refreshToken(params = {}, rootState) {
      try {
        const stateToken = rootState?.users?.token || '';
        const fingerPrint = getFingerPrint();
        const response = await requests.refreshToken({
          fingerPrint,
          accessToken: stateToken,
        });

        const token = response?.accessToken || '';
        const { info = {} } = response;

        if (!token) throw new RequestError(response);

        userData.set = info;
        userToken.set = token;
        dispatch.users.setState({ token });

        return token;
      } catch (error) {
        console.log(error.message);

        Notification.error({
          message: 'Не вдалося оновити сесію.',
          description: `Потрібно повторно увійти.`,
        });

        return await dispatch.users.logOut();
      }
    },

    async logOut(payload, rootState) {
      userData.set = null;
      userToken.set = null;

      const history = rootState?._app?.history || {};
      const { push = () => {} } = history;

      applyResetState(dispatch);

      push('/');
    },
  }),
};

export default model;
