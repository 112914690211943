export default {
  // step: 3,
  // selectedValues: {
  //   programType: 'Літні навчальні програми',
  //   programName: 'Summer Time program',
  //   paymant: 'Оплата на безготівковий рахунок',
  //   location: 'Академія Варшавський Квартал',
  //   educationLocation: 'Академія Варшавський Квартал',
  //   offer_agreement: '',
  //   program_start_date: '11-09-2020',
  //   program_end_date: '18-09-2020',
  //   discount: 200,
  //   amount_payable: 3750,
  //   conversion_rate: 1,
  //   payment_schedule: '',
  //   price_with_discount: 9800,
  //   amount_payable_in_UAH: '3750',
  //   date_create: '',
  //   time_create: '',
  //   unique_order_number: '',
  //   manager: 'test',
  //   link_AMO_CRM: 'https://international.study.ua/pdf/zdn/summertimetrm.pdf',
  //   special_wishes: '', // => wishes
  //   // special_wishes: {
  //   //   add_wish: '',
  //   //   change_wish: '',
  //   //   remove_wish: '',
  //   // },
  //   recipient: {
  //     _id: '5f2ada479d6fa95cfe804684',
  //     name: 'Товариство з обмеженою відповідальністю "СТАДІ ЕКЕДЕМІ ОНЛАЙН"',
  //     edrpou: '43272040',
  //     bank: 'АТ «УКРСИББАНК», Київ',
  //     iban: 'UA683510050000026001878972465',
  //     details: 'Є платником єдиного податку на 3-ій групі',
  //     director: 'Задніпряна Юлія Олександрівна',
  //     signature_link: 'у Петровой в обработке',
  //     type: 'ТОВ',
  //     // ---------------------
  //     // _id: '5f2ada479d6fa95cfe804684',
  //     // name: 'ФОП Остапенко Вікторія Олександрівна',
  //     // edrpou: '2840121425',
  //     // bank: 'ФIЛIЯ "РОЗРАХ.ЦЕНТР" АТ КБ"ПРИВАТБАНК"',
  //     // iban: 'UA683510050000026001878972465',
  //     // details: 'Є платником єдиного податку на 3-ій групі',
  //     // director: '',
  //     // signature_link: 'у Петровой в обработке',
  //     // type: 'ФОП',
  //   },
  // },
  // client: {
  //   payer_name: 'Петренко Петро Петрович',
  //   student_name: 'Петренко Іван Петрович',
  //   birth_date: '04-09-2020',
  //   payer_tax_ID: 21345678910,
  //   address: 'адреса',
  //   passport: 'НВ 224318',
  //   contact_phone: '098-33-21-333',
  //   now_class_course: 1,
  // },
  // programInfo: {
  //   _id: '5f2993b29d6fa95cfeb0daa1',
  //   global_id: '324',
  //   program_type: 'Літні навчальні програми',
  //   nomenclature_1s: 'Summer Time program',
  //   code: 'НФ-00000413',
  //   full_price: '10000',
  //   currency: 'UAH',
  //   full_price_prepay: '3750',
  //   program_name_for_docs:
  //     'Summer Time program - 3-місячний курс англомовної підтримки для школярів',
  //   payment_type: 'Оплата картою через термінал',
  //   location: 'Академія Варшавський Квартал',
  //   recipient: 'ФОП Задніпряна Юлія Олександрівна',
  //   edrpou: '3397707566',
  //   offer_agreement_link:
  //     'https://international.study.ua/pdf/zdn/summertimetrm.pdf',
  // },
  /////////////////////////////////////
  loading: false,
  step: 1,
  selectedValues: {
    programType: null,
    programName: null,
    paymant: null,
    location: null,
    educationLocation: null,
    recipient: {},
    offer_agreement: '',
    program_start_date: '',
    program_end_date: '',
    discount: 0,
    amount_payable: 0,
    conversion_rate: 1,
    payment_schedule: '',
    price_with_discount: '',
    amount_payable_in_UAH: '',
    date_create: '',
    time_create: '',
    unique_order_number: '',
    manager: '',
    link_AMO_CRM: '',
    special_wishes: '',
    need_to_send_mail: false,
    programInfo: {},
    attraction_channel: '',
    dop_country: null,
    dop_school: null,
  },
  mailPDFSendSuccess: false,
  client: {},
  // ----------------
  order: {},

  ukraine_education: {},
  abroad_education: {},

  rateUSD: [],
  rateEUR: [],
  //-----------------------
  program_types: [],
  program_names: [],
  paymant: [],
  locations: [],
  managers: [],
  shedule_payments_fields: [0],
  country_list: [],
  school_list: [],
  orderCopy: false,
  surcharge: false,
  createOrderPDF: false,
  createAccountPDF: false,
  document_create: false,

  sellChannels: [
    { id: 'TMK', title: 'Маркетинг (ТМК)' },
    { id: 'Visit', title: 'Візит в офіс (Visit)' },
    { id: 'Renew', title: 'Продовження (Renew)' },
    { id: 'Ref', title: 'Рекомендація (Ref)' },
    { id: 'Old', title: 'Клієнт із бази (Old)' },
  ],
  englishLevels: [
    { id: 1, title: 'Beginner' },
    { id: 2, title: 'Elementary low' },
    { id: 3, title: 'Elementary' },
    { id: 4, title: 'Elementary strong' },
    { id: 5, title: 'Pre-Intermediate low' },
    { id: 6, title: 'Pre-Intermediate' },
    { id: 7, title: 'Pre-Intermediate strong' },
    { id: 8, title: 'Intermediate low' },
    { id: 9, title: 'Intermediate' },
    { id: 10, title: 'Intermediate plus' },
    { id: 11, title: 'Upper-Intermediate' },
    { id: 12, title: 'Upper-Intermediate plus' },
    { id: 13, title: 'Advanced' },
    { id: 14, title: 'Advanced Plus' },
    { id: 15, title: 'Proficiency' },
  ],
  history: {},
};
