import React from 'react';

// UI
import { Select } from '~/ui';

// Plugins
import { connect } from '~/plugins/redux';
import { useEffect } from 'react';

// Model data
const getStateFromModel = ({ catalogs: { managers } }) => ({ managers });
const getMethodsFromModel = ({ catalogs: { getManagersFromServer } }) => ({
  getManagersFromServer,
});

export const ManagersSelect = connect(
  getStateFromModel,
  getMethodsFromModel
)(
  // props
  ({ managers = [], getManagersFromServer = () => {}, ...props }) => {
    useEffect(() => {
      getManagersFromServer({});
      // eslint-disable-next-line
    }, []);

    return (
      <Select
        mode="multiple"
        aria-label="Small"
        name="manager"
        defaultValue={'не выбрано...'}
        style={{ width: '40%' }}
        {...props}>
        {[{ name: 'не выбрано...' }]
          .concat(managers)
          .filter((pn) => pn.name)
          .map((manager, key) => (
            <option key={key} value={manager.name}>
              {manager.name}
            </option>
          ))}
      </Select>
    );
  }
);

// {...this.showOnlyExist('array', this.state.selectedValues.prname)}
//     onChange={this.handleChangeProgramName}
//     disabled={this.state.step > 1 ? false : true}
