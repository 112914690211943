import styled from 'styled-components';

export const select = (Select) =>
  styled(Select).attrs({ bordered: false })`
    flex: 1 1 auto;
  `;

export const datePicker = (DatePicker) =>
  styled(DatePicker).attrs({ bordered: false })`
    flex: 1 1 auto;
  `;

export const inputNumber = (InputNumber) => styled(InputNumber)`
  flex: 1 1 auto;
  [class='ant-input-number-input-wrap'] {
    height: ${({ height }) => (height ? height : 'auto')};
  }
`;

export const textArea = (TextArea) => styled(TextArea)``;
export const checkbox = (Checkbox) => styled(Checkbox)`
  flex: 1 1 auto;
  [class='ant-input-number-input-wrap'] {
    height: ${({ height }) => (height ? height : 'auto')};
  }
`;
