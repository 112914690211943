import { program_dates } from '../services/request';
import { RequestError } from 'utils';

export const requests = {
  async getAllProgramDates(payload) {
    const response = await program_dates.get('', true, payload);
    if (response.message || response.error) throw new RequestError(response);
    return response;
  },
  async addProgramDatesToServer(payload) {
    const response = await program_dates.post('', true, payload);

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },
  async editProgramDatesToServer(payload) {
    const response = await program_dates.patch(``, true, payload);

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },
  async removeProgramDatesFromServer(_id) {
    const response = await program_dates.delete(_id, true, {});

    if (response.message || response.error) throw new RequestError(response);

    return response;
  },
};

export default requests;
