import { orders } from '../services/request';
import { RequestError } from 'utils';

/**
 * Get Orders
 */
export const getOrdersFromServer = async (params = {}) => {
  let requestBody = {
    page: 1,
    // limit: 100,
    sort: {},
    ...params,
  };

  const { page, limit, sort, query } = requestBody;

  // settings
  const _sort = sort.columnKey ? sort.columnKey : '';
  const _order = sort.columnKey ? (sort.order ? sort.order : 'ascend') : '';
  const _query = query || '';

  let response;

  if (true) {
    response = await orders.post('getorders', true, requestBody);
  } else {
    response = await orders.get(
      `?page=${page}&limit=${limit}&sort=${_sort}&order=${_order}&q=${_query}`,
      true
    );
  }

  if (response.message || response.error) throw new RequestError(response);

  return response || [];
};

/**
 * Add Bill
 */
export const addBill = async (params = {}) => {
  let requestBody = {
    ...params,
  };
  let response;
  response = await orders.post('addbill', true, requestBody);
  return response;
};

export const deleteOrder = async (params = {}) => {
  let response;
  response = await orders.patch('deleteorder/' + params?.id, true, {});
  return response;
};

export const freezOrder = async (params = {}) => {
  let requestBody = {
    ...params,
  };
  let response;
  response = await orders.patch('freez/create', true, requestBody);
  return response;
};

export const deleteFreezOrder = async (params = {}) => {
  let requestBody = {
    ...params,
  };
  let response;
  response = await orders.patch('freez/delete', true, requestBody);
  return response;
};
