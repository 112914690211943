import React from 'react';
import { Page, Text, View, Document, StyleSheet, Link, Font } from '@react-pdf/renderer';

import LiberationSerifReg from './fonts/LiberationSerif-Regular.ttf';
import LiberationSerifBold from './fonts/LiberationSerif-Bold.ttf';
import LiberationSerifItalick from './fonts/LiberationSerif-Italic.ttf';

import { TransformNumberInWords } from 'services/NumbersInWords';
import { trasformData } from 'utils/restructuringOrderData';

Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifReg,
});
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifBold,
  fontWeight: 'bold',
});
Font.register({
  family: 'LiberationSerif',
  src: LiberationSerifItalick,
  fontStyle: 'italic',
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingLeft: 30,
    backgroundColor: '#fff',
    fontSize: 10,
    fontFamily: 'LiberationSerif',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  border: {
    border: 1,
    padding: '10px 5px',
    position: 'relative',
  },
  p_absolute: {
    position: 'absolute',
    top: 50,
    right: 20,
    flexDirection: 'colum',
    alignItems: 'center',
  },
  m_b: {
    marginBottom: 5,
  },
  title: {
    fontSize: 13,
    marginTop: 15,
    marginBottom: 15,
    padding: '2px 5px',
    borderBottom: 1,
  },
  text_left: {
    minWidth: 100,
    textAlign: 'left',
    marginRight: 10,
  },
  center_block: {
    minWidth: 500,
  },
  row_mb: {
    flexDirection: 'row',
    marginBottom: 10,
  },
});

export const Order = (props) => {
  const orderValues = trasformData(props?.orderValues, props?.bill);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View>
            {orderValues.payment !== 'Оплата готівкою' ? (
              <View>
                <View style={{ fontSize: 12 }}>
                  <Text
                    style={{
                      marginBottom: 10,
                      fontWeight: 'bold',
                    }}>
                    ШАНОВНИЙ КЛІЄНТЕ!
                  </Text>
                  <Text
                    style={{
                      marginBottom: 20,
                    }}>
                    ОБОВ’ЯЗКОВО ВКАЖІТЬ НАЗВУ ПЛАТНИКА ТА НОМЕР ЗАМОВЛЕННЯ В ПРИЗНАЧЕННІ
                    ПЛАТЕЖУ. БЕЗ ЗАЗНАЧЕНИХ РЕКВІЗИТІВ ВАША ОПЛАТА БУДЕ ВВАЖАТИСЯ
                    НЕВІДОМОЮ.
                  </Text>
                </View>
                <View style={styles.border}>
                  <Text>
                    Одержувач:{'   '}
                    {orderValues.recipient_name}
                  </Text>
                  <View style={styles.row}>
                    <Text> Код ЄДРПОУ</Text>
                    <Text style={styles.border}>{orderValues.recipient_edrpou}</Text>
                  </View>
                  <Text style={styles.m_b}>Банк одержувача</Text>
                  <Text style={styles.m_b}>{orderValues.recipient_bank}</Text>
                  <Text>
                    Призначення платежу: {orderValues.program_name_for_docs} зг. рахунку
                    на оплату замовлення №{orderValues.order_num} від {orderValues.date}{' '}
                    року, без ПДВ
                  </Text>
                  <Text style={{ position: 'absolute', top: 30, right: 70 }}>
                    КРЕДИТ рах. N
                  </Text>
                  <Text
                    style={{
                      position: 'absolute',
                      top: 50,
                      right: 10,
                      border: 1,
                      padding: '5px 10px',
                    }}>
                    {orderValues.recipient_iban}
                  </Text>
                </View>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View>
            <Text style={styles.title}>
              Рахунок на оплату замовлення № {orderValues.order_num} від{' '}
              {orderValues.date} року
            </Text>
          </View>

          <View style={styles.row_mb}>
            <Text style={styles.text_left}>Отримувач:</Text>
            <View>
              <Text>{orderValues.recipient_name}</Text>
              <View>
                {orderValues.payment !== 'Оплата готівкою' ? (
                  <View>
                    <Text>
                      {'IBAN '}
                      {orderValues.recipient_iban}, в {orderValues.recipient_bank}
                    </Text>
                    <Text>код за ЄДРПОУ {orderValues.recipient_edrpou}</Text>
                    <Text>{orderValues.recipient_details}</Text>
                    <Text></Text>
                  </View>
                ) : (
                  <Text></Text>
                )}
              </View>
            </View>
            <Text></Text>
          </View>

          <View style={styles.row_mb}>
            <Text style={styles.text_left}>Замовник:</Text>
            <View style={{ fontWeight: 'bold' }}>
              <Text>Фізична особа:</Text>
              <Text>{orderValues.client_payer_name}</Text>
            </View>
            <Text></Text>
          </View>

          {orderValues?.offer_agreement_link !== '' ? (
            <View style={styles.row_mb}>
              <Text style={styles.text_left}>Договір:</Text>
              <View>
                <Link src={orderValues.offer_agreement_link}>
                  {orderValues.offer_agreement_link}
                </Link>
              </View>
              <Text></Text>
            </View>
          ) : (
            <Text></Text>
          )}
          {orderValues.payment === 'Оплата картою через Fondy' ? (
            <View>
              {orderValues.fondy_link ? (
                <Text
                  style={{
                    marginTop: 15,
                    textAlign: 'right',
                  }}>
                  Посилання для оплати через Fondy:{' '}
                  <Link src={orderValues.fondy_link}>{orderValues.fondy_link}</Link>
                </Text>
              ) : (
                <Text></Text>
              )}
            </View>
          ) : (
            <></>
          )}
          <View
            style={{
              flexDirection: 'row',
              marginTop: 20,
              textAlign: 'center',
              fontWeight: 'bold',
            }}>
            <Text style={{ border: 1, width: 30, padding: 10 }}>№</Text>
            <Text style={{ border: 1, width: 300, padding: 10, borderLeft: 0 }}>
              Номенклатура
            </Text>
            <Text
              style={{
                border: 1,
                width: 40,
                padding: '3px 7px',
                borderLeft: 0,
              }}>
              Кіл- сть
            </Text>
            <Text
              style={{
                border: 1,
                width: 55,
                padding: '3px 10px',
                borderLeft: 0,
              }}>
              Од. вим.
            </Text>
            <Text
              style={{
                border: 1,
                width: 60,
                padding: '3px 7px',
                borderLeft: 0,
              }}>
              Ціна без ПДВ
            </Text>
            <Text
              style={{
                border: 1,
                width: 60,
                padding: '3px 7px',
                borderLeft: 0,
              }}>
              Сума без ПДВ
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 0,
              textAlign: 'center',
              marginBottom: 20,
            }}>
            <Text style={{ border: 1, width: 30, padding: 10, borderTop: 0 }}>1</Text>
            <Text
              style={{
                border: 1,
                width: 300,
                padding: 10,
                borderLeft: 0,
                borderTop: 0,
                textAlign: 'left',
              }}>
              {orderValues.program_name_for_docs}
            </Text>
            <Text
              style={{
                border: 1,
                width: 40,
                padding: '10px 2px',
                borderLeft: 0,
                borderTop: 0,
              }}>
              1
            </Text>
            <Text
              style={{
                border: 1,
                width: 55,
                padding: '10px 2px',
                borderLeft: 0,
                borderTop: 0,
              }}>
              послуга
            </Text>
            <Text
              style={{
                border: 1,
                width: 60,
                padding: '10px 2px',
                borderLeft: 0,
                borderTop: 0,
              }}>
              {orderValues.amount_payable_in_UAH_str}
              {'.00'}
            </Text>
            <Text
              style={{
                border: 1,
                width: 60,
                padding: '10px 2px',
                borderLeft: 0,
                borderTop: 0,
              }}>
              {orderValues.amount_payable_in_UAH_str}
              {'.00'}
            </Text>
          </View>
          <Text
            style={{
              marginBottom: 10,
            }}>
            Призначення платежу: {orderValues.program_name_for_docs} зг. рахунку на оплату
            замовлення №{orderValues.order_num} від {orderValues.date} року, без ПДВ
          </Text>
          <Text
            style={{
              width: '100%',
              textAlign: 'right',
              fontWeight: 'bold',
            }}>
            Всього без ПДВ:{'       '}
            {orderValues.amount_payable_in_UAH_str}
            {'.00'}
          </Text>
          <Text
            style={{
              width: 200,
            }}>
            Всього найменувань 1, на суму {orderValues.amount_payable_in_UAH_str}
            .00 грн.
          </Text>
          <Text>
            {new TransformNumberInWords(
              orderValues.amount_payable_in_UAH,
              'UAH'
            ).transform()}
            {'.'}
          </Text>
          <Text
            style={{
              fontWeight: 'bold',

              marginBottom: 30,
            }}>
            без ПДВ
          </Text>
          <Text>Рахунок дійсний протягом 3-х банківських днів</Text>
          <Text
            style={{
              marginTop: 30,
            }}>
            {orderValues.conversion_description}
          </Text>
        </View>
      </Page>
    </Document>
  );
};
export default Order;
