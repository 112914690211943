import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { SyncOutlined } from '@ant-design/icons';
//get getCurrencies
import requests from '../../../main/controllers/requests';
import TitleSum from '../Bills/infoSumCalculate';
// plugins
import { connect } from '~/plugins/redux';
//ui
import { Button, InputGroup, Title, Label } from 'ui';
//utils
import transformToNum from 'utils/getNumVal';
import { isWarszawa } from 'utils/isWarszawa';

// Components
import { InputNumber, Date as DateField, Select } from 'components/Fields';
//styles
import { Container, Close, CloseButton, TextContainer, TitleSucces } from './styles';
import { getValueFromEvent, selectDataProcessor } from '../../../../utils';

// Model data
const getStateFromModel = ({
  adminOrders: { orders, loading },
  users: { userData },
}) => ({
  orders,
  userData,
  loading,
});
const getMethodsFromModel = ({ adminOrders: { addBill } }) => ({
  addBill,
});

export const BillsPopup = connect(
  getStateFromModel,
  getMethodsFromModel
)(
  ({
    date,
    summ_rate,
    added,
    sumOrders,
    order = {},
    loading = false,
    placeholder = 'Спосіб оплати',

    handleTogle = () => {},
    handleCreateBill = () => {},
    addBill = () => {},
    ...props
  }) => {
    const { order_id, programName, programType } = order;
    useEffect(() => {
      (async () => {
        setPayments(
          await requests.getPaymentMethods({
            program_type: programType,
            nomenclature_1s: programName,
          })
        );
      })();
    }, []);
    const isWarszawaOrder = isWarszawa(order);
    const [programInfo, setProgramInfo] = useState({});
    const { currency, full_price } = programInfo;

    const [payments, setPayments] = useState([]);
    const [payment, setPayment] = useState('');
    const paymentMethods = useMemo(() => selectDataProcessor(payments), [payments]);

    const [recipient, setRecipient] = useState({});

    const [hasChange, setHasChange] = useState(false);

    const [tSumm, setTSum] = useState(null);
    const [tSumm_in_UAH, setTSum_in_UAH] = useState(full_price ? full_price : null);
    const [rate, setRate] = useState(summ_rate ? summ_rate : 1);
    const [rate_EUR_PLN, setRate_EUR_PLN] = useState(
      order?.conversion_rate_EUR_PLN || null
    );
    const [tSumm_in_PLN, setTSum_in_PLN] = useState(null);

    const dateFormatList = ['DD-MM-YYYY', 'DD.MM.YYYY'];
    const [dateDefault, setDateDefault] = useState(
      date ? moment(date).format(dateFormatList[1]) : moment().format(dateFormatList[1])
    );

    const handleChangeDate = (e) => {
      setDateDefault(moment(e).format(dateFormatList[1]));
      setHasChange(true);
    };
    const handleChangeSumm = (e) => {
      const val = transformToNum(e);
      setTSum(val);
      setTSum_in_UAH(+(val * rate).toFixed(0));
      setHasChange(true);
      if (isWarszawaOrder) setTSum_in_PLN(+(val * rate_EUR_PLN).toFixed(0));
    };
    const handleChangeSummInUAH = (e) => {
      const val = transformToNum(e);
      setTSum(+(val / rate).toFixed(2));
      setTSum_in_UAH(val);
      setHasChange(true);
    };
    const handleChangeSummInPLN = (e) => {
      const val = transformToNum(e);
      const tSum = +(val / rate_EUR_PLN).toFixed(2);
      setTSum(tSum);
      setTSum_in_PLN(val);
      setTSum_in_UAH(tSum * rate);
      setHasChange(true);
    };

    const paymentMethodChange = async (e) => {
      const value = getValueFromEvent(e);
      setPayment(value);

      const response = await requests.getRecipient({
        program_type: order?.programType || '',
        nomenclature_1s: order?.programName || '',
        payment_type: value || '',
        location: order?.location?.title || order?.location || '',
      });
      const { recipient, programInfo } = response;
      setRecipient(recipient);
      setProgramInfo(programInfo);
      setTSum(programInfo?.full_price);
      await getCurrencyRate();
      async function getCurrencyRate() {
        const exchangeRates = await requests.getCurrencies();
        const rate = exchangeRates.find((val) => val.ccy === programInfo?.currency);
        setTSum_in_UAH((+programInfo?.full_price * (+rate?.sell || 1)).toFixed(0));
        if (rate?.sell) setRate(rate.sell);
        if (isWarszawaOrder) {
          const rate_EUR_PLN = await requests.getCurrenciesManualByKey('PLZ_EUR');
          setRate_EUR_PLN(rate_EUR_PLN?.exchange?.sell || 1);
          setTSum_in_PLN(
            (+programInfo?.full_price * (+rate_EUR_PLN?.exchange?.sell || 1)).toFixed(0)
          );
        }
      }
    };

    const handleAddBill = async () => {
      let payload = {
        order_id,
        date: dateDefault,
        summ: tSumm,
        summ_rate: rate,
        summ_in_UAH: tSumm_in_UAH,
        payment: payment,
      };
      if (isWarszawaOrder) {
        payload.summ_rate_EUR_PLN = rate_EUR_PLN;
        payload.summ_in_PLN = tSumm_in_PLN;
      }
      const res = await addBill({ ...payload, recipient });
      if (res?.success) {
        handleCreateBill({ ...payload, sync_1c: true, recipient });
        setTSum(0);
        setTSum_in_UAH(0);
        setTSum_in_PLN(null);
      }
    };

    return (
      <>
        <Close onClick={handleTogle} />
        <Container style={props?.style || {}}>
          <CloseButton onClick={handleTogle} />
          <Title>Замовлення №{order_id}</Title>
          <TitleSucces>
            {loading ? <SyncOutlined spin /> : ''}
            {added ? 'Додано' : ''}
          </TitleSucces>

          <InputGroup>
            <Label text="Спосіб оплати:" required>
              <Select
                allowClear={true}
                placeholder={placeholder}
                options={paymentMethods}
                value={payment}
                // disabled={paymentMethodDisabled}
                onChange={paymentMethodChange}
              />
            </Label>

            {payment ? (
              <TextContainer>
                <span>{recipient?.name || recipient?.title || 'null'}, </span>
                <br />
                <span>IBAN {recipient?.iban || 'null'}, </span>
                <br />
                <span>
                  в {recipient?.bank}, {recipient?.place || 'null'},
                </span>
                <br />
                <span>код за ЄДРПОУ {recipient?.edrpou || 'null'}, </span>
                <br />
                <span>{recipient?.details || 'null'}</span>
                <br />
                {recipient?.director ? <span>Директор: {recipient?.director}</span> : ''}
              </TextContainer>
            ) : (
              <div style={{ margin: '10px 0' }}>
                <b>Виберіть спосіб оплати, щоб отримати реквізити отримувача *</b>
              </div>
            )}
          </InputGroup>

          <TitleSum
            sumOrders={sumOrders + tSumm}
            programPrice={full_price}
            currency={currency}
          />
          <InputGroup>
            <DateField
              addonBefore="Дата"
              onChange={handleChangeDate}
              defaultValue={moment(dateDefault, dateFormatList[1])}
              format={dateFormatList[0]}
            />
            <InputNumber
              addonBefore={`Сума (${currency || ''})`}
              onChange={handleChangeSumm}
              value={tSumm}
            />
            {currency !== 'UAH' && !isWarszawaOrder ? (
              <InputNumber
                addonBefore={`Сума (UAH) курс: ${rate}`}
                onChange={handleChangeSummInUAH}
                value={tSumm_in_UAH}
              />
            ) : (
              ''
            )}
            {isWarszawaOrder && (
              <InputNumber
                addonBefore={`Сума (PLN) курс: ${rate_EUR_PLN}`}
                onChange={handleChangeSummInPLN}
                value={tSumm_in_PLN}
              />
            )}
          </InputGroup>
          <span style={{ width: '100px' }} {...props}>
            {props.children}
          </span>
          <div style={{ marginTop: 20 }}>
            <Button
              disabled={!payment || !tSumm || !tSumm_in_UAH}
              onClick={handleAddBill}>
              Додати рахунок
            </Button>
          </div>
        </Container>
      </>
    );
  }
);
