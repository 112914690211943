import React, { useEffect, useState } from 'react';
import { Button, Input, Label, Wrap, Wrapper } from './style';
import { toBase64 } from '../../utils/toBase64';
import { requests } from '../main/controllers';

export const AdminUploadImage = () => {
  const [stamp, setStamp] = useState(null);
  const [signature, setSignature] = useState(null);

  const [responseStamp, setResponseStamp] = useState();
  const [responseSignature, setResponseSignature] = useState();

  const [stringifiedStamp, setStringifiedStamp] = useState(null);
  const [stringifiedSignature, setStringifiedSignature] = useState(null);

  const [isShowMessageSignature, setIsShowMessageSignature] = useState(false);
  const [isShowMessageStamp, setIsShowMessageStamp] = useState(false);

  const copyToClipboard = async (text, type) => {
    await navigator.clipboard.writeText(text);

    if (type === 'signature') {
      setIsShowMessageSignature(true);
      const timerId = setTimeout(() => {
        setIsShowMessageSignature(false);
        clearTimeout(timerId);
      }, 3000);
    }
    if (type === 'stamp') {
      setIsShowMessageStamp(true);
      const timerId = setTimeout(() => {
        setIsShowMessageStamp(false);
        clearTimeout(timerId);
      }, 3000);
    }
  };

  const handleStampChange = async (e) => {
    setStamp(e?.target?.files[0]);
  };
  const handleSignatureChange = async (e) => {
    setSignature(e?.target?.files[0]);
  };

  const uploadStamp = async () => {
    const response = await requests.postImageStamp(stamp);
    const [data] = response;
    setResponseStamp(data);
  };

  const uploadSignature = async () => {
    const response = await requests.postImageSignature(signature);
    const [data] = response;
    setResponseSignature(data);
  };

  useEffect(() => {
    (async () => {
      if (!(stamp instanceof File)) return;
      await uploadStamp();
      toBase64(stamp).then((base64) => setStringifiedStamp(String(base64)));
    })();
  }, [stamp]);

  useEffect(() => {
    (async () => {
      if (!(signature instanceof File)) return;
      await uploadSignature();
      toBase64(signature).then((base64) => setStringifiedSignature(String(base64)));
    })();
  }, [signature]);

  return (
    <Wrap>
      <Wrapper>
        <img
          style={{ aspectRatio: '1 / 1', objectFit: 'cover' }}
          src={
            stringifiedStamp ? stringifiedStamp : '../../../public/images/map-preview.svg'
          }
          width={150}
          height={150}
          alt="image"
        />
        {responseStamp ? (
          <Button
            onClick={() => copyToClipboard(responseStamp?.filename, 'stamp')}
            type="button">
            {isShowMessageStamp ? 'Текст скопійовано' : responseStamp?.filename}
          </Button>
        ) : (
          <Label>
            <Input
              name="file"
              type="file"
              accept="image/png, image/gif, image/jpeg, image/jpg, image/svg image/avif, image/webp"
              onChange={handleStampChange}
            />
            завантажити штамп
          </Label>
        )}
      </Wrapper>
      <Wrapper>
        <img
          style={{ aspectRatio: '1 / 1', objectFit: 'cover' }}
          src={
            stringifiedSignature
              ? stringifiedSignature
              : '../../../public/images/map-preview.svg'
          }
          width={150}
          height={150}
          alt="image"
        />
        {responseSignature ? (
          <Button
            onClick={() => copyToClipboard(responseSignature?.filename, 'signature')}
            type="button">
            {isShowMessageSignature ? 'Текст скопійовано' : responseSignature?.filename}
          </Button>
        ) : (
          <Label>
            <Input
              name="file"
              type="file"
              accept="image/png, image/gif, image/jpeg, image/jpg, image/svg image/avif, image/webp"
              onChange={handleSignatureChange}
            />
            завантажити підпис
          </Label>
        )}
      </Wrapper>
    </Wrap>
  );
};
