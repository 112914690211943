import React, { useState } from 'react';

import { Popover } from 'antd';
import { InfoCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { Button, Flex } from '~/ui';

export const Info1C = ({ status, text, ...props }) => {
  return (
    <Popover
      placement="right"
      content={
        status ? (
          <span>Інформація {text} додана в 1С</span>
        ) : (
          <span style={{ color: 'red' }}>Інформація {text} НЕ додана в 1С</span>
        )
      }>
      <Flex justify={'center'} align={'center'} width={'100%'}>
        {status ? (
          <CheckCircleTwoTone style={props?.style} twoToneColor="#52c41a" />
        ) : (
          <InfoCircleTwoTone style={props?.style} twoToneColor="red" />
        )}
        {props.children}
      </Flex>
    </Popover>
  );
};

export const Button1CUpdate = ({ orders = [], handle1C = () => {}, ...props }) => {
  const [blockClick, setBclockClick] = useState(false);
  return (
    <Flex justify={'center'} align={'center'} width={'max-content'}>
      <Button
        onClick={async () => {
          if (!blockClick && orders.length > 0) {
            setBclockClick(true);
            const res = await handle1C(orders[0]);
            console.log('res ', res);
            setBclockClick(false);
          }
        }}
        className="btn btn-lg btn-primary">
        Тестовий запит в 1С
      </Button>
      {props.children}
    </Flex>
  );
};
