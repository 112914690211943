import React, { useState } from 'react';
import { connect } from 'react-redux';
import { components as _components } from './AddUserModalComponents';

// Utils

// Components
import { Input, Text, InputPassword, Select } from 'ui';

// Styled
import { ModalContent } from '../UsersTable/styles';

const ComponentsList = {
  input: Input,
  select: Select,
  inputPassword: InputPassword,
};

/**
 * Component
 */
export const AddUserModalBody = connect(
  ({ adminUsers: { modalData, currentRecord }, users: { roles } }) => ({
    roles,
    modalData,
    currentRecord,
  }),
  ({ adminUsers: { setModalData } }) => ({ setModalData })
)(({ modalData = {}, roles = [], setModalData = () => {}, currentRecord = {} }) => {
  const [localData, setLocalData] = useState({});
  console.log('localData', localData);
  const handleFields = (key) => (e) => {
    setLocalData({ ...localData, [key]: e.target ? e.target.value : e });
  };

  const components = _components({ handleFields });
  const selectData = {
    // clients: modelData.clients || [],
    roles,
  };
  return (
    <ModalContent onMouseLeave={() => setModalData({ ...modalData, ...localData })}>
      {components.map((comp, index) => {
        const Field = ComponentsList[comp.type];
        const additionalProps = {};

        if (comp.isHasItems) {
          additionalProps.items = (selectData[comp.listName] || []).map((fld, k) => (
            <option key={k} value={fld}>
              {fld}
            </option>
          ));
        }

        const { placeholder = '' } = comp;

        return (
          <div>
            <Text>{placeholder ? `${placeholder}:` : ''}</Text>
            <Field
              key={index}
              className={'Field'}
              {...comp.behaviours}
              placeholder={comp.placeholder}
              value={localData[comp.value]}
              defaultValue={
                modalData[comp.defaultValue] || currentRecord[comp.defaultValue]
              }
              {...additionalProps}
            />
          </div>
        );
      })}
    </ModalContent>
  );
});

export default AddUserModalBody;
