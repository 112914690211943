import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Containers
import { Search } from '../containers/Search';
import { ProgramDatesTable } from '../containers/ProgramDatesTable';
// import { SchoolsTable } from '../containers/SchoolsTable';
import { AddProgramDatesModalBody } from '../containers/AddProgramDatesModal';
import { EditProgramDatesModal } from '../containers/EditProgramDatesModal';
import { DeleteProgramDatesModal } from '../containers/DeleteProgramDatesModal';

// Components
import { UniversalModal } from 'ui';

// Styled
import { PageSection, TableWrap } from './styles';

export const AdminProgramDates = connect(
  ({ adminProgramDates: { modal, currentRecord, modalData } }) => ({
    modal,
    currentRecord,
    modalData,
  }),
  ({
    adminProgramDates: {
      setModal,
      setModalData,
      addCountryToServer,
      editCountryToServer,
      addProgramDatesToServer,
      editProgramDatesToServer,
      removeProgramDatesFromServer,
      removeCountryFromServer,
      addSchoolToServer,
      editSchoolToServer,
      removeSchoolFromServer,
    },
    users: { getRoles },
  }) => ({
    setModal,
    setModalData,
    addCountryToServer,
    editCountryToServer,
    addProgramDatesToServer,
    editProgramDatesToServer,
    removeProgramDatesFromServer,
    removeCountryFromServer,
    addSchoolToServer,
    editSchoolToServer,
    removeSchoolFromServer,
    getRoles,
  })
)(
  ({
    modal = '',
    currentRecord = {},
    modalData = {},
    setModal = () => {},
    setModalData = () => {},
    addProgramDatesToServer = () => {},
    editProgramDatesToServer = () => {},
    removeProgramDatesFromServer = () => {},
    getRoles = () => {},
  }) => {
    const setModalClose = () => {
      setModal('');
    };

    const { code = '' } = currentRecord;

    useEffect(() => {
      getRoles({});
    }, [getRoles]);

    return (
      <PageSection>
        {/* Add new modal */}
        {modal === 'add' && (
          <UniversalModal
            title="Додати нові дати для програми"
            onCancel={setModalClose}
            setModalClose={setModalClose}
            visible={true}
            ModalBody={() => <AddProgramDatesModalBody modelData={currentRecord} />}
            serverRequest={addProgramDatesToServer}
            setModalData={setModalData}
            modalData={modalData}
          />
        )}
        {/* Edit modal */}
        {modal === 'edit' && (
          <UniversalModal
            title={`Редагувати ${code}`}
            onCancel={setModalClose}
            setModalClose={setModalClose}
            visible={true}
            currentRecord={currentRecord}
            ModalBody={() => <EditProgramDatesModal modelData={currentRecord} />}
            serverRequest={editProgramDatesToServer}
            setModalData={setModalData}
            modalData={modalData}
          />
        )}
        {/* Delete user modal */}
        {modal === 'delete' && (
          <UniversalModal
            title={`Видалити ${code}?`}
            onCancel={setModalClose}
            setModalClose={setModalClose}
            visible={true}
            currentRecord={currentRecord}
            ModalBody={() => <DeleteProgramDatesModal modelData={currentRecord} />}
            serverRequest={removeProgramDatesFromServer}
            setModalData={setModalData}
            modalData={currentRecord._id}
          />
        )}
        <Search />
        <ProgramDatesTable />
      </PageSection>
    );
  }
);

export default AdminProgramDates;
