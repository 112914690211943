import styled from 'styled-components';

// Components
import { Button as UIButton } from '~/ui';

export const SButton = styled(UIButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  padding: 6px;
  margin: 0 auto;
  ${'' /* background-color: #ff4343 !important; */}
  ${'' /* background-color: ${({ disabled, delete1C }) =>
    disabled === true
      ? '#bfbfbf !important'
      : delete1C === true
      ? '#fd7d00 !important'
      : '#ff4343 !important'};
  box-shadow: 0 10px 20px rgb(0, 0, 0, 0.19), 0 6px 6px rgb(0, 0, 0, 0.23); */};
`;
export const IconFreeze = styled.div`
  width: 30px;
  height: 30px;
  background-image: url(/images/snowflake.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
