import styled from 'styled-components';

export const TableWrap = styled.div`
  width: 55%;
  padding: 0 5px;
  margin-bottom: 40px;
  &:first-of-type {
    width: 45%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    &:first-of-type {
      width: 100%;
    }
  }
`;

export const PageSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  padding: 0 2% 0 2%;
  width: 100%;
`;
