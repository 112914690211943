import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const BeforeRoutesList = [];
const RoutesList = [];
const AfterRoutesList = [];

export const initRoutes = ({ RouterWrapper = () => {} }) => (props) => (
  <Router>
    <RouterWrapper>
      {BeforeRoutesList.map((Component, i) => (
        <Component key={i} {...props} />
      ))}
      <Switch {...props}>
        {RoutesList.map((route, i) => (
          <Route key={i} exact path={route.path} component={route.component} {...props} />
        ))}
      </Switch>
      {AfterRoutesList.map((Component, i) => (
        <Component key={i} {...props} />
      ))}
    </RouterWrapper>
  </Router>
);

export const addToRoutes = (path = '', component = () => {}) =>
  RoutesList.push({ path, component });

export const addBeforeRoutes = (component = () => {}) => BeforeRoutesList.push(component);

export const addAfterRoutes = (component) => AfterRoutesList.push(component);
