export const transformOrdersExcel = (order = []) => {
  return [
    {
      0: 'Дата cтворення',
      1: 'Менеджер',
      2: 'Тип програми',
      3: 'Назва програми',
      4: 'Повна вартість програми',
      5: 'Повна вартість програми в гривні',
      6: 'Знижка',
      7: 'Знижка в гривні',
      8: 'Вартість зі знижкою',
      9: 'Вартість зі знижкою в гривні',
      10: 'Сума передплати',
      11: 'Сплачено',
      12: 'Сплачено в гривні',
      13: 'Валюта',
      14: 'Сума передплати в гривні',
      15: 'Конверсія',
      16: 'Лінк на AMO CRM',
      17: 'Локація продажу',
      18: 'Назва програми в Замовленні/Рахунку для клієнта',
      19: 'ID замовлення',
      20: 'Main ID',
      21: 'Договір оферти',
      22: 'П.І.Б клієнта',
      23: "Ім'я студента",
      24: 'Дата народження',
      25: 'Тип оплати',
      26: 'Реквізити оплати',
    },
    order.map((order) => {
      return {
        //0
        create_date: `${order?.createDate?.d}-${order?.createDate?.m}-${order?.createDate?.y}`,
        //1
        manager: order.manager?.id
          ? order.manager?.firstName + ' ' + order.manager?.lastName
          : order.manager || '',
        //2
        program_type: order.programType?.title || order.programType,
        //3
        program_name: order.programName?.title || order.programName,
        //4
        full_price: order.programName?.full_price || order.full_price || 0,
        //5
        full_price_uah:
          order.programName?.full_price * order.conversion_rate ||
          order.full_price * order.conversion_rate ||
          0,
        //6
        discount: order?.discount || 0,
        //7
        discount_uah: order?.discount * order.conversion_rate || 0,
        //8
        price_wiht_discount: order?.price_wiht_discount || 0,
        //9
        price_wiht_discount_uah: order?.price_wiht_discount * order.conversion_rate || 0,
        //10
        amount_payable:
          order?.amount_payable || order.programName?.prepayment_amount || 0,
        //11
        payed: order?.payed || 0,
        //12
        payed_uah: order.payed * order.conversion_rate || 0,
        //13
        currency: order.programName?.currency || order?.currency || '',
        //14
        amount_payable_in_UAH: +order.amount_payable_in_UAH || 0,
        //15
        conversion_rate: order.conversion_rate || 0,
        //16
        link_AMO_CRM: order.link_AMO_CRM || '',
        //17
        location: order?.location?.title || order?.location || '',
        //18
        program_name_for_docs:
          order?.program_name_for_docs || order.programName?.program_name_for_docs || '',
        //19
        order_id: order.order_id || '',
        //20
        main_id: order?.main_id || '',
        //21
        offer_agreement: order?.offer_agreement_link || order?.offer_agreement || '',
        //22
        client_payer_name: order.client?.payer_name || '',
        //23
        client_student_name: order.client?.student_name || '',
        //24
        client_birth_date: order.client?.birth_date || '',
        //25
        payment: order.payment || '',
        //26
        recipient: `${order.recipient.name || '-'} | ${order.recipient.bank || '-'} | ${
          order.recipient.edrpou || '-'
        } `,
      };
    }),
  ];
};
