import React, { useState } from 'react';

import { PDFDownloadLink } from '@react-pdf/renderer';

// Components

import Account from './account';
import Order from './order';
import Contract from './contract';
import Agreement from './agreement';
import AccountCyprus from './accountCyprus';
import AccountWarszawa from './accountWarszawa';
import AccountWarszawaServices from './accountWarszawaServices';

import { services } from '../../../../config/services';
// Styles
import { GenerateButton, DownloadButton } from './styles';
// Utils
import { isWarszawa } from 'utils/isWarszawa';

export const CreatePDF = ({
  disabled = false,
  type,
  order,
  bill,
  style,
  short = false,
}) => {
  const [show, setShow] = useState(false);
  const pdfType =
    type === 'order'
      ? 'Замовлення'
      : type === 'bill' || type === 'newBill'
      ? 'Рахунок'
      : type === 'agreement'
      ? 'Договір доручення'
      : 'Додаткова угода';
  const PdfComponent =
    services.includes(order?.programName) && type === 'bill'
      ? AccountWarszawaServices
      : type === 'bill' && isWarszawa(order)
      ? AccountWarszawa
      : type === 'order'
      ? Account
      : type === 'bill'
      ? Order
      : type === 'agreement'
      ? Agreement
      : type === 'newBill'
      ? AccountCyprus
      : Contract;
  return show ? (
    <PDFDownloadLink
      disabled={disabled}
      document={<PdfComponent orderValues={order} bill={bill} />}
      fileName={`${pdfType}_${(order.client || {}).payer_name}_${
        order.programName || {}
      }.pdf`}>
      {({ loading }) =>
        loading ? (
          short ? (
            'Loading...'
          ) : (
            'Loading document...  '
          )
        ) : (
          <DownloadButton
            style={{ marginRight: 10, ...style }}
            onClick={() => {
              setShow(false);
            }}>
            <span>Скачати</span>
            {style || short ? '' : <>&nbsp;</>}
            {short ? '' : <span>{pdfType}</span>}
          </DownloadButton>
        )
      }
    </PDFDownloadLink>
  ) : order?.programType === 'Оплати в школу аттестати/сертифікати' &&
    type === 'order' ? (
    <a
      href={'https://international.study.ua/pdf/agreementpayment.pdf'}
      target={'_blank'}
      rel="noopener noreferrer">
      ДОГОВІР
    </a>
  ) : (
    <GenerateButton
      disabled={disabled}
      style={{ marginRight: 10, ...style }}
      onClick={() => {
        setShow(true);
      }}>
      <span>Згенерувати</span>
      {style || short ? '' : <>&nbsp;</>}
      {short ? '' : <span>{pdfType}</span>}
    </GenerateButton>
  );
};
