import React, { useState } from 'react';

import { connect } from '~/plugins/redux';

import { Progress } from 'antd';
import { Button, Flex } from '~/ui';

// Model data
const getStateFromModel = ({
  adminOrders: { orders, loading, progressShow, allOrders },
  users: { userData },
}) => ({
  orders,
  loading,
  progressShow,
  allOrders,
  userData,
});
const getMethodsFromModel = ({
  adminOrders: { getOrdersFromServer, setState },
  main: { order1CSend, bill1CSend, all1CSend },
}) => ({
  setState,
  getOrdersFromServer,
  order1CSend,
  bill1CSend,
  all1CSend,
});

export const Update1C = connect(
  getStateFromModel,
  getMethodsFromModel
)(
  ({
    orders = [],
    loading = false,
    progressShow = false,
    allOrders = false,
    setState = () => {},
    order1CSend = () => {},
    bill1CSend = () => {},
    all1CSend = () => {},
    getOrdersFromServer = () => {},
    ...props
  }) => {
    const [hasError, setHasError] = useState(false);
    const [progres, setProgres] = useState(0);
    const [idListErrors, setIdListErrors] = useState([]);
    const step = 100 / orders.length;
    const handleUpdate1C = async () => {
      if (orders.length > 0) {
        setProgres(0);
        setIdListErrors([]);
        const tmp = [];
        setHasError(false);
        setState({ loading: true, progressShow: true });
        for (let i = 0; i < orders.length; i++) {
          // if (!orders[i]?.syncBill_1c || !orders[i]?.sync_1c) {
          const resOrder = await order1CSend(orders[i]);
          if (!(resOrder?.success === true)) {
            tmp.push(orders[i]?.order_id);
            setIdListErrors(tmp);
            setHasError(true);
            console.log('resOrder order_id=', orders[i]?.order_id, resOrder);
          }
          // }
          if (orders[i]?.bills?.length > 0) {
            for (let j = 0; j < orders[i]?.bills?.length; j++) {
              // if (!orders[i].bills[j]?.sync_1c) {
              const resBill = await bill1CSend({
                order: orders[i],
                bill: orders[i].bills[j],
              });
              if (!(resBill?.success === true)) {
                tmp.push(`${orders[i]?.order_id}[${j}]`);
                setIdListErrors(tmp);
                setHasError(true);
                console.log(
                  'resBill order_id=',
                  orders[i]?.order_id,
                  `Bill[${j}] `,
                  resBill
                );
              }
              // }
            }
          }
          setProgres((i + 1) * step);
        }
        setState({ loading: false, allOrders: false });
        getOrdersFromServer();
      }
    };
    const handleUpdateAll1C = async () => {
      setState({ loading: true, progressShow: false });
      if (allOrders) {
        setState({ allOrders: false });
        await getOrdersFromServer();
      } else {
        setState({ allOrders: true, search: { manager_id: 'showAll' } });
        await getOrdersFromServer();
      }
      // const resAll = await all1CSend();
      // console.log('resAll ', resAll);
      setState({ loading: false });
    };
    return (
      <>
        <Flex justify={'center'} align={'center'} width={'max-content'}>
          {props?.children ? props.children : ''}
          <Button
            disabled={loading}
            onClick={handleUpdate1C}
            className="btn btn-lg btn-primary">
            Синхронізувати {allOrders ? '[' + orders.length + ']' : 'сторінку'} з 1С
          </Button>
          <Button
            disabled={loading}
            onClick={handleUpdateAll1C}
            className="btn btn-lg btn-primary">
            Завантажити {allOrders ? 'поточну сторінку' : 'всі записи'} для синхронізації
          </Button>
        </Flex>
        <br />
        {progressShow ? (
          <>
            <Progress percent={progres.toFixed(2)} status={hasError ? 'exception' : ''} />
            {orders.length === progres / step
              ? 'Виконано'
              : `[${(progres / step).toFixed(0)}/${orders.length}]`}

            {idListErrors.length > 0 && (
              <div>
                <span>
                  <b>Errors order_id: </b>
                </span>
                {idListErrors.map((item, index) => (
                  <span>{item}; </span>
                ))}
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </>
    );
  }
);
