import styled from 'styled-components';

// UI
import { Flex, Button } from 'ui';

export const Container = styled.header`
  width: 100%;
  height: 40px;
  min-height: 60px;
  padding: 10px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background: #fff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  transition: background-color 1s ease-out;
  z-index: 999;
`;

export const LeftSide = styled(Flex)`
  width: 400px;
  margin: 0;
  padding: 0 20px;
  align-items: center;
  justify-content: flex-start;
`;

export const RightSide = styled(Flex)`
  width: 400px;
  margin: 0;
  align-items: center;
  justify-content: flex-end;
`;

export const LogOutButton = styled(Button)``;
