import React from 'react';

import { Flex } from 'ui';

// Component
export const Header = () => {
  return (
    <Flex margin="20px auto 50px auto" width="50%">
      {['logo_left.png', 'logo_right.png'].map((src, key) => (
        <Flex key={key}>
          <img src={`/images/${src}`} width="100" alt="logo" />
        </Flex>
      ))}
    </Flex>
  );
};
