export const columns = [
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
  },
  {
    title: 'Одержувач',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'ЄДРПОУ',
    dataIndex: 'edrpou',
    key: 'edrpou',
  },
  {
    title: 'Банк',
    dataIndex: 'bank',
    key: 'bank',
  },
  {
    title: 'IBAN',
    dataIndex: 'iban',
    key: 'iban',
  },
  {
    title: 'Адреса',
    dataIndex: 'place',
    key: 'place',
  },
  {
    title: 'Отримано',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    render: (item) => item.toLocaleString(),
  },
];
