import { managers } from '../services/request';

/**
 * Get Managers
 */
export const getManagersFromServer = async ({
  page = 1,
  limit = 10,
  sort = {},
  query,
}) => {
  // settings
  const _sort = sort.columnKey ? sort.columnKey : '';
  const _order = sort.columnKey ? (sort.order ? sort.order : 'ascend') : '';
  const _query = query || '';

  const response = await managers.get(
    `all?page=${page}&limit=${limit}&sort=${_sort}&order=${_order}&q=${_query}`,
    true
  );

  if (response.message || response.error)
    throw new Error(`Ошибка взятия менеджеров из сервера. ${JSON.stringify(response)}`);

  return response || [];
};
