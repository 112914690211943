import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Steps } from 'antd';
import { SolutionOutlined, FileSyncOutlined, FileDoneOutlined } from '@ant-design/icons';

// UI
import { Flex, Title } from 'ui';

// Containers
import OrderForm from '../OrderForm';
import ClientForm from '../ClientForm';
import PriceForm from '../PriceForm';
import { Header } from '../Header';
//Styles
import { Container } from './styles';
// Get data from redux
const getDataFromStore = ({ main: { step, exchangeRates } }) => ({
  step,
  exchangeRates,
});
const getMethodsFromStore = ({
  main: { setState, getCurrencies, setInitialPrices },
}) => ({
  setState,
  getCurrencies,
  setInitialPrices,
});

/**
 * Component
 */
export const MainForm = connect(
  getDataFromStore,
  getMethodsFromStore
)(
  // props
  ({ step = 1, setState = () => {}, setInitialPrices = () => {} }) => {
    const handleOrderFormFinish = () => {
      setState({ step: 3 });
    };

    if (step === 3) {
      useEffect(() => {
        setInitialPrices();
      }, [setInitialPrices]);
    }

    const handleClientFormFinish = () => {
      setState({ step: 2 });
    };

    const handlePriceFormFinish = () => {};
    const { Step } = Steps;
    const steps = [
      {
        title: 'Клієнт',
        icon: <SolutionOutlined />,
        content: <ClientForm onSubmit={handleClientFormFinish} />,
      },
      {
        title: 'Програма',
        icon: <FileSyncOutlined />,
        content: <OrderForm onSubmit={handleOrderFormFinish} />,
      },
      {
        title: 'Замовлення',
        icon: <FileDoneOutlined />,
        content: <PriceForm onSubmit={handlePriceFormFinish} />,
      },
    ];
    return (
      <>
        <Header />
        <Flex direction="column" width="100%">
          <Title>Ласкаво просимо на портал оплати STUDY.UA</Title>
          <Container>
            <Steps current={step - 1}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} icon={item.icon} />
              ))}
            </Steps>
          </Container>
          {steps[step - 1].content}
          {/* {step === 1 ? <ClientForm onSubmit={handleClientFormFinish} /> : ''}
          {step === 2 ? <OrderForm onSubmit={handleOrderFormFinish} /> : ''}
          {step === 3 ? <PriceForm onSubmit={handlePriceFormFinish} /> : ''} */}
        </Flex>
      </>
    );
  }
);

export default MainForm;
