import React, { useState } from 'react';
import { SyncOutlined, DeleteOutlined, HistoryOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';

// plugins
import { connect } from '~/plugins/redux';
//ui
import { Button } from './styles';

// Model data
const getStateFromModel = ({ main: { loading }, users: { userData } }) => ({
  userData,
  loading,
});
const getMethodsFromModel = ({ adminOrders: { setState, deleteOrder } }) => ({
  setState,
  deleteOrder,
});

export const MarkToDelete = connect(
  getStateFromModel,
  getMethodsFromModel
)(
  ({
    order = {},
    userData = {},
    loading = false,
    setState = () => {},
    deleteOrder = () => {},
    ...props
  }) => {
    const { order_id, delete1C = false } = order;
    const [dataLoading, setDataLoading] = useState(false);
    const { _id = '', role = '' } = userData;
    const handleDeleteOrder = async () => {
      setDataLoading(true);
      try {
        const res = await deleteOrder({ id: order_id });
        setState({ orderCopy: true });
        console.log('res', res);
      } catch (error) {}
      setDataLoading(false);
    };
    return (
      <>
        <Popconfirm
          {...props}
          title={
            (delete1C ? 'Відновити ' : 'Видалити ') + 'замовлення №' + order_id + '?'
          }
          onConfirm={() => {
            console.log(userData);
            console.log(order);
            handleDeleteOrder();
          }}>
          <Button
            disabled={role === 'admin' || _id === order?.manager?.id ? false : true}
            delete1C={delete1C}>
            {dataLoading ? (
              <SyncOutlined spin />
            ) : delete1C === true ? (
              <HistoryOutlined />
            ) : (
              <DeleteOutlined />
            )}
          </Button>
        </Popconfirm>
      </>
    );
  }
);
