import React from 'react';
import styled from 'styled-components';

// UI
import { Flex, Text } from 'ui';

// Styled
const Container = styled(Flex)`
  text-align: inherit;
  ${({ bordered }) => (bordered ? 'border: 1px solid #d9d9d9;' : '')}
  ${({ warning }) => (warning ? 'border: 1px solid red;' : '')}
`;
const TextContainer = styled(Flex)`
  width: auto;
  background-color: #fafafa;
  padding: 0 10px 0 10px;
  border: 1px solid #d9d9d9;
`;
const StyledText = styled(Text)``;

function createAddon(prop, border) {
  if (!prop) return '';

  if (typeof prop === 'string') {
    return (
      <TextContainer border={border}>
        <StyledText>{prop}</StyledText>
      </TextContainer>
    );
  }

  return prop;
}

export default (Component) => {
  return ({ addonBefore, addonAfter, bordered = true, warning = false, ...props }) => {
    const before = createAddon(addonBefore, 'right');
    const after = createAddon(addonAfter, 'left');

    return (
      <Container
        align="stretch"
        bordered={bordered}
        warning={warning}
        justify="flex-start">
        {before}
        <Component {...props}>{props.children}</Component>
        {after}
      </Container>
    );
  };
};
