export default {
  LOCAL_URL: 'http://localhost:5000',
  PROD_URL: 'http://168.119.3.5',
  API_PORT: 5000,
  MONOBANK_EDRPOU: 45085299,
  PAYMENT_TYPE_MONOBANK: `Розстрочка Монобанк`,
  IT_DIRECTION: `ІТ напрям`,
  MONOBANK_IBAN_IT: `UA633220010000026009310093684`,
  MONOBANK_EDRPOU_IT: `3289708760`,
  MONOBANK_IBAN: `UA113220010000026006930000867`,
  MONOBANK_BANK: `АТ "УНІВЕРСАЛ БАНК"`,
};
