import React from 'react';

// UI
import {
  Select,
  Input,
  Button,
  TextArea,
  DatePicker,
  RangeDatePicker,
  InputNumber,
  Label,
  Checkbox,
} from 'ui';

// Components
import AddonWrapper from '../AddonWrapper';

// Styled
import { select, datePicker, inputNumber, textArea, checkbox } from './styles';

const CustomSelect = AddonWrapper(select(Select));
const CustomDate = AddonWrapper(datePicker(DatePicker));
const CustomRangeDate = AddonWrapper(datePicker(RangeDatePicker));
const CustomInputNumber = AddonWrapper(inputNumber(InputNumber));
const CustomTextArea = AddonWrapper(textArea(TextArea));
const CustomCheckbox = AddonWrapper(checkbox(Checkbox));
const SubmitButton = ({ text = '', ...p }) => (
  <Button type="primary" htmlType="submit" {...p}>
    {text}
  </Button>
);

export {
  Label,
  Input,
  CustomSelect as Select,
  CustomDate as Date,
  CustomRangeDate as RangeDate,
  CustomInputNumber as InputNumber,
  CustomTextArea as TextArea,
  SubmitButton,
  CustomCheckbox as Checkbox,
};
